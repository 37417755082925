import { CheckCircleOutline, Cancel } from "@material-ui/icons";
import { EventParticipantStatus } from "@relieftelemed/platform";
import React, { FunctionComponent } from "react";

import { useCheckinContext } from "../context";
import { PrimaryAction } from "../primary-action";
import { SecondaryAction } from "../secondary-action";
import { useTabContext } from "../tab-context";

export const PrimaryActionWrapper: FunctionComponent = () => {
  const { update } = useCheckinContext();
  const { next } = useTabContext();
  const handleClick = () => {
    update({
      status: EventParticipantStatus.APPROVED,
    });
    next();
  };

  return (
    <PrimaryAction
      icon={<CheckCircleOutline />}
      label="Approve"
      onClick={handleClick}
    />
  );
};

export const SecondaryActionWrapper: FunctionComponent = () => {
  const { update } = useCheckinContext();
  const { next } = useTabContext();
  const handleClick = () => {
    update({
      status: EventParticipantStatus.NEEDSNURSEREVIEW,
    });
    next();
  };

  return (
    <SecondaryAction icon={<Cancel />} label="Decline" onClick={handleClick} />
  );
};
