import { TableCell, TableSortLabel, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { useIsLsu } from "../../hooks";
import { allCovid, lsuCovid } from "../../labs";

interface TableResultsHeaderProps {
  onChangeSort: (id: string) => void;
  orderBy: string;
  order: "asc" | "desc";
}

export const TableResultsHeader: FunctionComponent<TableResultsHeaderProps> = ({
  onChangeSort,
  order,
  orderBy,
}) => {
  const isLsu = useIsLsu();
  const tests = isLsu ? lsuCovid : allCovid;

  return (
    <>
      {tests.map((test) => (
        <TableCell
          key={test.id}
          align="center"
          sortDirection={orderBy === test.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === test.id}
            direction={orderBy === test.id ? order : "asc"}
            onClick={() => onChangeSort(test.id)}
          >
            {test.shortName}
            {orderBy === test.id ? (
              <Typography variant="srOnly">
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Typography>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
};
