import { Button, Grid, Hidden, TextField } from "@material-ui/core";
import React, { FormEventHandler, FunctionComponent } from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";

import { ActionButton, FormCard, PageTitle } from "../components";
import { AUTHENTICATION_MACHINE, PATH_LOGIN } from "../constants";
import { useAnalytics, validateEmail } from "../core";
import { curryValidator } from "../helpers";
import { useValidatedState } from "../hooks";
import { AuthenticationLayout } from "../layouts";
import { useAppService } from "../state";

import { AuthenticationService, useAuthService } from "./state";

export const ForgotPasswordPage: FunctionComponent = () => {
  const [, { [AUTHENTICATION_MACHINE]: authentication }] = useAppService();
  const [{ didSendReset }, { forgetPassword }] = useAuthService(
    authentication as AuthenticationService,
  );
  const { track } = useAnalytics();

  const [email, isEmailValid, changeEmail] = useValidatedState(
    "",
    curryValidator<string>(validateEmail),
    true,
  );

  if (didSendReset) {
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN,
          state: {
            didSendReset: true,
          },
        }}
      />
    );
  }

  const handleSubmit: FormEventHandler<HTMLFormElement & HTMLDivElement> = (
    event,
  ) => {
    event.preventDefault();
    track("auth.forgot", { email });
    forgetPassword(email);
  };

  return (
    <AuthenticationLayout>
      <PageTitle title="Forgot Password" />
      <FormCard
        title="Forgot Password?"
        instructions="Enter the email you used when you signed up, and we’ll send you a link to reset your password."
        elevation={0}
        onSubmit={handleSubmit}
        actions={
          <Grid container direction="row-reverse" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <ActionButton type="submit" disabled={!isEmailValid}>
                Send
              </ActionButton>
            </Grid>
            <Hidden smDown>
              <Grid item md={4} />
            </Hidden>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                fullWidth
                variant="text"
                component={RouterLink}
                to={PATH_LOGIN}
              >
                Go to Login
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid item xs={12}>
          <TextField
            autoFocus
            error={!isEmailValid}
            id="email"
            label="Email"
            onChange={changeEmail}
            required
            value={email}
          />
        </Grid>
      </FormCard>
    </AuthenticationLayout>
  );
};
