import { useMemo } from "react";

import { EventParticipant } from "../core";
import { useAppService } from "../state";

export const useParticipantsForLocation = (
  participants: EventParticipant[],
) => {
  const [{ currentLocation }] = useAppService();

  const participantsAtLocation = useMemo(() => {
    return participants.filter((participant) => {
      return currentLocation && participant.appointmentLocation
        ? participant.appointmentLocation === currentLocation.id
        : true;
    });
  }, [currentLocation, participants]);

  return participantsAtLocation;
};
