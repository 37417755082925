import { useContext } from "react";

import { ActionMenuContext } from "../helpers";

export const useActionMenuContext = () => useContext(ActionMenuContext);

const ADD_ACTION_PLAN = "addActionPlan";
const EVENT = "event";
const CONTACT = "contact";
const NURSE = "nurse";
const CHECKIN = "checkin";
const ADD_RESULTS = "addResults";
const UPLOAD_PARTICIPANTS = "uploadParticipants";
const REVIEW_PARTICIPANTS = "reviewParticipants";
const ADD_PARTICIPANT = "addParticipant";

export const ActionMenuActions = {
  [ADD_ACTION_PLAN]: ADD_ACTION_PLAN,
  [EVENT]: EVENT,
  [CONTACT]: CONTACT,
  [NURSE]: NURSE,
  [CHECKIN]: CHECKIN,
  [ADD_RESULTS]: ADD_RESULTS,
  [UPLOAD_PARTICIPANTS]: UPLOAD_PARTICIPANTS,
  [REVIEW_PARTICIPANTS]: REVIEW_PARTICIPANTS,
  [ADD_PARTICIPANT]: ADD_PARTICIPANT,
};
