import {
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
} from "@material-ui/core";
import { Event as EventIcon } from "@material-ui/icons";
import React, {
  FormEventHandler,
  FunctionComponent,
  useState,
  ChangeEventHandler,
} from "react";

import {
  Button,
  DatetimeInput,
  FormModal,
  StateSelector,
} from "../../components";
import { Address, Event, useUpdateEvent, validateUrl } from "../../core";
import { curryValidator } from "../../helpers";
import { useValidatedState } from "../../hooks";
import { SelectOrganization } from "../../organizations";
import { SelectPromotion } from "../../promotions";
import { useAppService } from "../../state";

interface EventFormProps {
  event: Event;
  onClose?: () => void;
  open?: boolean;
}

const useVisitModalStyles = makeStyles((theme) => ({
  switchLabel: {
    ...theme.typography.body1,
  },
  primaryAction: {
    textAlign: "right",
  },
}));

const isNullishString = (value?: string): value is undefined => {
  if (!value) {
    return true;
  }

  return value === "";
};

const createAddress = ({
  street,
  addressee,
  city,
  state,
  postcode,
}: Partial<Address>): Address | undefined => {
  if (
    isNullishString(street) ||
    isNullishString(city) ||
    isNullishString(state) ||
    isNullishString(postcode)
  ) {
    return undefined;
  }
  return {
    street,
    addressee: isNullishString(addressee) ? undefined : addressee,
    city,
    state,
    postcode,
  };
};

export const UpdateEventForm: FunctionComponent<EventFormProps> = ({
  event: initialEvent,
  onClose,
  open = false,
}) => {
  const classes = useVisitModalStyles();
  const [, , { fetchEvents }] = useAppService();

  const [organization, changeOrg] = useState(
    typeof initialEvent.organization === "string"
      ? initialEvent.organization
      : initialEvent.organization?.id ?? "",
  );
  const [promotion, changePromo] = useState(
    typeof initialEvent.promotion === "string"
      ? initialEvent.promotion
      : initialEvent.promotion?.id ?? "",
  );
  const [name, changeName] = useState(initialEvent.name);
  const [preMessage, changePreMessage] = useState(initialEvent.preMessage);
  const [postMessage, changePostMessage] = useState(initialEvent.postMessage);

  const [documentUrl, isValidUrl, setDocumentUrl] = useValidatedState(
    initialEvent.planningDocument,
    curryValidator<string>(validateUrl),
  );

  const [startedAt, setStartDate] = useState<Date | null>(
    initialEvent.startedAt ?? null,
  );
  const [endedAt, setEndDate] = useState<Date | null>(
    initialEvent.endedAt ?? null,
  );

  const [showResults, setShowResults] = useState(
    initialEvent.showResults ?? false,
  );
  const [showPlans, setShowPlans] = useState(
    initialEvent.showActionPlans ?? false,
  );
  const [isVisitRequired, setVisitRequired] = useState(
    initialEvent.isVisitRequired ?? true,
  );

  const [street, changeStreet] = useState(initialEvent.address?.street ?? "");
  const [addressee, changeAddressee] = useState(
    initialEvent.address?.addressee ?? "",
  );
  const [city, changeCity] = useState(initialEvent.address?.city ?? "");
  const [state, changeState] = useState(initialEvent.address?.state ?? "");
  const [postcode, changePostcode] = useState(
    initialEvent.address?.postcode ?? "",
  );

  const { mutate: updateEventMutate } = useUpdateEvent();

  const toggleShowPlans: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setShowPlans(target.checked);
  };

  const toggleShowResults: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setShowResults(target.checked);
  };

  const toggleRequireVisits: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setVisitRequired(target.checked);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement & HTMLDivElement> = (
    formEvent,
  ) => {
    formEvent.preventDefault();

    updateEventMutate(
      {
        ...initialEvent,
        address: createAddress({
          street,
          addressee,
          city,
          state,
          postcode,
        }),
        endedAt: endedAt ?? undefined,
        isVisitRequired,
        name,
        preMessage,
        postMessage,
        showActionPlans: showPlans,
        showResults,
        startedAt: startedAt ?? undefined,
        organization,
        promotion,
        planningDocument: documentUrl,
      },
      {
        onSuccess: (_event) => fetchEvents(),
      },
    );
  };

  return (
    <FormModal
      icon={EventIcon}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      onSubmit={handleSubmit}
      open={open}
      scroll="body"
      title="Edit Event"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button type="submit">Submit</Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="name"
            label="Event Name"
            helperText="Enter something to distinguish this from other events."
            onChange={({ target }) => changeName(target.value)}
            required
            value={name}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectOrganization
            value={organization}
            onChange={changeOrg}
            helperText="Select the organization that is requesting the event."
          />
        </Grid>
        <Grid item xs={12}>
          <SelectPromotion
            value={promotion}
            onChange={changePromo}
            helperText="Select the promotion to associate with the event."
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={showResults}
                onChange={toggleShowResults}
              />
            }
            label="Show Results to Organization Contacts"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={showPlans}
                onChange={toggleShowPlans}
              />
            }
            label="Enable Action Plans"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={isVisitRequired}
                onChange={toggleRequireVisits}
              />
            }
            label="Require Video Visits"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DatetimeInput
            label="Start Date and Time"
            value={startedAt}
            onChange={setStartDate}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DatetimeInput
            label="End Date and Time"
            value={endedAt}
            id="end-date-time-input"
            onChange={setEndDate}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address-street"
            label="Street Address"
            onChange={({ target }) => changeStreet(target.value)}
            value={street}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address-addressee"
            label="Address Line 2"
            onChange={({ target }) => changeAddressee(target.value)}
            value={addressee}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <TextField
            id="address-city"
            label="City"
            onChange={({ target }) => changeCity(target.value)}
            value={city}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <StateSelector
            value={state}
            onChange={({ target }) => changeState(target.value)}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            id="address-postcode"
            label="ZIP Code"
            onChange={({ target }) => changePostcode(target.value)}
            value={postcode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="pre-message"
            label="Pre-Visit Message"
            helperText="This message is shown to the participant when they are added to the video visit queue."
            onChange={({ target }) => changePreMessage(target.value)}
            value={preMessage}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="post-message"
            label="Post-Visit Message"
            helperText="This message is show to the participant when their video visit is completed."
            onChange={({ target }) => changePostMessage(target.value)}
            value={postMessage}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="document-url"
            label="Document url"
            error={!isValidUrl}
            onChange={setDocumentUrl}
            value={documentUrl}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </FormModal>
  );
};
