import {
  DialogActions as MuiDialogActions,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { DialogActionsProps } from "./types";

const useDialogActionsStyles = makeStyles((theme) => ({
  actions: {
    flex: "1 1 auto",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
}));

export const DialogActions: FunctionComponent<DialogActionsProps> = ({
  children,
  ...props
}) => {
  const classes = useDialogActionsStyles();

  return (
    <MuiDialogActions classes={{ spacing: classes.actions }} {...props}>
      {children}
    </MuiDialogActions>
  );
};
