import { EventParticipantStatus } from "@relieftelemed/platform";
import React, { FunctionComponent } from "react";

import { TabTitle } from "../../../components";
import { useCheckinContext } from "../context";
import { CheckinTabs, CheckinTitleProps } from "../types";

export const Title: FunctionComponent<CheckinTitleProps> = ({
  prefix,
  ...props
}) => {
  const { participant } = useCheckinContext();

  const hasApproval = participant?.status === EventParticipantStatus.APPROVED;
  const hasQuestions =
    participant?.screenData && Object.keys(participant.screenData).length > 0;

  return (
    <TabTitle
      disabled={hasApproval || !hasQuestions}
      label={`${prefix} Questionnaire`}
      name={CheckinTabs.QUESTIONNAIRE}
      {...props}
    />
  );
};
