import { LabelConfig } from "./types";

export const MPRINT_SERVER_URL = "http://127.0.0.1:18080/mPrintServer/";

/**
 * Printer Sizing and Positioning
 * 1 unit = 1 dot (8 units = 1 mm, 203 units = 1 in)
 *
 * Positioning is calculated *after* margins and will include the
 * border thickness (if applicable).
 * Example: A position of [0,0] with 24u margins means the
 *          element will be positioned absolutely at 3mm, 3mm
 * However, if an element has a second position (i.e. bottom right),
 * you must account for the margin if you are using PRINTER_WIDTH
 * (i.e. DEFAULT_WIDTH - horizontalMargin) or else the right end of the
 * element will be cut off.
 *
 * Text elements will wrap (maintaining the indent position) if they
 * do not fit the width.
 */
export const MILLIMETER = 8;
export const INCH = 203;

export const PRINTER_WIDTH = 832;
export const MAXIMUM_PRINTER_WIDTH = 832;

export const HORIZONTAL_MARGIN = 3 * MILLIMETER;
export const VERTICAL_MARGIN = 1 * MILLIMETER;

export const CENTER = PRINTER_WIDTH / 2 - HORIZONTAL_MARGIN;

export const BARCODE_WIDTH_MULTIPLIER = 2;

export const DEFAULT_FONT_SIZE = 4 * MILLIMETER;
export const DEFAULT_BARCODE_WIDTH = 2;
export const DEFAULT_BARCODE_HEIGHT = 4 * MILLIMETER;
export const DEFAULT_BOX_THICKNESS = 2;
export const DEFAULT_PRINTER_NAME = "LabelPrinter";

export const DEFAULT_CONFIG: LabelConfig = {
  density: 20,
  margin: {
    horizontal: HORIZONTAL_MARGIN,
    vertical: VERTICAL_MARGIN,
  },
};
