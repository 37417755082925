import { assign, ErrorExecutionEvent } from "xstate";

import {
  FAIL,
  FORGET_EMAIL,
  FORGET_PASSWORD,
  LOGIN,
  RESET_PASSWORD,
  SUCCEED,
} from "./constants";
import {
  AuthenticationContext,
  AuthenticationEvents,
  isDoneEvent,
  LoginEvent,
  ForgetPasswordEvent,
  ForgetEmailEvent,
  ResetPasswordEvent,
  SucceedEvent,
} from "./types";

export const clearContext = assign<AuthenticationContext, AuthenticationEvents>(
  {
    confirmation: "",
    email: "",
    errors: [],
    password: "",
    token: "",
    user: undefined,
  },
);

export const saveCredentials = assign<
  AuthenticationContext,
  AuthenticationEvents
>({
  email: (_context, event) =>
    event.type !== LOGIN ? "" : (event as LoginEvent).email,
  password: (_context, event) =>
    event.type !== LOGIN ? "" : (event as LoginEvent).password,
  code: (_context, event) =>
    event.type !== LOGIN ? "" : (event as LoginEvent).code,
});

export const saveEmail = assign<AuthenticationContext, AuthenticationEvents>({
  email: (_context, event) =>
    event.type !== FORGET_PASSWORD ? "" : (event as ForgetPasswordEvent).email,
});

export const saveMobile = assign<AuthenticationContext, AuthenticationEvents>({
  mobile: (_context, event) =>
    event.type !== FORGET_EMAIL ? "" : (event as ForgetEmailEvent).mobile,
});

export const saveError = assign<AuthenticationContext, AuthenticationEvents>({
  errors: (context, event) =>
    isDoneEvent(event)
      ? (event as ErrorExecutionEvent).data.message
      : context.errors,
});

export const saveNewPassword = assign<
  AuthenticationContext,
  AuthenticationEvents
>({
  confirmation: (_context, event) =>
    event.type !== RESET_PASSWORD
      ? ""
      : (event as ResetPasswordEvent).confirmation,
  email: (_context, event) =>
    event.type !== RESET_PASSWORD ? "" : (event as ResetPasswordEvent).email,
  password: (_context, event) =>
    event.type !== RESET_PASSWORD ? "" : (event as ResetPasswordEvent).password,
  token: (_context, event) =>
    event.type !== RESET_PASSWORD ? "" : (event as ResetPasswordEvent).token,
});

export const saveUser = assign<AuthenticationContext, AuthenticationEvents>({
  token: (_context, event) =>
    isDoneEvent<SucceedEvent>(event) ? event.data.token : "",
  user: (_context, event) =>
    isDoneEvent<SucceedEvent>(event) ? event.data.user : undefined,
});
