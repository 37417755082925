import { Button, makeStyles, SnackbarContent } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { Message } from "./message";

const useSuccessStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.success.main,
  },
}));

export const Success: FunctionComponent<Message> = ({
  dismissable = true,
  dismissText = "Dismiss",
  message,
  onDismiss = () => null,
}) => {
  const styles = useSuccessStyles();
  let action = null;

  if (dismissable) {
    action = (
      <Button onClick={onDismiss} variant="text">
        {dismissText}
      </Button>
    );
  }
  return <SnackbarContent classes={styles} message={message} action={action} />;
};
