import { TestResult } from "../core";

import { isInTimeframe, Timeframe } from "./is-in-timeframe";

export const isResultInTimeframe = (
  maybeResult?: TestResult,
  timeframe?: Timeframe,
) => {
  return isInTimeframe(maybeResult?.createdAt, timeframe);
};
