import { createContext, useContext } from "react";

import { PhotoCardState } from "./types";

export const PhotoCardContext = createContext<PhotoCardState>({
  identification: {
    setValue: () => {},
    isUploading: false,
    upload: () => Promise.resolve(undefined),
  },
  insuranceBack: {
    setValue: () => {},
    isUploading: false,
    upload: () => Promise.resolve(undefined),
  },
  insuranceFront: {
    setValue: () => {},
    isUploading: false,
    upload: () => Promise.resolve(undefined),
  },
});

export const usePhotoCardContext = () => useContext(PhotoCardContext);
