import { Link } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { logger } from "../core";
import { DymoFrameworkContext, FrameworkStatus } from "../helpers";

import { Alert } from "./alert";
import { NotificationTrigger } from "./notification-trigger";
import { Success } from "./success";

export const DymoProvider: FunctionComponent = ({ children }) => {
  const initialStatus = window.dymo?.label?.framework
    ? FrameworkStatus.loaded
    : FrameworkStatus.unloaded;
  const [status, setStatus] = useState<FrameworkStatus>(initialStatus);
  const [showNotification, setNotification] = useState(false);
  const flags = useFlags();

  useEffect(() => {
    if (status === FrameworkStatus.unloaded) {
      logger.info("Adding DYMO Framework JS");
      const script = document.createElement("script");

      script.src = `${process.env.PUBLIC_URL}/assets/dymo.js`;
      script.async = true;

      document.body.appendChild(script);

      const checkLoaded = () => {
        if (window.dymo?.label?.framework) {
          logger.info("DYMO Framework JS now found");
          setStatus(FrameworkStatus.loading);
        } else {
          setTimeout(checkLoaded, 500);
        }
      };

      setTimeout(checkLoaded, 500);
    }

    if (status === FrameworkStatus.loading) {
      logger.info("Initializing DYMO");

      const afterInit = () => {
        const {
          isBrowserSupported,
          isFrameworkInstalled,
        } = window.dymo.label.framework.checkEnvironment();

        if (!isBrowserSupported) {
          setStatus(FrameworkStatus.unsupported);
          setNotification(true);
          return;
        }

        if (!isFrameworkInstalled) {
          setStatus(FrameworkStatus.error);
          setNotification(true);
          return;
        }

        if (isBrowserSupported && isFrameworkInstalled) {
          setStatus(FrameworkStatus.loaded);
          setNotification(true);
        }
      };

      if (window.dymo.label.framework.init) {
        window.dymo.label.framework.init(afterInit);
      } else {
        afterInit();
      }

      setStatus(FrameworkStatus.initializing);
    }
  }, [status]);

  const clearNotifiation = () => {
    setNotification(false);
  };

  let alert: ReactNode = null;
  switch (status) {
    case FrameworkStatus.error:
      alert = (
        <Alert
          message={
            <>
              Please install the{" "}
              <Link href="https://www.dymo.com/en-US/online-support/dymo-user-guides">
                Dymo Connect
              </Link>{" "}
              software.
            </>
          }
          onDismiss={clearNotifiation}
        />
      );
      break;
    case FrameworkStatus.unsupported:
      alert = (
        <Alert
          message="This browser is not supported by the label printer."
          onDismiss={clearNotifiation}
        />
      );
      break;
    case FrameworkStatus.loaded:
      alert = (
        <Success message="Label printer found." onDismiss={clearNotifiation} />
      );
      break;
    default:
      break;
  }

  const notifications = (
    <NotificationTrigger open={showNotification} onClose={clearNotifiation}>
      {alert as ReactElement}
    </NotificationTrigger>
  );

  return (
    <DymoFrameworkContext.Provider value={status}>
      {children}
      {flags.checkInPatient ? notifications : null}
    </DymoFrameworkContext.Provider>
  );
};
