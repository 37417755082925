import { useEffect, useState } from "react";

import { useCheckDymoService } from "./use-check-dymo-service";

type Label = dymo.label.framework.ILabel;

export const useDymoLabel = (xml?: string) => {
  const isReady = useCheckDymoService();
  const [label, setLabel] = useState<Label>();

  useEffect(() => {
    if (isReady && xml) {
      const newLabel = window.dymo.label.framework.openLabelXml(xml);
      setLabel(newLabel);
    }
  }, [isReady, xml]);

  return label;
};
