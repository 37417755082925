import React, { FunctionComponent } from "react";

import { PrimaryAction } from "../primary-action";
import { useTabContext } from "../tab-context";

import { usePhotoCardContext } from "./context";

export const PrimaryActionWrapper: FunctionComponent = () => {
  const { next } = useTabContext();

  const {
    identification,
    insuranceBack,
    insuranceFront,
  } = usePhotoCardContext();

  const handleClick = () => {
    if (identification.value) {
      identification.upload({
        identification: identification.value,
      });
    }

    if (insuranceFront.value) {
      insuranceFront.upload(insuranceFront.value);
    }

    if (insuranceBack.value) {
      insuranceBack.upload(insuranceBack.value);
    }
    next();
  };

  return <PrimaryAction onClick={handleClick} />;
};
