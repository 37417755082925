import { useTheme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import {
  PieChart as RePieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { ChartProps } from "./chart-types";

export const PieChart: FunctionComponent<ChartProps> = ({
  data,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer aspect={2} {...props}>
      <RePieChart
        margin={{
          top: theme.spacing(0.5),
          bottom: theme.spacing(0.5),
          left: theme.spacing(1),
          right: theme.spacing(0.5),
        }}
      >
        <Pie dataKey="value" data={data} label>
          {children}
        </Pie>
        <Tooltip />
      </RePieChart>
    </ResponsiveContainer>
  );
};
