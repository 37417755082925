import React, { FunctionComponent, useEffect } from "react";

import { usePageTitle } from "../../hooks";
import { useAppService } from "../../state";

import { ParticipantsTable } from "./table";

export const ChecklistReviewPage: FunctionComponent = () => {
  const setTitle = usePageTitle();
  const [{ currentEvent }] = useAppService();

  useEffect(() => {
    const title = currentEvent
      ? `${currentEvent.orgName ?? "Event"} Checklist Review (${
          currentEvent.name
        })`
      : undefined;
    setTitle("Checklist Review", title);
  }, [currentEvent, setTitle]);

  return <ParticipantsTable variant="checklistreview" />;
};
