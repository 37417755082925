import { Box, Grid, Hidden, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, ReactNode } from "react";

import { Copyright } from "../../components";
import { Layout, LayoutWidth } from "../layout";
import { Sidebar } from "../sidebar";

import { Announcements } from "./announcements";

interface AuthenticationLayoutProps {
  rightSidebar?: ReactNode;
}

const useAuthStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    paddingBottom: 0,
  },
  rightContainer: {
    height: "100%",
  },
  spacer: {
    flexGrow: 1,
  },
}));

export const AuthenticationLayout: FunctionComponent<AuthenticationLayoutProps> = ({
  children,
  rightSidebar = null,
}) => {
  const classes = useAuthStyles();

  let rightContent = null;
  if (rightSidebar) {
    rightContent = <Grid item>{rightSidebar}</Grid>;
  }
  return (
    <Layout
      fullHeight
      padded
      width={LayoutWidth.wide}
      sidebar={<Sidebar footer={<Announcements />} />}
    >
      <Box height="100%">
        <Grid classes={{ container: classes.container }} container spacing={4}>
          <Hidden mdDown>
            <Grid item lg={2} />
          </Hidden>
          <Grid item xs={12} md={7} lg={5}>
            <Box mt={{ xs: 0, lg: "12.5rem" }}>{children}</Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              container
              direction="column"
              className={classes.rightContainer}
            >
              {rightContent}
              <Grid item className={classes.spacer} />
              <Grid item>
                <Copyright align="right" color="textPrimary" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};
