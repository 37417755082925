import React, { FunctionComponent, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { PATH_EVENT_HOME } from "../constants";
import { useIsVaccinationEvent, usePageTitle } from "../hooks";
import { useAppService } from "../state";

import { TestingDashboard } from "./testing";
import { VaccinationDashboard } from "./vaccination";

export const DashboardPage: FunctionComponent = () => {
  const setTitle = usePageTitle();
  const [{ currentEvent }] = useAppService();
  const isVaccinationEvent = useIsVaccinationEvent();

  useEffect(() => {
    setTitle("Dashboard");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentEvent) {
    return <Redirect to={PATH_EVENT_HOME} />;
  }
  return isVaccinationEvent ? <VaccinationDashboard /> : <TestingDashboard />;
};
