import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import React, { FunctionComponent } from "react";

const types = [
  { id: 1, name: "Business" },
  { id: 2, name: "School" },
  { id: 3, name: "Other" },
];

const valueRenderer = (value: unknown) =>
  types.find((type) => type.id === (value as number))?.name ??
  (value as string);

export const SelectOrgType: FunctionComponent<TextFieldProps> = ({
  SelectProps,
  ...props
}) => {
  return (
    <TextField
      {...props}
      select
      SelectProps={{ ...SelectProps, renderValue: valueRenderer }}
    >
      {types.map((type) => (
        <MenuItem key={type.id} value={type.id}>
          {type.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
