import { Typography } from "@material-ui/core";
import React, { FunctionComponent, useEffect } from "react";

import { usePageTitle } from "../hooks";
import { useAppService } from "../state";

import { EventSelection } from "./event-finder";

export const EventDashboardPage: FunctionComponent = () => {
  const [{ isReady, currentOrganization }] = useAppService();
  const setTitle = usePageTitle();

  useEffect(() => {
    setTitle("Event Dashboard");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isReady && currentOrganization == null ? (
    <Typography variant="h2">
      Welcome to Relief Telemed. Please select an Organization
    </Typography>
  ) : (
    <EventSelection />
  );
};
