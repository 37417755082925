import {
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
} from "@material-ui/core";
import { Event as EventIcon } from "@material-ui/icons";
import React, {
  FormEventHandler,
  FunctionComponent,
  useMemo,
  useState,
  ChangeEventHandler,
  useCallback,
} from "react";

import {
  Button,
  DatetimeInput,
  FormModal,
  StateSelector,
} from "../../components";
import { defaultPromoClinic } from "../../config";
import {
  Address,
  ApiState,
  EventInfo,
  logger,
  OrganizationInfo,
  PromotionInfo,
  Organization,
  Promotion,
  useCreateEvent,
  useCreateOrganization,
  useCreatePromotion,
  validateUrl,
} from "../../core";
import { curryValidator } from "../../helpers";
import { useValidatedState } from "../../hooks";
import {
  CreateOrganizationForm,
  SelectOrganization,
} from "../../organizations";
import { CreatePromotionForm, SelectPromotion } from "../../promotions";
import { useAppService } from "../../state";
import { SelectEventType } from "../event-type-select";

interface EventFormProps {
  onClose?: () => void;
  open?: boolean;
}

const useVisitModalStyles = makeStyles((theme) => ({
  switchLabel: {
    ...theme.typography.body1,
  },
  primaryAction: {
    textAlign: "right",
  },
}));

const isNullishString = (value?: string): value is undefined => {
  if (!value) {
    return true;
  }

  return value === "";
};

const createAddress = ({
  street,
  addressee,
  city,
  state,
  postcode,
}: Partial<Address>): Address | undefined => {
  if (
    isNullishString(street) ||
    isNullishString(city) ||
    isNullishString(state) ||
    isNullishString(postcode)
  ) {
    return undefined;
  }
  return {
    street,
    addressee: isNullishString(addressee) ? undefined : addressee,
    city,
    state,
    postcode,
  };
};

export const EventForm: FunctionComponent<EventFormProps> = ({
  onClose,
  open = false,
}) => {
  const classes = useVisitModalStyles();
  const [
    { currentEvent, currentOrganization },
    ,
    { fetchEvents },
  ] = useAppService();

  const [selectOrg, changeOrgMode] = useState(true);
  const [selectPromo, changePromoMode] = useState(true);
  const [organization, changeOrg] = useState(currentOrganization?.id ?? "");
  const [promotion, changePromo] = useState(
    typeof currentEvent?.promotion === "string"
      ? currentEvent?.promotion
      : currentEvent?.promotion?.id,
  );
  const [name, changeName] = useState("");
  const [preMessage, changePreMessage] = useState("");
  const [postMessage, changePostMessage] = useState("");
  const [documentUrl, isValidUrl, setDocumentUrl] = useValidatedState(
    "",
    curryValidator<string>(validateUrl),
  );

  const [orgForm, setOrgForm] = useState<OrganizationInfo | undefined>();

  const [promoForm, setPromoForm] = useState<PromotionInfo | undefined>();

  const [eventTypeId, setEventTypeId] = useState<string | undefined>();

  const [startedAt, setStartDate] = useState<Date | null>(null);
  const [endedAt, setEndDate] = useState<Date | null>(null);
  const [showResults, setShowResults] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [isVisitRequired, setVisitRequired] = useState(false);

  const [street, changeStreet] = useState("");
  const [addressee, changeAddressee] = useState("");
  const [city, changeCity] = useState("");
  const [state, changeState] = useState("");
  const [postcode, changePostcode] = useState("");

  const { mutateAsync: createOrganizationMutate } = useCreateOrganization();
  const { mutateAsync: createPromotionMutate } = useCreatePromotion();
  const { mutateAsync: createEventMutate } = useCreateEvent();

  const [s3CsvFolder, changeS3csv] = useState("");
  const [s3ReqFolder, changeS3req] = useState("");
  const [linksClinic, changeLinksClinic] = useState("");
  const [participantsSignup, setParticipantsSignup] = useState(false);
  const [guardianRequired, setGuardianRequired] = useState(false);
  const [filesRequired, setFilesRequired] = useState(false);
  const [autoSchedule, setAutoSchedule] = useState(false);
  const [linksAutoReport, setLinksAutoReport] = useState(false);
  const [requireConsentSignature, setRequireConsentSignature] = useState(false);
  const [laboratory, setLab] = useState("");
  const [eventCode, setEventCode] = useState("");
  const newEvent: EventInfo = useMemo(() => {
    return {
      address: createAddress({
        street,
        addressee,
        city,
        state,
        postcode,
      }),
      endedAt: endedAt ?? undefined,
      isVisitRequired,
      name,
      preMessage,
      postMessage,
      showActionPlans: showPlans,
      showResults,
      startedAt: startedAt ?? undefined,
      type: eventTypeId,
      planningDocument: documentUrl,
      participantsSignup,
      guardianRequired,
      filesRequired,
      autoSchedule,
      linksAutoReport,
      requireConsentSignature,
      s3CsvFolder,
      s3ReqFolder,
      linksClinic,
      laboratory,
      eventCode,
    };
  }, [
    startedAt,
    endedAt,
    isVisitRequired,
    name,
    postMessage,
    preMessage,
    showPlans,
    showResults,
    street,
    addressee,
    city,
    state,
    postcode,
    eventTypeId,
    documentUrl,
    participantsSignup,
    guardianRequired,
    filesRequired,
    autoSchedule,
    linksAutoReport,
    requireConsentSignature,
    s3CsvFolder,
    s3ReqFolder,
    linksClinic,
    laboratory,
    eventCode,
  ]);

  const toggleOrgMode = () => {
    changeOrgMode(!selectOrg);
  };

  const togglePromoMode = () => {
    changePromoMode(!selectPromo);
  };

  const toggleShowPlans: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setShowPlans(target.checked);
  };

  const toggleShowResults: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setShowResults(target.checked);
  };

  const toggleRequireVisits: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setVisitRequired(target.checked);
  };

  const toggleParticipantsSignup: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setParticipantsSignup(target.checked);
  };

  const toggleGuardianRequired: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setGuardianRequired(target.checked);
  };

  const toggleFilesRequired: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setFilesRequired(target.checked);
  };

  const toggleAutoSchedule: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setAutoSchedule(target.checked);
  };

  const toggleLinksAutoReport: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setLinksAutoReport(target.checked);
  };

  const toggleRequireConsentSignature: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setRequireConsentSignature(target.checked);
  };

  const handleSubmit: FormEventHandler<
    HTMLFormElement & HTMLDivElement
  > = useCallback(
    (formEvent) => {
      formEvent.preventDefault();
      const submitEventCreation = async () => {
        const selectedOrg: string = selectOrg
          ? organization!
          : (await createOrganizationMutate(orgForm!)).id;
        const selectedPromotion: string = selectPromo
          ? promotion!
          : (await createPromotionMutate(promoForm!)).id;

        const createdEvent = await createEventMutate({
          ...newEvent,
          organization: selectedOrg!,
          promotion: selectedPromotion!,
        });
        logger.debug("Event was created", createdEvent);
        fetchEvents();
      };
      submitEventCreation();
      if (onClose) {
        onClose();
      }
    },
    [
      selectOrg,
      organization,
      orgForm,
      selectPromo,
      promotion,
      promoForm,
      newEvent,
      createOrganizationMutate,
      createPromotionMutate,
      createEventMutate,
      fetchEvents,
      onClose,
    ],
  );

  // const findLaboratory = (
  //   <SelectLaboratory
  //     value={organization}
  //     onChange={changeOrg}
  //     helperText={
  //       <>
  //         Select the organization that is requesting the event or
  //         <Button size="small" variant="text" onClick={toggleOrgMode}>
  //           create a new organization
  //         </Button>
  //       </>
  //     }
  //   />
  // );

  const findOrganization = (
    <SelectOrganization
      value={organization}
      onChange={changeOrg}
      helperText={
        <>
          Select the organization that is requesting the event or
          <Button size="small" variant="text" onClick={toggleOrgMode}>
            create a new organization
          </Button>
        </>
      }
    />
  );

  const createOrganization = (
    <CreateOrganizationForm
      onChange={setOrgForm}
      helperText={
        <>
          Enter a name for the organization or
          <Button size="small" variant="text" onClick={toggleOrgMode}>
            select an existing organization
          </Button>
        </>
      }
    />
  );

  const findPromotion = (
    <SelectPromotion
      value={promotion}
      onChange={changePromo}
      helperText={
        <>
          Select the promotion to associate with the event or{" "}
          <Button size="small" variant="text" onClick={togglePromoMode}>
            create a new promotion
          </Button>
        </>
      }
    />
  );

  const createPromotion = (
    <CreatePromotionForm
      onChange={setPromoForm}
      helperText={
        <>
          Enter a code for the promotion or
          <Button size="small" variant="text" onClick={togglePromoMode}>
            select an existing promotion
          </Button>
        </>
      }
    />
  );

  return (
    <FormModal
      icon={EventIcon}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      onSubmit={handleSubmit}
      open={open}
      scroll="body"
      title="Add New Event"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button type="submit" disabled={!isValidUrl} id="submit-modal-form">
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="name"
            label="Event Name"
            helperText="Enter something to distinguish this from other events."
            onChange={({ target }) => changeName(target.value)}
            required
            value={name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="eventCode"
            label="Event Code"
            helperText="Event Code."
            onChange={({ target }) => setEventCode(target.value)}
            value={eventCode}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectEventType value={eventTypeId} onChange={setEventTypeId} />
        </Grid>
        <Grid item xs={12}>
          {selectOrg ? findOrganization : createOrganization}
        </Grid>
        <Grid item xs={12}>
          {selectPromo ? findPromotion : createPromotion}
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            id="lab"
            label="Lab"
            helperText="lab."
            onChange={({ target }) => setLab(target.value)}
            value={laboratory}
          />
        </Grid> */}
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={showResults}
                onChange={toggleShowResults}
              />
            }
            label="Show Results to Organization Contacts"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={showPlans}
                onChange={toggleShowPlans}
              />
            }
            label="Enable Action Plans"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={isVisitRequired}
                onChange={toggleRequireVisits}
              />
            }
            label="Require Video Visits"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={participantsSignup}
                onChange={toggleParticipantsSignup}
              />
            }
            label="Participants Signup"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={guardianRequired}
                onChange={toggleGuardianRequired}
              />
            }
            label="Guardian Required"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={autoSchedule}
                onChange={toggleAutoSchedule}
              />
            }
            label="Auto Schedule"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={requireConsentSignature}
                onChange={toggleRequireConsentSignature}
              />
            }
            label="Require Consent Signature"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={linksAutoReport}
                onChange={toggleLinksAutoReport}
              />
            }
            label="Links Auto Report"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="s3CsvFolder"
            label="S3 csv folder"
            helperText="S3 csv folder."
            onChange={({ target }) => changeS3csv(target.value)}
            value={s3CsvFolder}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="s3ReqFolder"
            label="S3 required folder"
            helperText="S3 required folder."
            onChange={({ target }) => changeS3req(target.value)}
            value={s3ReqFolder}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                checked={filesRequired}
                onChange={toggleFilesRequired}
              />
            }
            label="Files Required"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="linksClinic"
            label="Links Clinic"
            helperText="Links Clinic."
            onChange={({ target }) => changeLinksClinic(target.value)}
            value={linksClinic}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DatetimeInput
            label="Start Date and Time"
            InputProps={{
              id: "start-date-time-input",
            }}
            value={startedAt}
            onChange={setStartDate}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DatetimeInput
            label="End Date and Time"
            InputProps={{
              id: "end-date-time-input",
            }}
            value={endedAt}
            onChange={setEndDate}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address-street"
            label="Street Address"
            onChange={({ target }) => changeStreet(target.value)}
            value={street}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address-addressee"
            label="Address Line 2"
            onChange={({ target }) => changeAddressee(target.value)}
            value={addressee}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <TextField
            id="address-city"
            label="City"
            onChange={({ target }) => changeCity(target.value)}
            value={city}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <StateSelector
            value={state}
            id="form-state-selector"
            onChange={({ target }) => changeState(target.value)}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            id="address-postcode"
            label="ZIP Code"
            onChange={({ target }) => changePostcode(target.value)}
            value={postcode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="pre-message"
            label="Pre-Visit Message"
            helperText="This message is shown to the participant when they are added to the video visit queue."
            onChange={({ target }) => changePreMessage(target.value)}
            value={preMessage}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="post-message"
            label="Post-Visit Message"
            helperText="This message is show to the participant when their video visit is completed."
            onChange={({ target }) => changePostMessage(target.value)}
            value={postMessage}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="document-url"
            label="Planning Document URL"
            error={!isValidUrl}
            onChange={setDocumentUrl}
            value={documentUrl}
            autoComplete="off"
          />
        </Grid>
      </Grid>
    </FormModal>
  );
};
