import { downloadParticipantCsv } from "@relieftelemed/platform";
import React, { FunctionComponent, useCallback } from "react";

import { Button } from "../components";
import { useAppService } from "../state";

export const DownloadParticipantButton = () => {
  const [{ currentEvent }] = useAppService();
  const handleDownloadParticipantsCsv = useCallback(() => {
    downloadParticipantCsv(currentEvent?.id!).then((csvBlobData) => {
      const blob = new Blob([csvBlobData]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "participants.csv";
      link.click();
    });
  }, [currentEvent]);

  return (
    <Button onClick={handleDownloadParticipantsCsv}>Export Participants</Button>
  );
};
