import { Grid, TextField } from "@material-ui/core";
import React, {
  ChangeEventHandler,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { OrganizationInfo, OrganizationType } from "../../core";

import { SelectOrgType } from "./select-type";

interface CreateOrgFormProps {
  helperText?: ReactNode;
  onChange?: (organization: OrganizationInfo) => void;
}

export const CreateOrganizationForm: FunctionComponent<CreateOrgFormProps> = ({
  helperText,
  onChange,
}) => {
  const [name, changeName] = useState("");
  const [type, changeType] = useState<OrganizationType | undefined>();

  useEffect(() => {
    if (onChange) {
      onChange({ name, type });
    }
  }, [onChange, name, type]);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (event) => {
    changeName(event.target.value);
  };

  const handleChangeType: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.value === "") {
      changeType(undefined);
      return;
    }

    changeType(event.target.value as OrganizationType);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <TextField
          id="organization-name"
          helperText={helperText}
          label="Organization Name"
          required
          value={name}
          onChange={handleChangeName}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <SelectOrgType onChange={handleChangeType} value={type ?? ""} />
      </Grid>
    </Grid>
  );
};
