import { Event } from "@relieftelemed/platform";
import { useEffect, useState } from "react";

import { isVaccinationEventType } from "../helpers";
import { useAppService } from "../state";

export const useIsEventVaccination = (event?: Event) => {
  const [{ eventTypes }] = useAppService();
  const [isVaccinationEvent, setIsVaccination] = useState(false);

  useEffect(() => {
    if (eventTypes.length !== 0) {
      const typeId =
        typeof event?.type === "string" ? event?.type : event?.type?.id;
      const type = eventTypes.find(({ id }) => id === typeId);
      setIsVaccination(isVaccinationEventType(type));
    } else {
      setIsVaccination(false);
    }
  }, [event, eventTypes]);

  return isVaccinationEvent;
};
