import { endOfDay, isAfter, startOfDay } from "date-fns";

import { EventParticipant, TestResult } from "../core";
import { allCovid } from "../labs";

import { getParticipantEventId } from "./get-participant-event";
import { sortBy } from "./sort";

export function getCurrentResults(participant: EventParticipant): TestResult[] {
  if (!participant.results) {
    return [];
  }

  const eventId = getParticipantEventId(participant);

  const visitId =
    typeof participant.visit === "string"
      ? participant.visit
      : participant.visit?.id;

  const foundResults = participant.results.filter((result) => {
    const matchedEvent = result.event === eventId;
    const matchedVisit = result.visit === visitId;

    let isMatch = matchedEvent && matchedVisit;

    // Handle previous schema which did not use visit id
    if (matchedEvent && !matchedVisit && !result.visit) {
      const isAfterRecord = isAfter(
        endOfDay(result.createdAt),
        startOfDay(participant.createdAt),
      );

      isMatch = isAfterRecord;
    }

    return isMatch;
  });

  return foundResults;
}

export function getResult(
  testName: string,
  participant: EventParticipant,
): TestResult | undefined {
  const matcher = new RegExp(
    `^${testName.replace("(", "\\(").replace(")", "\\)")}$`,
    "i",
  );
  const results = getCurrentResults(participant);
  results.sort(sortBy("createdAt"));

  return results.find((result) => matcher.test(result.name));
}

export const getLabResults = (participant: EventParticipant): TestResult[] => {
  const results = getCurrentResults(participant);

  return results.filter((result) => {
    const actualTest = allCovid.find((test) => test.name === result.name);

    return actualTest?.type !== "rapid";
  });
};

export const getRapidResults = (
  participant: EventParticipant,
): TestResult[] => {
  const results = getCurrentResults(participant);

  return results.filter((result) => {
    const actualTest = allCovid.find((test) => test.name === result.name);

    return actualTest?.type === "rapid";
  });
};
