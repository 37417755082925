import { State } from "xstate";

import { logger, setBaseHeader } from "../core";

import { ApplicationContext, ApplicationEvents } from "./types";

const STATE_KEY = "events-state";

export const clearSavedState = () => {
  try {
    localStorage.removeItem(STATE_KEY);
  } catch (error) {
    logger.warn("Unable to clear saved state", error);
  }
};

export const saveState = (
  state: State<ApplicationContext, ApplicationEvents, any, any>,
) => {
  try {
    const trimmedState = {
      ...state,
      context: {
        ...state.context,
        locations: [],
        participants: [],
        orgEvents: [],
      },
    };

    const jsonState = JSON.stringify(trimmedState);
    localStorage.setItem(STATE_KEY, jsonState);
  } catch (error) {
    logger.error("Unable to save state", error);
  }
};

export const restoreState = (): State<
  ApplicationContext,
  ApplicationEvents,
  any,
  any
> | null => {
  if (!localStorage) {
    return null;
  }

  const jsonState = localStorage.getItem(STATE_KEY);

  if (!jsonState) {
    return null;
  }

  const stateDefinition = JSON.parse(jsonState);

  const state = State.create<ApplicationContext, ApplicationEvents>(
    stateDefinition,
  );

  state.context.orgEvents = [];
  state.context.participants = [];
  state.context.currentEvent = undefined;

  if (state.context.token) {
    logger.info("Restoring user token");
    setBaseHeader("Authorization", `Token ${state.context.token}`);
  }

  return state;
};
