import {
  EventParticipant,
  PatientVaccination,
  LastVaccinationData,
  TestResult,
} from "@relieftelemed/platform";
import { DoneInvokeEvent, InterpreterFrom, AnyStateMachine } from "xstate";

export interface VaccineInfo {
  vaccineLot: string;
  vaccineId: string;
  vaccineSeries: string;
  patientVaccinationId?: string;
  linksReady: boolean;
  shotPlacement: string;
  attmidCode: string;
  dose: string;
  doseUnit: string;
  administeredDateString: string;
  anatomicalRoute: string;
}

export interface TestingInfo {
  testResult: string;
  testName: string;
  testResultId?: string;
  emailUser: boolean;
}

export interface CheckInContext {
  participantId?: string;
  participant?: EventParticipant;
  vaccineInfo?: VaccineInfo;
  checkInLocation?: string;
  testingInfo?: TestingInfo;
}

export interface CheckInSchema {
  states: {
    init: {};
    patientSelection: {};
    checkInPatient: {};
    fetchForParticipantDetails: {};
    persistChanges: {};
    done: {};
  };
}

export interface SetParticipantEvent {
  type: "SET_PARTICIPANT_ID";
  participantId?: string;
}

export interface UpdateVaccineInfoEvent {
  type: "updateVaccineInfo";
  vaccineInfo: VaccineInfo;
}
export interface PersistChangesEvent {
  type: "persistVaccineInfoChanges";
}

export interface SetSelectedLocationEvent {
  type: "selectLocation";
  checkInLocation: string;
}

export interface PartialUpdateParticipantEvent {
  type: "partialUpdateParticipant";
  participant: Partial<EventParticipant>;
}

export interface UpdateTestInfoEvent {
  type: "updateTestingInfo";
  testingInfo: TestingInfo;
}

// export interface AssignParticipantEvent {
//   type: "ASSIGN_PARTICIPANT";
//   data: EventParticipant;
// }

export type RequestDoneEvent = { type: "AUTH.DONE" };

export type CheckInEvents =
  | DoneInvokeEvent<EventParticipant>
  | UpdateTestInfoEvent
  | PartialUpdateParticipantEvent
  | SetParticipantEvent
  | SetSelectedLocationEvent
  | UpdateVaccineInfoEvent
  | PersistChangesEvent
  | DoneInvokeEvent<any>;

export const isDoneEvent = <TData = {}>(
  event: CheckInEvents,
): event is DoneInvokeEvent<TData> =>
  (event as DoneInvokeEvent<TData>).data !== undefined;

export interface CheckInHookProps {
  maybeParticipant?: EventParticipant;
}

export interface HookActions {
  onSelectParticipant: (maybeParticipant?: EventParticipant) => void;
  onUpdateVaccinationInfo: (vaccineInfo: VaccineInfo) => void;
  onPersistChanges: () => void;
  onSelectLocation: (checkInLocation: string) => void;
  onPartialEventParticipantUpdate: (
    partialParticipant: Partial<EventParticipant>,
  ) => void;
  onUpdateTestingInfo: (testingInfo: TestingInfo) => void;
}

export interface HookSelectors {
  currentParticipant?: EventParticipant;
  patientVaccinations?: PatientVaccination[];
  vaccinationData?: LastVaccinationData;
  checkInLocation?: string;
  vaccineInfo?: VaccineInfo;
  testingInfo?: TestingInfo;
  patientTestResults?: TestResult[];
}

export type HookProperties = [
  HookActions,
  HookSelectors,
  InterpreterFrom<AnyStateMachine>,
];
