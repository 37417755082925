import { useState, useEffect } from "react";

import { logger } from "../core";
import { SpecimenLabelDetails } from "../helpers";

import { useLabelPrinter } from "./use-label-printer";
import { useSpecimenLabel } from "./use-specimen-label";

export enum LabelPrintStatus {
  unavailable = "UNAVAILABLE",
  error = "ERROR",
  printing = "PRINTING",
  ready = "READY",
}

type UsePrintLabel = [
  (details?: SpecimenLabelDetails) => void,
  LabelPrintStatus,
];

export const usePrintLabel = (): UsePrintLabel => {
  const [status, changeStatus] = useState<LabelPrintStatus>(
    LabelPrintStatus.unavailable,
  );
  const [labelDetails, changeLabel] = useState<SpecimenLabelDetails>();
  const label = useSpecimenLabel(labelDetails);
  const printer = useLabelPrinter();

  // Reset status after printing
  useEffect(() => {
    if (!label && status === LabelPrintStatus.printing) {
      changeStatus(LabelPrintStatus.unavailable);
    }
  }, [label, printer, status]);

  // Trigger that we are ready
  useEffect(() => {
    if (
      label &&
      printer &&
      (status === LabelPrintStatus.unavailable ||
        status === LabelPrintStatus.error)
    ) {
      changeStatus(LabelPrintStatus.ready);
    }
  }, [label, printer, status]);

  // Print if ready
  useEffect(() => {
    if (status === LabelPrintStatus.ready) {
      try {
        changeStatus(LabelPrintStatus.printing);
        label!.print(printer!.name, "", "");
        changeLabel(undefined);
      } catch (error) {
        logger.error(error.message, error.exceptionMessage);
        changeStatus(LabelPrintStatus.error);
      }
    }
  }, [label, printer, status]);

  return [changeLabel, status];
};
