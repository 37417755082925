import { DialogContentText, Grid, makeStyles } from "@material-ui/core";
import { Assignment } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";

import { ActionButton, FormModal } from "../../components";
import { EventParticipant } from "../../core";

import { ParticipantFilter, SelectParticipant } from "./select-participant";

interface SelectParticipantFormProps {
  filter?: ParticipantFilter;
  onChange: (participant?: EventParticipant) => void;
  onClose?: () => void;
}

const useVisitModalStyles = makeStyles({
  primaryAction: {
    textAlign: "right",
  },
});

export const SelectParticipantForm: FunctionComponent<SelectParticipantFormProps> = ({
  filter = "checkedIn",
  onChange,
  onClose,
}) => {
  const classes = useVisitModalStyles();
  const [participant, changeParticipant] = useState<
    EventParticipant | undefined
  >();

  const handleSubmit = () => {
    onChange(participant);
  };

  return (
    <FormModal
      fullWidth
      open
      icon={Assignment}
      onClose={onClose}
      scroll="body"
      title="Add Test Results"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <ActionButton onClick={handleSubmit}>Next</ActionButton>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText variant="h3">Select a participant</DialogContentText>
      <SelectParticipant
        filter={filter}
        value={participant}
        onChange={changeParticipant}
      />
    </FormModal>
  );
};
