import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent } from "react";

import { AddContactForm } from "./form";

interface AddContactProps {
  onCancel?: () => void;
  open: boolean;
}

export const AddContact: FunctionComponent<AddContactProps> = ({
  onCancel,
  open,
}) => {
  const flags = useFlags();

  if (!flags.addOrganization || !open) {
    return null;
  }

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return <AddContactForm onClose={handleClose} open={open} />;
};
