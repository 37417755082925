import { validateMinLength } from "@relieftelemed/platform";

import { TestingInfo } from "../state";

export const canSubmitForTesting = (testingInfo: TestingInfo) => {
  const hasTestName = validateMinLength(testingInfo.testName);
  // Te falta el EDIT

  return hasTestName;
};
