import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, useState } from "react";

import { EventParticipant } from "../../core";
import { SelectParticipantForm } from "../select";

import { AddResultForm } from "./form";

interface AddTestResultProps {
  onCancel?: () => void;
  open: boolean;
}

export const AddTestResult: FunctionComponent<AddTestResultProps> = ({
  onCancel,
  open,
}) => {
  const flags = useFlags();
  const [participant, changeParticipant] = useState<
    EventParticipant | undefined
  >();

  if (!flags.addTestResults || !open) {
    return null;
  }

  const handleClose = () => {
    changeParticipant(undefined);

    if (onCancel) {
      onCancel();
    }
  };

  if (participant) {
    return <AddResultForm participant={participant} onClose={handleClose} />;
  }
  return (
    <SelectParticipantForm onChange={changeParticipant} onClose={handleClose} />
  );
};
