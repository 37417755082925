import { Event, EventParticipant, logger } from "@relieftelemed/platform";

export const getParticipantEventId = (
  participant: EventParticipant,
): string | undefined => {
  if (!participant.event) {
    logger.warn(`Participant ${participant.id} is missing an event`);
  }

  const eventId =
    typeof participant.event === "string"
      ? participant.event
      : participant.event?.id;

  return eventId;
};

export const getParticipantEvent = (
  participant: EventParticipant,
  events?: Event[],
): Event | undefined => {
  const eventId = getParticipantEventId(participant);

  if (events) {
    return events.find((ev) => ev.id === eventId);
  }

  return undefined;
};
