import {
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  CircularProgress,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { parseISO } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

import {
  EventParticipant,
  EventParticipantStatus,
  useGetEventParticipantsPaginated,
  Event,
} from "../../core";
import { useIsVaccinationEvent, useRouteAsPagination } from "../../hooks";
import { useAppService, GLOBAL_EVENT } from "../../state";
import { AddActionPlanForm } from "../action-plan";

import {
  Fields,
  fieldMap,
  rosterFields,
  stagesFields,
  stageTimesFields,
  detailsFields,
  resultsFields,
  pcrFields,
  plansFields,
  TableVariants,
  vaccinationStageTimesFields,
  vaccinationStagesFields,
  checklistFields,
} from "./fields";
import { ParticipantRow } from "./participant-row";
import { TableResultsHeader } from "./table-results-header";

interface ParticipantsTableProps {
  patientHistory?: EventParticipant[];
  variant?: TableVariants;
}
interface ParticipantsTableInnerProps extends ParticipantsTableProps {
  allEvents: Event[];
  currentEvent: Event;
  events: Event[];
  nameFilter: string;
  dateFilter?: Date;
  page?: number;
  currentLocationId?: string;
  handleChangePage: (event: object, page: number) => void;
  // handleSort: (sortOp: SortOptions, sort: string) => void;
}

type Order = "asc" | "desc";

const useTableStyles = makeStyles((theme) => ({
  mainContainer: {
    overflow: "hidden",
    height: "90%",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flex: 1,
  },
  table: {},
  body: {},
  paginationContainer: {
    height: "15%",
    paddingTop: "16px",
    display: "table",
    alignSelf: "center",
    "& > *": {
      display: "table-cell",
      verticalAlign: "middle",
    },
  },
}));

const noOp = () => null;
const isEmpty = (a: string | Array<any>) => a.length === 0;

const supportedSortingMap: Record<string, string> = {
  name: "patient_name",
  gender: "patient_gender",
  dob: "patient_dob",
  email: "patient_email",
  mobile: "patient_phone",
  appointmentDate: "appointment_time",
};

const supportedSorting = Object.keys(supportedSortingMap);

const ParticipantsTableInner: FunctionComponent<ParticipantsTableInnerProps> = ({
  patientHistory,
  variant = "roster",
  allEvents,
  currentEvent,
  dateFilter,
  events,
  nameFilter,
  page = 1,
  currentLocationId,
  handleChangePage,
}) => {
  const classes = useTableStyles();
  const isVaccinationEvent = useIsVaccinationEvent();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<Fields | string>(Fields.name);
  const sortingParams = useMemo(
    () => ({
      sort: `${order === "desc" ? "-" : ""}${supportedSortingMap[orderBy]}`,
    }),
    [order, orderBy],
  );
  const maybeCheckListReview = useMemo(() => {
    if (variant === "checklistreview") {
      return {
        status: [
          EventParticipantStatus.NEEDSNURSEREVIEW,
          EventParticipantStatus.APPROVED,
        ],
        checkedIn: null,
      };
    }

    return {};
  }, [variant]);

  const maybeCompletedVideoVisitParams = useMemo(() => {
    if (variant === "results") {
      const shouldFilterByVisited = currentEvent?.isVisitRequired ?? true;

      if (!shouldFilterByVisited) {
        return {};
      }

      return {
        hasVisit: true,
      };
    }

    return {};
  }, [currentEvent, variant]);

  const {
    data: dataParticipants,
    isLoading,
    isError,
  } = useGetEventParticipantsPaginated(currentEvent.id, {
    page,
    // detail: true,
    name: isEmpty(nameFilter) ? undefined : nameFilter,
    apptDate: isVaccinationEvent ? dateFilter : undefined,
    locationId: currentLocationId,
    ...maybeCheckListReview,
    ...maybeCompletedVideoVisitParams,
    ...sortingParams,
  });

  useEffect(() => {
    if (isError && page !== 1) {
      handleChangePage({}, 1);
    }
  }, [isError, page, handleChangePage]);

  const participants = useMemo(
    (): EventParticipant[] => (isLoading ? [] : dataParticipants!.results),
    [dataParticipants, isLoading],
  );
  const pageCount = useMemo(
    () =>
      dataParticipants?.count == null
        ? 0
        : Math.ceil(dataParticipants?.count / 50),
    [dataParticipants],
  );
  const flags = useFlags();
  const [selectedVisit, setVisit] = useState("");
  const fields = useMemo(() => {
    switch (variant) {
      case "stages":
        return isVaccinationEvent ? vaccinationStagesFields : stagesFields;
      case "stagetimes":
        return isVaccinationEvent
          ? vaccinationStageTimesFields
          : stageTimesFields;
      case "details":
        return detailsFields;
      case "results":
        return resultsFields;
      case "pcr":
        return pcrFields;
      case "plans":
        return plansFields;
      case "checklistreview":
        return checklistFields;
      default:
        return rosterFields;
    }
  }, [isVaccinationEvent, variant]);

  const shownEvents = useMemo(() => {
    return currentEvent && GLOBAL_EVENT === currentEvent.id
      ? allEvents
      : events;
  }, [allEvents, currentEvent, events]);

  // const maybeCompletedVideoVisit = useMemo(() => {
  //   if (variant === "results") {
  //     const shouldFilterByVisited = currentEvent?.isVisitRequired ?? true;

  //     if (!shouldFilterByVisited) {
  //       return participants;
  //     }

  //     return participants.filter((participant): boolean =>
  //       Boolean(participant.visitedAt),
  //     );
  //   }

  //   return [...participants];
  // }, [currentEvent, participants, variant]);

  // const maybeHasPositiveResult = useMemo(() => {
  //   if (variant === "pcr") {
  //     return maybeCompletedVideoVisit.filter((participant): boolean => {
  //       const result = getResult(covidTest.name, participant);

  //       if (!result) {
  //         return false;
  //       }

  //       return parseBooleanResult(result);
  //     });
  //   }

  //   return [...maybeCompletedVideoVisit];
  // }, [maybeCompletedVideoVisit, variant]);

  const participantsToShow = useMemo(() => {
    if (patientHistory) {
      return patientHistory;
    }
    return participants;
  }, [participants, patientHistory]);

  const handleChangeSort = (value: Fields) => {
    const isAsc = orderBy === value && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(value);
  };

  const handleChangeSortResults = (value: string) => {
    const isAsc = orderBy === value && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(value);
  };

  const handleAddPlan = (visit: string) => () => {
    setVisit(visit);
  };

  let resultsHeader = null;
  if (variant === "results" || variant === "details") {
    let addActionPlan = null;
    if (variant === "results" && flags.addActionPlans) {
      addActionPlan = <TableCell />;
    }

    resultsHeader = (
      <>
        <TableResultsHeader
          order={order}
          orderBy={orderBy}
          onChangeSort={handleChangeSortResults}
        />
        {addActionPlan}
      </>
    );
  }

  return (
    <div className={classes.mainContainer} id="participants_table">
      <TableContainer className={classes.container}>
        <Table className={classes.table} size="medium" stickyHeader>
          <TableHead>
            <TableRow>
              {fields.map((fieldName, index) => {
                const field = fieldMap[fieldName];

                return supportedSorting.includes(fieldName) ? (
                  <TableCell
                    key={field.id}
                    align={index === 0 ? undefined : "center"}
                    sortDirection={orderBy === field.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === field.id}
                      direction={orderBy === field.id ? order : "asc"}
                      onClick={() => handleChangeSort(field.id)}
                    >
                      {field.label}
                      {orderBy === field.id ? (
                        <Typography variant="srOnly">
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Typography>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    key={field.id}
                    align={index === 0 ? undefined : "center"}
                  >
                    {field.label}
                  </TableCell>
                );
              })}
              {resultsHeader}
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {participantsToShow.map((participant) => (
              <ParticipantRow
                canAddActionPlans={flags.addActionPlan}
                currentEvent={currentEvent}
                events={shownEvents}
                fields={fields}
                key={participant.id}
                onAddPlan={handleAddPlan}
                participant={participant}
                variant={variant}
                onVisible={noOp}
              />
            ))}
          </TableBody>
          <AddActionPlanForm
            visit={selectedVisit}
            open={selectedVisit !== ""}
            onClose={() => setVisit("")}
          />
        </Table>
      </TableContainer>
      <div className={classes.paginationContainer}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          size="large"
        />
      </div>
    </div>
  );
};

export const ParticipantsTable: FunctionComponent<ParticipantsTableProps> = (
  props,
) => {
  const [
    {
      allEvents,
      currentEvent,
      events,
      nameFilter,
      dateFilter,
      currentLocation,
    },
  ] = useAppService();
  const { page, onChangePage, key } = useRouteAsPagination();
  const dateFilterFormatted =
    dateFilter == null ? undefined : parseISO(dateFilter);
  return currentEvent == null ? (
    <CircularProgress />
  ) : (
    <ParticipantsTableInner
      key={key}
      allEvents={allEvents}
      currentLocationId={currentLocation?.id}
      dateFilter={dateFilterFormatted || undefined}
      currentEvent={currentEvent}
      events={events}
      nameFilter={nameFilter}
      page={page}
      handleChangePage={onChangePage}
      // handleSort={onSort}
      {...props}
    />
  );
};
