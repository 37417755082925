import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  TextField,
  Box,
} from "@material-ui/core";
import { Event } from "@relieftelemed/platform";
import Fuse from "fuse.js";
import React, { FunctionComponent, useMemo, useState } from "react";

import { groupBy } from "../../helpers";
import { useAppService } from "../../state";

import { OrganizationSection } from "./organization-section";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      columnGap: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        columnCount: 3,
      },
      [theme.breakpoints.down("sm")]: {
        columnCount: 2,
      },
      [theme.breakpoints.down("xs")]: {
        columnCount: 1,
      },
    },
    items: {
      breakInside: "avoid",
      pageBreakInside: "avoid",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),
);

export const EventSelection: FunctionComponent = () => {
  const classes = useStyles();
  const [{ allEvents }] = useAppService();
  const [searchTerm, setSearchTerm] = useState("");

  const fuseEvents = useMemo(() => {
    return new Fuse(allEvents, {
      threshold: 0.3,
      keys: ["name", "orgName"],
    });
  }, [allEvents]);

  const filteredEvents = useMemo(() => {
    return searchTerm.length > 0
      ? fuseEvents.search(searchTerm).map((result) => result.item)
      : allEvents;
  }, [fuseEvents, searchTerm, allEvents]);

  const groupedEvents: Record<string, Event[]> = useMemo(
    () => groupBy<Event>(filteredEvents, "organization"),
    [filteredEvents],
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            id="event"
            label="Event"
            onChange={({ target }) => setSearchTerm(target.value)}
            value={searchTerm}
            placeholder="Search Event"
          />
        </Grid>
        <Grid item xs={12} className={classes.container} id="events_container">
          {Object.keys(groupedEvents).map((orgId, index) => (
            <Box
              key={orgId}
              className={`${classes.items} event_item_container`}
            >
              <OrganizationSection
                events={groupedEvents[orgId]}
                organization={{
                  id: orgId,
                  name: groupedEvents[orgId][0].orgName ?? "Orphaned Events",
                }}
              />
            </Box>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};
