import { Typography } from "@material-ui/core";
import React, { FunctionComponent, useEffect, useMemo } from "react";

import { usePageTitle } from "../hooks";
import { useAppService } from "../state";

import { DeliveryCard } from "./delivery-card";
import { LocationCard } from "./location-card";

export const CollectionPage: FunctionComponent = () => {
  const setTitle = usePageTitle();
  const [{ currentEvent, locations, participants }] = useAppService();

  useEffect(() => {
    setTitle("Specimen Delivery");
  });

  const availableForCollection = useMemo(() => {
    return participants.filter(
      (participant) =>
        !participant.specimenCollectedAt && participant.checkedInAt,
    );
  }, [participants]);

  const availableForDelivery = useMemo(() => {
    return participants.filter(
      (participant) =>
        !participant.specimenDeliveredAt && participant.specimenCollectedAt,
    );
  }, [participants]);

  const cards =
    locations.length > 1 ? (
      locations.map((location) => {
        const specimenFromLocation = availableForCollection.filter(
          (participant) => {
            const checkInLocation =
              typeof participant.checkInLocation === "string"
                ? participant.checkInLocation
                : participant.checkInLocation?.id;
            return checkInLocation === location.id;
          },
        );

        // Already collected from location this run, so don't show it
        if (
          specimenFromLocation.some((participant) =>
            Boolean(participant.specimenCollectedAt),
          )
        ) {
          return null;
        }

        return (
          <LocationCard
            key={location.id}
            title={location.name}
            participants={specimenFromLocation}
          />
        );
      })
    ) : (
      <LocationCard
        title={currentEvent?.name ?? "Event"}
        participants={availableForCollection}
      />
    );

  const deliveryCard =
    availableForDelivery.length > 0 ? (
      <DeliveryCard participants={availableForDelivery} />
    ) : null;

  return (
    <div>
      {deliveryCard}
      <Typography variant="h2">Locations Ready for Collection</Typography>
      {cards}
    </div>
  );
};
