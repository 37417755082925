import { useTheme } from "@material-ui/core";
import React, { FunctionComponent, useMemo } from "react";
import { Cell } from "recharts";

import { ChartEntry, DashboardCard, PieChart } from "../../components";
import { useAppService } from "../../state";
// This component is not being used
export const CheckedIn: FunctionComponent = () => {
  const theme = useTheme();
  const [{ participants }] = useAppService();

  const chartData: ChartEntry[] = useMemo(() => {
    const rawCounts: Record<string, number> = {
      yes: 0,
      no: 0,
    };

    const realCounts = participants.reduce((aggregate, participant) => {
      const addYes = participant.checkedInAt ? 1 : 0;
      const addNo = participant.checkedInAt ? 0 : 1;

      return {
        yes: aggregate.yes + addYes,
        no: aggregate.no + addNo,
      };
    }, rawCounts);

    return [
      { name: "Checked In", value: realCounts.yes },
      { name: "Not Checked In", value: realCounts.no },
    ];
  }, [participants]); // TODO this should come either from endpoint. or should be in a hook since its used more than once.

  return (
    <DashboardCard title="Participants Checked In">
      <PieChart data={chartData}>
        <Cell fill={theme.palette.primary.main} />
        <Cell fill={theme.palette.error.main} />
      </PieChart>
    </DashboardCard>
  );
};
