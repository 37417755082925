import {
  Avatar,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Card,
  CardHeader,
  CardActionArea,
} from "@material-ui/core";
import { Event, MinimalOrganization } from "@relieftelemed/platform";
import React, { FunctionComponent, useState } from "react";
import { Redirect } from "react-router-dom";

import { PATH_HOME } from "../../constants";
import { useAppService } from "../../state";

export interface OrganizationSectionProps {
  events: Event[];
  organization: MinimalOrganization;
}

const useOrganizationSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: "0.875rem",
      fontWeight: "bold",
      lineHeight: 1.14,
      letterSpacing: 1.05,
      width: 36,
      height: 36,
    },
  }),
);

export const OrganizationSection: FunctionComponent<OrganizationSectionProps> = ({
  events,
  organization,
}) => {
  const classes = useOrganizationSectionStyles();
  const [, , { changeOrganization, changeEvent }] = useAppService();
  const [orgChanged, setOrgChanged] = useState(false);

  const handleChangeOrganization = (
    newOrg: MinimalOrganization,
    event?: Event,
  ) => () => {
    changeEvent(event!);
    setOrgChanged(true);
  };

  if (orgChanged) {
    return <Redirect to={PATH_HOME} />;
  }

  const orgInitials = organization.name
    .split(" ")
    .map((segment: string) => {
      return segment.substr(segment.indexOf("#") + 1, 1);
    })
    .join("");

  return (
    <Card>
      <CardActionArea onClick={handleChangeOrganization(organization)}>
        <CardHeader
          avatar={
            <Avatar classes={{ root: classes.avatar }}>{orgInitials}</Avatar>
          }
          title={organization.name}
        />
      </CardActionArea>
      <List disablePadding className="organization_event_list">
        {events.map((event) => (
          <ListItem
            button
            onClick={handleChangeOrganization(organization, event)}
            className="organization_event_item"
            key={event.id}
          >
            <ListItemText>{event.name}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};
