import React, { FunctionComponent, useEffect } from "react";

import { usePageTitle } from "../../hooks";
import { useAppService } from "../../state";

import { ParticipantsTable } from "./table";

export const RosterPage: FunctionComponent = () => {
  const setTitle = usePageTitle();
  const [{ currentEvent }] = useAppService();

  useEffect(() => {
    const title = currentEvent
      ? `${currentEvent.orgName ?? "Event"} Roster (${currentEvent.name})`
      : undefined;
    setTitle("Roster", title);
  }, [currentEvent, setTitle]);

  return <ParticipantsTable />;
};
