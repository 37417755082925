import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { FormEventHandler } from "react";
import { Redirect } from "react-router";

import {
  ActionButton,
  Alert,
  FormCard,
  NotificationTrigger,
  PageTitle,
  PasswordInput,
} from "../components";
import { PATH_HOME, REGISTRATION_MACHINE } from "../constants";
import {
  useAnalytics,
  validateConfirmation,
  validateEmail,
  validateMinLength,
  validatePassword,
  validateTerms,
} from "../core";
import { curryValidator, curryConfirmationValidator } from "../helpers";
import {
  useValidatedConfirmation,
  useValidatedPhone,
  useValidatedState,
  useValidatedToggle,
} from "../hooks";
import { OnboardingLayout } from "../layouts";
import { useAppService } from "../state";

import { RegistrationService, useRegisterService } from "./state";

export const RegistrationPage = () => {
  const { track } = useAnalytics();
  const [
    { isLoggedIn },
    { [REGISTRATION_MACHINE]: registration },
  ] = useAppService();

  const [{ didFail, errors }, { register, reset }] = useRegisterService(
    registration as RegistrationService,
  );

  const [email, isEmailValid, changeEmail] = useValidatedState(
    "",
    curryValidator<string>(validateEmail),
  );

  const [firstName, isFirstNameValid, changeFirstName] = useValidatedState(
    "",
    curryValidator<string>(validateMinLength),
  );

  const [lastName, isLastNameValid, changeLastName] = useValidatedState(
    "",
    curryValidator<string>(validateMinLength),
  );

  const [
    password,
    confirmation,
    isPasswordValid,
    isConfirmationValid,
    changePassword,
    changeConfirmation,
  ] = useValidatedConfirmation(
    "",
    curryValidator<string>(validatePassword),
    curryConfirmationValidator(validateConfirmation),
  );

  const [mobile, isMobileValid, changeMobile] = useValidatedPhone();

  const [acceptedTerms, isTermsValid, changeTerms] = useValidatedToggle(
    false,
    validateTerms,
  );

  const allowSubmit = [
    isEmailValid,
    email.length > 0,
    isFirstNameValid,
    firstName.length > 0,
    isLastNameValid,
    lastName.length > 0,
    isMobileValid,
    mobile.length > 0,
    isPasswordValid,
    password.length > 0,
    isConfirmationValid,
    confirmation.length > 0,
    isTermsValid,
  ].every(Boolean);

  const handleSubmit: FormEventHandler<HTMLFormElement & HTMLDivElement> = (
    event,
  ) => {
    event.preventDefault();
    track("user.register", { email });
    register({
      email,
      firstName,
      lastName,
      mobile,
      password,
      confirmation,
      acceptedTerms,
    });
  };

  const handleDismissNotice = () => {
    reset();
  };

  if (isLoggedIn) {
    return <Redirect push to={PATH_HOME} />;
  }

  return (
    <OnboardingLayout>
      <PageTitle title="Registration" />
      <Box maxWidth={400}>
        <FormCard
          elevation={0}
          title="User Registration"
          onSubmit={handleSubmit}
          instructions="Start with your account login information."
          actions={
            <ActionButton type="submit" disabled={!allowSubmit}>
              Next
            </ActionButton>
          }
        >
          <Grid item xs={12} lg={6}>
            <TextField
              autoFocus
              error={!isFirstNameValid}
              id="first-name"
              label="First Name"
              onChange={changeFirstName}
              required
              value={firstName}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={!isLastNameValid}
              id="last-name"
              label="Last Name"
              onChange={changeLastName}
              required
              value={lastName}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={!isEmailValid}
              id="email"
              label="Email Address"
              onChange={changeEmail}
              required
              value={email}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="mobile"
              onChange={changeMobile}
              value={mobile}
              label="Mobile Number"
              required
              error={!isMobileValid}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <PasswordInput
              error={!isPasswordValid}
              id="password"
              label="Password"
              onChange={changePassword}
              required
              value={password}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              error={!isConfirmationValid}
              helperText={
                isConfirmationValid ? undefined : "Passwords do not match"
              }
              id="password-confirmation"
              label="Confirm Password"
              onChange={changeConfirmation}
              required
              type="password"
              value={confirmation}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptedTerms}
                  color="primary"
                  name="accept-terms"
                  onChange={changeTerms}
                />
              }
              label="I agree to Relief’s Terms &amp; Conditions and Privacy Policy"
            />
          </Grid>
        </FormCard>
      </Box>
      <NotificationTrigger open={didFail} onClose={handleDismissNotice}>
        <Alert
          message={errors[0] || "Generic Error"}
          onDismiss={handleDismissNotice}
        />
      </NotificationTrigger>
    </OnboardingLayout>
  );
};
