import { ReactElement } from "react";

import { TabTitleProps } from "../../components";

export interface ActionProps {
  disabled?: boolean;
  icon?: ReactElement;
  label?: string;
  onClick?: () => void;
}

export interface CheckinTitleProps extends TabTitleProps {
  prefix?: string;
}

export enum CheckinTabs {
  QUESTIONNAIRE = "QUESTIONNAIRE",
  TESTING = "TESTING",
  IDENTIFICATION = "PHOTO_CARDS",
  VACCINATIONS = "VACCINATIONS",
}

export type ValueSetter<T = string> = (avalues: T) => void;
