import { Button, makeStyles, TableCell, TableRow } from "@material-ui/core";
import React, { FunctionComponent, ReactNode, useRef } from "react";
import { useDebounce, useIntersection } from "react-use";

import { Event, EventParticipant } from "../../core";
import { getParticipantEvent } from "../../helpers";
import { useActionMenuContext } from "../../hooks";

import { fieldMap, Fields, TableVariants } from "./fields";
import { TableResultsSegment } from "./table-results-segment";

const useTableRowStyles = makeStyles((theme) => ({
  row: {
    "&:nth-of-type(3n+2)": {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

export interface ParticipantRowProps {
  canAddActionPlans: boolean;
  currentEvent?: Event;
  events: Event[];
  fields: Fields[];
  onAddPlan: (visit: string) => () => void;
  onVisible: () => void;
  participant: EventParticipant;
  variant: TableVariants;
}

export const ParticipantRow: FunctionComponent<ParticipantRowProps> = ({
  canAddActionPlans,
  currentEvent,
  events,
  fields,
  onAddPlan,
  onVisible,
  participant,
  variant,
}) => {
  const classes = useTableRowStyles();
  const ref = useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  useDebounce(
    () => {
      if ((intersection?.intersectionRatio ?? 0) > 0) {
        onVisible();
      }
    },
    1000,
    [intersection],
  );

  const { doCheckIn } = useActionMenuContext();

  const event = getParticipantEvent(participant, events);

  let actionPlanButton = null;
  if (event?.showActionPlans && participant.visit) {
    const visitId =
      typeof participant.visit === "string"
        ? participant.visit
        : participant.visit.id;

    actionPlanButton = participant.actionPlan ? (
      <Button color="primary" variant="outlined" onClick={onAddPlan(visitId)}>
        Append to Action Plan
      </Button>
    ) : (
      <Button color="primary" onClick={onAddPlan(visitId)}>
        Add Action Plan
      </Button>
    );
  }

  return (
    <TableRow
      className={`${classes.row} participant_row`}
      hover
      tabIndex={-1}
      ref={ref}
    >
      {fields.map((fieldName, fieldIndex) => {
        const field = fieldMap[fieldName];
        let value: ReactNode = "--";

        const fieldValue =
          variant === "stagetimes" && field.timeValue
            ? field.timeValue(participant, events, currentEvent)
            : field.value(participant, events, currentEvent);

        if (fieldValue) {
          value = fieldValue;
        }
        const MaybeCheckInPatient =
          // participant.checkedInAt == null &&
          field.id === "name" ? (
            <Button
              onClick={() => doCheckIn({ participant })}
              variant="outlined"
              size="small"
              id="check-in-edit-patient"
            >
              {participant.checkedInAt == null
                ? "Check In Patient"
                : "Edit Patient"}
            </Button>
          ) : null;

        return (
          <TableCell
            key={`${participant.id}-${field.id}`}
            scope={fieldIndex === 0 ? "row" : undefined}
            align={fieldIndex === 0 ? undefined : "center"}
          >
            {value}
            {MaybeCheckInPatient}
          </TableCell>
        );
      })}
      {variant === "results" || variant === "details" ? (
        <TableResultsSegment participant={participant} />
      ) : null}
      {variant === "results" && canAddActionPlans ? (
        <TableCell>{actionPlanButton}</TableCell>
      ) : null}
    </TableRow>
  );
};
