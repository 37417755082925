import {
  LinearProgress,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import React, { FunctionComponent, useState, ChangeEvent } from "react";

import { EventType } from "../../core";
import { useAppService } from "../../state";

export interface SelectEventTypeProps {
  onChange?: (selectedOrg: string) => void;
  value?: string;
}

export const SelectEventType: FunctionComponent<
  SelectEventTypeProps & Omit<TextFieldProps, "onChange">
> = ({ onChange, SelectProps, value: passedValue = "", ...props }) => {
  const [{ eventTypes }] = useAppService();
  const [internalValue, changeValue] = useState(passedValue);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? "";
    const callback = onChange ?? changeValue;

    callback(newValue);
  };

  if (eventTypes.length === 0) {
    return <LinearProgress />;
  }

  return (
    <TextField
      label="Event Types"
      id="form-event-type-selector"
      helperText="Select the event type"
      {...props}
      onChange={handleChange}
      value={passedValue || internalValue}
      select
      SelectProps={{ ...SelectProps }}
    >
      {(eventTypes! as EventType[]).map(({ id, name }) => (
        <MenuItem key={id} value={id} id={`event-type-menu-item-${id}`}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};
