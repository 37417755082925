import {
  Patient,
  useGetPatient,
  usePatientInsurance,
  useUploadInsuranceCard,
  useUploadPatientIdentification,
} from "@relieftelemed/platform";
import React, { FunctionComponent, useState } from "react";

import { useCheckinContext } from "../context";

import { PhotoCardContext } from "./context";

export const ContextWrapper: FunctionComponent = ({ children }) => {
  const { patientId: rawPatientId, participant } = useCheckinContext();
  const patientId = rawPatientId!;
  const patient = participant?.patient as Patient;

  const [identification, changeIdentification] = useState<File>();
  const {
    mutateAsync: saveIdentification,
    isLoading: isUploadingIdentification,
  } = useUploadPatientIdentification(patientId);

  const [insuranceBack, changeInsuranceBack] = useState<File>();
  const {
    mutateAsync: saveInsuranceBack,
    isLoading: isUploadingInsuranceBack,
  } = useUploadInsuranceCard(patientId);

  const [insuranceFront, changeInsuranceFront] = useState<File>();
  const {
    mutateAsync: saveInsuranceFront,
    isLoading: isUploadingInsuranceFront,
  } = useUploadInsuranceCard(patientId);

  return (
    <PhotoCardContext.Provider
      value={{
        patient: patient!,
        identification: {
          value: identification,
          setValue: changeIdentification,
          isUploading: isUploadingIdentification,
          upload: saveIdentification,
        },
        insuranceBack: {
          value: insuranceBack,
          setValue: changeInsuranceBack,
          isUploading: isUploadingInsuranceBack,
          upload: saveInsuranceBack,
        },
        insuranceFront: {
          value: insuranceFront,
          setValue: changeInsuranceFront,
          isUploading: isUploadingInsuranceFront,
          upload: saveInsuranceFront,
        },
      }}
    >
      {children}
    </PhotoCardContext.Provider>
  );
};
