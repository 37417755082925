type SortByFn<T = {}> = (a: T, b: T) => number;

export function sortBy<T = {}>(
  property: keyof T,
  ascending = true,
): SortByFn<T> {
  return (a: T, b: T) => {
    if (a[property] > b[property]) {
      return ascending ? 1 : -1;
    }

    if (a[property] < b[property]) {
      return ascending ? -1 : 1;
    }

    return 0;
  };
}
