import { CheckBox, Timeline } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import { NavItem } from "../../components";
import { PATH_REVIEW, PATH_STAGES } from "../../constants";

export const VaccineNavigation: FunctionComponent = () => {
  return (
    <>
      {/* <NavItem icon={Timeline} link={PATH_STAGES} text="Protocol Stages" /> */}
      <NavItem
        icon={CheckBox}
        link={PATH_REVIEW}
        text="Checklist Review"
        id="checklist_review_link"
      />
    </>
  );
};
