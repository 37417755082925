import DateFnsUtils from "@date-io/date-fns";
import {
  Container,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createBrowserHistory } from "history";
import React, { FunctionComponent } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { Router } from "react-router-dom";

import { AppUpdateNotifications } from "./app-updates";
import { DymoProvider } from "./components";
import { theme } from "./styles";

const history = createBrowserHistory();
export const queryClient = new QueryClient();

export const App: FunctionComponent = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        <StylesProvider injectFirst>
          <Router history={history}>
            {/* <DymoProvider> */}
            <Container disableGutters maxWidth="xl">
              {children!}
            </Container>
            {/* </DymoProvider> */}
            <AppUpdateNotifications />
          </Router>
        </StylesProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </QueryClientProvider>
);
