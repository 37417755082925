import {
  DialogContent as MuiDialogContent,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { DialogContentProps } from "./types";

const useDialogContentStyles = makeStyles({
  content: {
    height: "100%",
  },
  dividers: {
    paddingBottom: 0,
  },
});

export const DialogContent: FunctionComponent<DialogContentProps> = ({
  children,
  showDividers = false,
  ...props
}) => {
  const classes = useDialogContentStyles();

  return (
    <MuiDialogContent
      classes={{ root: classes.content, dividers: classes.dividers }}
      dividers={showDividers}
      {...props}
    >
      {children}
    </MuiDialogContent>
  );
};
