import React, { FunctionComponent } from "react";

import { EventParticipant } from "../../core";

import { ContextWrapper } from "./context-wrapper";
import { CheckInForm } from "./form";
import { TabContextProvider } from "./tab-context-wrapper";

export interface CheckInDialogProps {
  onClose?: () => void;
  open?: boolean;
  participant?: EventParticipant;
}

export const CheckInDialog: FunctionComponent<CheckInDialogProps> = (props) => {
  const { participant: maybeParticipant, onClose, ...restProps } = props;

  return (
    <ContextWrapper onClose={onClose} participant={maybeParticipant}>
      <TabContextProvider>
        <CheckInForm onClose={onClose} {...restProps} />
      </TabContextProvider>
    </ContextWrapper>
  );
};
