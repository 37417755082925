import React, { FunctionComponent, useMemo } from "react";

import { Alert, NotificationTrigger } from "../components";

import { UpdateState, useUpdateState } from "./context";

export const ServiceWorkerUpdated: FunctionComponent = () => {
  const { state, handleReload } = useUpdateState();

  const isUpdateAvailable = useMemo(() => state === UpdateState.update, [
    state,
  ]);

  return (
    <NotificationTrigger open={isUpdateAvailable} autoHideDuration={null}>
      <Alert
        dismissText="Update Now"
        message="There is a new version available."
        onDismiss={handleReload}
      />
    </NotificationTrigger>
  );
};
