import { formatISO } from "date-fns";
import { assign } from "xstate";

import { Event, logger, setBaseHeader, MinimalOrganization } from "../core";
import { applyIfNotNull } from "../helpers";

import {
  CHANGE_EVENT,
  CHANGE_NAME_FILTER,
  CHANGE_ORG,
  CHANGE_TIME,
  CHANGE_LOCATION,
  CHANGE_DATE_FILTER,
} from "./constants";
import {
  ApplicationContext,
  ApplicationEvents,
  DoneLoginData,
  GLOBAL_EVENT,
  GLOBAL_ORG,
  isDoneEvent,
  ChangeDateFilterEvent,
  UpdateEventsEvent,
  UpdateParticipantsEvent,
  UpdateLocationsEvent,
} from "./types";

const globalOrg: MinimalOrganization = {
  id: GLOBAL_ORG,
  name: "Relief Telemed",
};

const globalEvent: Event = {
  name: "Global",
  id: GLOBAL_EVENT,
  organization: GLOBAL_ORG,
  postMessage: "",
  preMessage: "",
  type: "",
  showResults: false,
  showActionPlans: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  participantsSignup: false,
  guardianRequired: false,
  filesRequired: false,
  autoSchedule: false,
  linksAutoReport: false,
  requireConsentSignature: false,
  s3CsvFolder: "",
  s3ReqFolder: "",
  linksClinic: "",
  laboratory: "",
  eventCode: "",
};

export const removeUser = assign<ApplicationContext, ApplicationEvents>({
  token: "",
  user: undefined,
});

export const revokeToken = () => {
  logger.debug("Removing user token");
  setBaseHeader("Authorization", "");
};

export const saveGlobal = assign<ApplicationContext, ApplicationEvents>({
  showGlobal: true,
});

export const saveEvents = assign<ApplicationContext, ApplicationEvents>({
  events: (context, event) => {
    return isDoneEvent<UpdateEventsEvent>(event)
      ? event.data.concat(context.showGlobal ? [globalEvent] : [])
      : context.events;
  },
  orgEvents: (context, event) => {
    return isDoneEvent<UpdateEventsEvent>(event) ? event.data : context.events;
  },
});

export const saveLocations = assign<ApplicationContext, ApplicationEvents>({
  locations: (context, event) =>
    isDoneEvent<UpdateLocationsEvent>(event) ? event.data : context.locations,
});

export const saveNameFilter = assign<ApplicationContext, ApplicationEvents>({
  nameFilter: (context, event) =>
    !isDoneEvent<any>(event) && event.type === CHANGE_NAME_FILTER
      ? event.query
      : context.nameFilter,
});

export const saveOrganizations = assign<ApplicationContext, ApplicationEvents>({
  organizations: (context, event) =>
    isDoneEvent<any>(event) ? event.data : context.organizations,
});
// export const saveOrganizations = assign<ApplicationContext, ApplicationEvents>({
//   organizations: (context) =>
//     context.events.reduce(
//       (aggregate: MinimalOrganization[], event) => {
//         const orgs = [...aggregate];

//         if (
//           event.organization &&
//           !orgs.find((org) => org.id === event.organization)
//         ) {
//           orgs.push({
//             id:
//               typeof event.organization === "string"
//                 ? event.organization
//                 : event.organization.id,
//             name: event.orgName ?? "",
//             type: event.orgType,
//           });
//         }
//         return orgs;
//       },
//       context.showGlobal ? [globalOrg] : [],
//     ),
// });

export const saveParticipants = assign<ApplicationContext, ApplicationEvents>({
  participants: (context, event) =>
    isDoneEvent<UpdateParticipantsEvent>(event)
      ? event.data
      : context.participants,
});

export const saveUser = assign<ApplicationContext, ApplicationEvents>({
  showGlobal: false,
  token: (context, event) =>
    isDoneEvent<DoneLoginData>(event) ? event.data.token : context.token,
  user: (context, event) =>
    isDoneEvent<DoneLoginData>(event) ? event.data.user : context.user,
});

export const setCurrentEvent = assign<ApplicationContext, ApplicationEvents>({
  currentEvent: (context, event) =>
    !isDoneEvent<any>(event) && event.type === CHANGE_EVENT
      ? event.event
      : context.currentEvent,
  currentLocation: undefined,
});

export const setCurrentLocation = assign<ApplicationContext, ApplicationEvents>(
  {
    currentLocation: (context, event) =>
      !isDoneEvent<any>(event) && event.type === CHANGE_LOCATION
        ? event.location
        : context.currentLocation,
  },
);

export const setCurrentOrg = assign<ApplicationContext, ApplicationEvents>({
  currentOrg: (context, event) =>
    !isDoneEvent<any>(event) && event.type === CHANGE_ORG
      ? event.organization
      : context.currentOrg,
  currentEvent: (context, event) =>
    !isDoneEvent<any>(event) && event.type === CHANGE_ORG && event.event
      ? event.event
      : undefined,
  currentLocation: undefined,
});

export const setOrgEvents = assign<ApplicationContext, ApplicationEvents>({
  orgEvents: (context) =>
    context.events.filter(
      (event) =>
        context.currentOrg && event.organization === context.currentOrg.id,
    ),
});

export const setDateFilter = assign<ApplicationContext, ApplicationEvents>({
  dateFilter: (context, event) =>
    event.type === CHANGE_DATE_FILTER
      ? applyIfNotNull<Date | null, string | null>(
          (a: Date | null) => formatISO(a!),
          (event as ChangeDateFilterEvent).dateFilter,
        )
      : context.dateFilter,
});

export const setTimeframe = assign<ApplicationContext, ApplicationEvents>({
  timeframe: (context, event) =>
    !isDoneEvent<any>(event) && event.type === CHANGE_TIME
      ? event.timeframe
      : context.timeframe,
});

export const setToken = (context: ApplicationContext) => {
  logger.debug("Setting user token");
  setBaseHeader("Authorization", `Token ${context.token}`);
};

export const validateCurrentEvent = assign<
  ApplicationContext,
  ApplicationEvents
>({
  currentEvent: (context) => {
    if (context.currentEvent) {
      const foundEvent = context.orgEvents.find(
        (event) => context.currentEvent!.id === event.id,
      );

      if (foundEvent) {
        return foundEvent;
      }
    }

    return context.orgEvents.length > 0 ? context.orgEvents[0] : undefined;
  },
});

export const validateCurrentOrg = assign<ApplicationContext, ApplicationEvents>(
  {
    currentOrg: (context) => {
      if (
        context.currentOrg &&
        context.organizations.find((org) => context.currentOrg!.id === org.id)
      ) {
        return context.currentOrg;
      }

      return undefined;
    },
  },
);

export const updateUser = assign<ApplicationContext, ApplicationEvents>({
  user: (context, event) =>
    isDoneEvent<DoneLoginData>(event) ? event.data.user : context.user,
});

export const saveEventTypes = assign<ApplicationContext, ApplicationEvents>({
  eventTypes: (context, event) =>
    isDoneEvent<any>(event) ? event.data : context.eventTypes,
});
