import { Analytics } from "./analytics";
import { reportWebVitals } from "./report-web-vitals";

export * from "./context";
export * from "./mixpanel";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
export const registerWebVitals = () => {
  reportWebVitals((metric) => Analytics.sendBeacon("vitals", metric as {}));
};
