import React, { FunctionComponent, useState, useCallback } from "react";

import { ActionMenuContext } from "../helpers";
import { ActionMenuActions } from "../hooks";
/**
 * Note Modals are defined in the action-button.tsx component
 */
const ActionMenuProvider: FunctionComponent = ({ children }) => {
  const [activeAction, setActiveAction] = useState<string | undefined>();
  const [activeActionArgs, setActiveActionArgs] = useState({});

  const doCancelAction = useCallback(() => {
    setActiveAction(undefined);
    setActiveActionArgs({});
  }, [setActiveAction, setActiveActionArgs]);

  const doCheckIn = useCallback(
    (args) => {
      setActiveAction(ActionMenuActions.checkin);
      setActiveActionArgs(args);
    },
    [setActiveAction],
  );

  const doReviewParticipant = useCallback(
    (args) => {
      setActiveAction(ActionMenuActions.reviewParticipants);
      setActiveActionArgs(args);
    },
    [setActiveAction],
  );

  return (
    <ActionMenuContext.Provider
      value={{
        activeAction,
        activeActionArgs,
        setActiveAction,
        doCancelAction,
        doCheckIn,
        doReviewParticipant,
      }}
    >
      {children}
    </ActionMenuContext.Provider>
  );
};

export { ActionMenuProvider };
