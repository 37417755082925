import { Grid } from "@material-ui/core";
import { User } from "@relieftelemed/platform";
import React, { FunctionComponent, useEffect, useCallback } from "react";

import { useAppService } from "../state";

import { SecondFactorForm } from "./second-factor-form";

export const ProfilePage: FunctionComponent = () => {
  const [{ user }, , { updateUser }] = useAppService();

  const handleUpdateUser = useCallback(
    (updatedUser: User) => {
      updateUser(updatedUser);
    },
    [updateUser],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid>
          <SecondFactorForm user={user!} onUserUpdated={handleUpdateUser} />
        </Grid>
      </Grid>
    </Grid>
  );
};
