import { EventParticipant, PatientVaccination } from "@relieftelemed/platform";

export const getPatientVaccination = (
  participant: EventParticipant,
  series: Number,
): PatientVaccination | undefined => {
  return typeof participant.patient !== "string"
    ? participant.patient.vaccinations?.find(
        (vacc) => vacc.eventParticipant === participant.id,
      )
    : undefined;
};
