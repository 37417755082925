import React, { FormEventHandler, FunctionComponent, ReactNode } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogModalProps,
  DialogTitle,
} from "./dialog";

interface FormModalProps extends DialogModalProps {
  onSubmit?: FormEventHandler<HTMLFormElement & HTMLDivElement>;
}

export const FormModal: FunctionComponent<FormModalProps> = ({
  actions,
  children,
  icon,
  onClose,
  onSubmit,
  showDividers = false,
  title,
  titleProps,
  ...props
}) => {
  let dialogActions = null;
  if (actions) {
    dialogActions = <DialogActions>{actions}</DialogActions>;
  }

  const content = (
    <>
      <DialogTitle
        icon={icon}
        onClose={onClose}
        title={title}
        {...titleProps}
      />
      <DialogContent showDividers={showDividers}>{children}</DialogContent>
      {dialogActions}
    </>
  );

  return (
    <Dialog {...props}>
      {onSubmit ? <form onSubmit={onSubmit}>{content}</form> : content}
    </Dialog>
  );
};
