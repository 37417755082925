// Actions
export const SET_PARTICIPANT_ID = "SET_PARTICIPANT_ID";
export const ASSIGN_PARITICIPANT = "ASSIGN_PARITICIPANT";
export const UPDATE_VACCINE_INFO = "updateVaccineInfo";

// states
export const PATIENT_SELECTION = "patientSelection";
export const CHECK_IN_PATIENT = "checkInPatient";
export const FETCH_FOR_PARTICIPANT_DETAILS = "fetchForParticipantDetails";
export const INIT = "init";

export const DONE = "done";
export const PERSIST_CHANGES = "persistChanges";
export const PERSIST_CHANGES_ACTION = "persistVaccineInfoChanges";
export const SET_SELECTED_LOCATION = "selectLocation";
export const PARTIAL_UPDATE_PARTICIPANT = "partialUpdateParticipant";
export const UPDATE_TESTING_INFO = "updateTestingInfo";
