import { makeStyles, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { DashboardCard } from "../../components";
import { useAppService } from "../../state";

const useBigTextStyles = makeStyles({
  root: {
    fontSize: "3rem",
  },
});

export const PromoCode: FunctionComponent = () => {
  const classes = useBigTextStyles();
  const [{ currentEvent }] = useAppService();

  let promoCode = "No Promo Code Set";
  if (currentEvent?.promoCode) {
    promoCode = currentEvent.promoCode;
  }

  return (
    <DashboardCard title="Promo Code">
      <Typography align="center" className={classes.root}>
        {promoCode}
      </Typography>
    </DashboardCard>
  );
};
