import { useTheme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import {
  LineChart as ReLineChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ChartProps } from "./chart-types";

export const LineChart: FunctionComponent<ChartProps> = ({
  children,
  data,
  multiple,
  ...props
}) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer aspect={2.5} {...props}>
      <ReLineChart
        data={data}
        margin={{
          top: theme.spacing(0.5),
          bottom: theme.spacing(0.5),
          left: theme.spacing(1),
          right: theme.spacing(0.5),
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {children}
      </ReLineChart>
    </ResponsiveContainer>
  );
};
