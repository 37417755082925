import { useMemo } from "react";

import { TabConfiguration } from "../../components";
import { useAppService } from "../../state";

import { useCheckinContext } from "./context";
import { PhotoCardsTab } from "./photo-cards";
import { QuestionnaireTab } from "./questionnaire";
import { CovidTestingTab } from "./testing";
import { CheckinTabs } from "./types";
import { VaccinationsTab } from "./vaccinations";

export const useTabs = () => {
  const [{ currentEvent }] = useAppService();
  const { currentParticipant } = useCheckinContext();
  const isVaccinationEvent = useMemo(
    () => currentParticipant?.appointmentType === "covid_vaccination",
    [currentParticipant],
  );
  // console.log("current participant", currentParticipant);

  const tabsToShow = useMemo(() => {
    const availableTabs = [
      PhotoCardsTab,
      QuestionnaireTab,
      VaccinationsTab,
      CovidTestingTab,
    ];

    const initialTabs: TabConfiguration<CheckinTabs>[] = [];
    return availableTabs.reduce(
      (aggregate, tab) =>
        tab.shouldShow(currentEvent, isVaccinationEvent)
          ? [...aggregate, tab]
          : aggregate,
      initialTabs,
    );
  }, [currentEvent, isVaccinationEvent]);

  return tabsToShow;
};
