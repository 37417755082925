import React, { FunctionComponent } from "react";
import { Redirect, RouteProps, Switch, useLocation } from "react-router-dom";

import { FlagProvider, ActionMenuProvider } from "./components";
import { PATH_LOGIN } from "./constants";
import { useAnalytics } from "./core";
import { CreateEvent } from "./events";
import { ActionMenuActions, useActionMenuContext } from "./hooks";
import { AppLayout } from "./layouts";
import { AddContact } from "./organizations";
import {
  CheckIn,
  AddTestResult,
  AddActionPlan,
  UploadParticipants,
  ReviewParticipant,
  NewParticipantMenuItem,
} from "./participants";
import { useAppService } from "./state";
import { AddNurse } from "./users";

const ModalSwitch: FunctionComponent = () => {
  const {
    activeAction,
    doCancelAction,
    activeActionArgs,
  } = useActionMenuContext();
  return (
    <>
      <NewParticipantMenuItem
        open={activeAction === ActionMenuActions.addParticipant}
        onCancel={doCancelAction}
      />
      <CreateEvent
        open={activeAction === ActionMenuActions.event}
        onCancel={doCancelAction}
      />
      <AddContact
        open={activeAction === ActionMenuActions.contact}
        onCancel={doCancelAction}
      />
      <CheckIn
        open={activeAction === ActionMenuActions.checkin}
        onCancel={doCancelAction}
        {...activeActionArgs}
      />
      <AddTestResult
        open={activeAction === ActionMenuActions.addResults}
        onCancel={doCancelAction}
      />
      <AddActionPlan
        open={activeAction === ActionMenuActions.addActionPlan}
        onCancel={doCancelAction}
      />
      <AddNurse
        open={activeAction === ActionMenuActions.nurse}
        onCancel={doCancelAction}
      />
      <UploadParticipants
        open={activeAction === ActionMenuActions.uploadParticipants}
        onCancel={doCancelAction}
      />
      <ReviewParticipant
        open={activeAction === ActionMenuActions.reviewParticipants}
        onCancel={doCancelAction}
        {...activeActionArgs}
      />
    </>
  );
};

export const AuthenticatedSwitch: FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const [{ isLoggedIn, user }] = useAppService();
  const location = useLocation();
  const analytics = useAnalytics();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN,
          state: { from: location },
        }}
      />
    );
  }

  analytics.login(user!);

  return (
    <FlagProvider>
      <ActionMenuProvider>
        <AppLayout fullHeight>
          <Switch {...rest}>{children}</Switch>
          <ModalSwitch />
        </AppLayout>
      </ActionMenuProvider>
    </FlagProvider>
  );
};
