import { Interpreter, State, DoneInvokeEvent } from "xstate";

import { User } from "../../core";

export interface RegistrationData {
  acceptedTerms: boolean;
  confirmation: string;
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
  password: string;
}

export interface RegistrationContext extends RegistrationData {
  errors: string[];
  user?: User;
  token?: string;
}

export interface RegistrationSchema {
  states: {
    unregistered: {};
    failed: {};
    registering: {};
    registered: {};
  };
}

// Events

export interface CreateEvent extends RegistrationData {
  type: "REGISTER.CREATE";
}

export interface FailEvent {
  type: "REGISTER.FAIL";
  errors: string[];
}

export type ResetEvent = { type: "REGISTER.RESET" };

export interface SucceedEvent {
  token: string;
  user: User;
}

// Aggregates

export type RegistrationEvents =
  | CreateEvent
  | FailEvent
  | DoneInvokeEvent<SucceedEvent>
  | ResetEvent
  | DoneInvokeEvent<any>;

export const isDoneEvent = <TData = {}>(
  event: RegistrationEvents,
): event is DoneInvokeEvent<TData> =>
  (event as DoneInvokeEvent<TData>).data !== undefined;

export type RegistrationState = State<RegistrationContext, RegistrationEvents>;

export type RegistrationService = Interpreter<
  RegistrationContext,
  RegistrationSchema,
  RegistrationEvents
>;

// Hooks

type SendRegister = (details: RegistrationData) => void;

type SendReset = () => void;

interface RegisterHookActions {
  register: SendRegister;
  reset: SendReset;
}

interface RegisterHookSelectors {
  didFail: boolean;
  errors: string[];
}

export type RegisterServiceHook = [RegisterHookSelectors, RegisterHookActions];
