import { Grid, Button } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { ActionProps } from "./types";

export const SecondaryAction: FunctionComponent<ActionProps> = ({
  disabled,
  icon,
  label,
  onClick,
}) => {
  return (
    <Grid item xs={6} md={3}>
      <Button
        color="secondary"
        disabled={disabled}
        onClick={onClick}
        startIcon={icon}
        variant="outlined"
      >
        {label ?? "Cancel"}
      </Button>
    </Grid>
  );
};
