import { LinearProgress } from "@material-ui/core";
import {
  setBeforeRequestHooks,
  setAfterResponseHook,
} from "@relieftelemed/platform";
import React, { useState, useEffect, FunctionComponent } from "react";

import { ProcessingContext } from "./context";

export const ProcessingProvider: FunctionComponent = ({ children }) => {
  const [isProcessing, setProcessing] = useState(false);
  useEffect(() => {
    setBeforeRequestHooks([
      () => {
        setProcessing(true);
      },
    ]);
    setAfterResponseHook([
      () => {
        setProcessing(false);
      },
    ]);
  }, [setProcessing]);

  return (
    <ProcessingContext.Provider value={{ isProcessing, setProcessing }}>
      {isProcessing ? <LinearProgress /> : null}
      {children}
    </ProcessingContext.Provider>
  );
};
