import React, { FunctionComponent } from "react";

import { TabPanel, AnsweredQuestionsList } from "../../../components";
import { useCheckinContext } from "../context";
import { CheckinTabs } from "../types";

export const Panel: FunctionComponent = () => {
  const { participant } = useCheckinContext();

  return (
    <TabPanel name={CheckinTabs.QUESTIONNAIRE}>
      <AnsweredQuestionsList participant={participant} />
    </TabPanel>
  );
};
