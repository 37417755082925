import {
  makeStyles,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, {
  ChangeEventHandler,
  FunctionComponent,
  useState,
  useMemo,
} from "react";
import { useDebounce } from "react-use";

import { PATH_HOME, PATH_EVENT_HOME } from "../../constants";
import { useIsPath } from "../../hooks";
import { useAppService } from "../../state";

const useFilterStyles = makeStyles({
  searchBox: {
    maxWidth: "33vw",
  },
});

export const ParticipantFilter: FunctionComponent = () => {
  const classes = useFilterStyles();
  const isDashboard = useIsPath(PATH_HOME);
  const isEventsPage = useIsPath(PATH_EVENT_HOME);
  const [tempNameFilter, setTempNameFilter] = useState<string>("");
  const [{ nameFilter }, , { changeNameFilter }] = useAppService();
  const hasSearchChanges = useMemo(() => nameFilter !== tempNameFilter, [
    tempNameFilter,
    nameFilter,
  ]);

  const [, cancel] = useDebounce(
    () => {
      if (hasSearchChanges) {
        changeNameFilter(tempNameFilter);
      }
    },
    1000,
    [changeNameFilter, hasSearchChanges, tempNameFilter],
  );

  if (isDashboard || isEventsPage) {
    return null;
  }

  const handleChangeFilter: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTempNameFilter(event.target.value);
  };

  return (
    <TextField
      id="participant_filter"
      className={classes.searchBox}
      label="Participant"
      placeholder="Search by participant name"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="primary" />
          </InputAdornment>
        ),
        endAdornment: hasSearchChanges ? <CircularProgress /> : null,
      }}
      value={tempNameFilter}
      onChange={handleChangeFilter}
    />
  );
};
