import { Snackbar, SnackbarProps } from "@material-ui/core";
import React, { FunctionComponent } from "react";

export const NotificationTrigger: FunctionComponent<Partial<SnackbarProps>> = ({
  children,
  open,
  onClose,
}) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
      onClose={onClose}
    >
      {children}
    </Snackbar>
  );
};
