import { FunctionComponent } from "react";

import { useLargeSpecimenLabel } from "../hooks";

import { PrintLabelProps } from "./types";

export const PrintWirelessLabel: FunctionComponent<PrintLabelProps> = ({
  participant,
  render,
}) => {
  const [isPrinting, handleReprint] = useLargeSpecimenLabel(participant);

  return render(handleReprint, isPrinting);
};
