import { useActor } from "@xstate/react";

import { REGISTRATION_MACHINE } from "../../constants";
import { logService } from "../../helpers";

import { CREATE, FAILED, RESET } from "./constants";
import {
  RegistrationData,
  RegistrationService,
  RegisterServiceHook,
} from "./types";

// Exposed API Below
export { registerMachine } from "./machine";
export * from "./types";

export const useRegisterService = (
  registerService: RegistrationService,
): RegisterServiceHook => {
  logService(REGISTRATION_MACHINE, registerService);
  const [state, send] = useActor(registerService);
  const didFail = state.matches(FAILED) || false;
  const errors = state.context.errors || [];

  const register = (details: RegistrationData) => {
    registerService?.send({
      type: CREATE,
      ...details,
    });
  };

  const reset = () => {
    registerService?.send({
      type: RESET,
    });
  };

  return [
    { didFail, errors },
    { register, reset },
  ];
};
