import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { PrimaryAction } from "./primary-action";
import { useTabContext } from "./tab-context";

export const Actions: FunctionComponent = () => {
  const { currentTab, isLastTab } = useTabContext();
  const {
    primaryAction: PrimaryActionWrapper,
    secondaryAction: SecondaryActionWrapper,
  } = currentTab!;

  const PrimaryComponent = PrimaryActionWrapper ?? PrimaryAction;

  let secondary = null;
  if (SecondaryActionWrapper) {
    secondary = <SecondaryActionWrapper />;
  }
  return (
    <Grid container justify="flex-end">
      <PrimaryComponent label={isLastTab ? "Submit" : "Next"} />
      {secondary}
    </Grid>
  );
};
