import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Fab,
} from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import React, { FunctionComponent, CSSProperties, useMemo } from "react";
import { FileUploader } from "react-drag-drop-files";

export const OpenImageInNewTabButton: FunctionComponent<{ src: string }> = ({
  src,
}) =>
  src == null ? null : (
    <Fab style={{ position: "relative", bottom: 60, margin: 10 }} size="small">
      <a href={src} target="_blank" rel="noreferrer" style={{ all: "unset" }}>
        <OpenInNew />
      </a>
    </Fab>
  );

export const StyledImage: FunctionComponent<{
  src: string;
  imageStyle?: CSSProperties;
}> = ({ src, imageStyle = {} }) => {
  return (
    <Card variant="outlined">
      <CardActionArea>
        <CardMedia
          image={src}
          style={{
            backgroundSize: "contain",
            ...imageStyle,
          }}
        />
      </CardActionArea>
    </Card>
  );
};

export const ImageNotAvailable: FunctionComponent<{
  content: string;
}> = ({ content }) => (
  <Card variant="outlined">
    <CardActionArea>
      <CardContent
        style={{
          width: "100%",
          height: 200,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography variant="subtitle2">{content}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

export const InputImage: FunctionComponent<{
  value?: string | File;
  imageStyle?: CSSProperties;
  onChange?: (file: File) => void;
  placeholder?: string;
  fileName?: string;
  id?: string;
  disabled?: boolean;
  showOpenImageInNewTabButton?: boolean;
}> = ({
  value,
  imageStyle,
  placeholder = "",
  onChange,
  fileName = "image",
  id,
  showOpenImageInNewTabButton = true,
}) => {
  const handleDropChange = (file: File) => {
    onChange?.(new File([file], fileName));
  };

  const imageUrl = useMemo(() => {
    if (value) {
      return typeof value === "string" ? value : URL.createObjectURL(value);
    }
    return "";
  }, [value]);

  return (
    <div id={id}>
      <FileUploader
        handleChange={handleDropChange}
        name={fileName}
        types={["JPG", "PNG", "GIF", "JPEG"]}
      >
        {imageUrl ? (
          <StyledImage src={imageUrl} imageStyle={imageStyle} />
        ) : (
          <ImageNotAvailable content={placeholder} />
        )}
      </FileUploader>
      {showOpenImageInNewTabButton && (
        <OpenImageInNewTabButton src={imageUrl} />
      )}
    </div>
  );
};
