import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
} from "@material-ui/pickers";
import React, { FunctionComponent } from "react";

export const DatetimeInput: FunctionComponent<KeyboardDateTimePickerProps> = (
  props,
) => {
  return (
    <KeyboardDateTimePicker
      variant="inline"
      format="MM/dd/yyyy hh:mm a"
      {...props}
    />
  );
};
