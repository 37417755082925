import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { PATH_HOME } from "./constants";

interface FlaggedRouteProps {
  flag: string;
}

export const FlaggedRoute: FunctionComponent<
  FlaggedRouteProps & RouteProps
> = ({ children, flag, ...rest }) => {
  const flags = useFlags();

  const allowed = flags[flag] ?? false;

  if (!allowed) {
    return <Redirect to={PATH_HOME} />;
  }

  return <Route {...rest}>{children}</Route>;
};
