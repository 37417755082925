import { Fragment } from "react";

import { TabConfiguration } from "../../../components";
import { CheckinTabs } from "../types";

import { PrimaryActionWrapper, SecondaryActionWrapper } from "./actions";
import { Panel } from "./panel";
import { Title } from "./title";

export const QuestionnaireTab: TabConfiguration<CheckinTabs> = {
  primaryAction: PrimaryActionWrapper,
  secondaryAction: SecondaryActionWrapper,
  name: CheckinTabs.QUESTIONNAIRE,
  panel: Panel,
  shouldShow: (_event, isVaccinationEvent) => isVaccinationEvent === true,
  title: Title,
  context: Fragment,
};
