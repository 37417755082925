import { EventParticipant, Patient } from "@relieftelemed/platform";
import { useInterpret, useSelector } from "@xstate/react";
import { useCallback } from "react";
import { State } from "xstate";

import { logService } from "../../../helpers";

import {
  UPDATE_TESTING_INFO,
  PARTIAL_UPDATE_PARTICIPANT,
  SET_PARTICIPANT_ID,
  FETCH_FOR_PARTICIPANT_DETAILS,
  CHECK_IN_PATIENT,
  UPDATE_VACCINE_INFO,
  PERSIST_CHANGES_ACTION,
  SET_SELECTED_LOCATION,
} from "./constants";
import { checkInStateMachine } from "./machine";
import {
  HookProperties,
  CheckInHookProps,
  VaccineInfo,
  TestingInfo,
  CheckInContext,
} from "./types";

export * from "./types";

const devTools =
  process.env.NODE_ENV === "development" ? { devTools: true } : {};
// console.log(devTools);

export const useCheckInService = ({
  maybeParticipant,
}: CheckInHookProps): HookProperties => {
  const checkInService = useInterpret(checkInStateMachine, {
    ...devTools,
    context: {
      participantId: maybeParticipant?.id,
    },
  });

  const onSelectParticipant = (participant?: EventParticipant) => {
    checkInService.send({
      type: SET_PARTICIPANT_ID,
      participantId: participant?.id,
    });
  };

  const onUpdateVaccinationInfo = (vaccineInfo: VaccineInfo) => {
    checkInService.send({
      type: UPDATE_VACCINE_INFO,
      vaccineInfo,
    });
  };

  const onSelectLocation = (checkInLocation: string) => {
    checkInService.send({
      type: SET_SELECTED_LOCATION,
      checkInLocation,
    });
  };

  const onPartialEventParticipantUpdate = (
    partialParticipant: Partial<EventParticipant>,
  ) => {
    checkInService.send({
      type: PARTIAL_UPDATE_PARTICIPANT,
      participant: partialParticipant,
    });
  };

  const onPersistChanges = () => {
    checkInService.send({
      type: PERSIST_CHANGES_ACTION,
    });
  };

  const onUpdateTestingInfo = (testingInfo: TestingInfo) => {
    checkInService.send({
      type: UPDATE_TESTING_INFO,
      testingInfo,
    });
  };

  const currentParticipant = useSelector(
    checkInService,
    (state: State<CheckInContext>) => state.context.participant,
  );

  const checkInLocation = useSelector(
    checkInService,
    (state: State<CheckInContext>) => state.context.checkInLocation,
  );

  const vaccineInfo = useSelector(
    checkInService,
    (state: State<CheckInContext>) => state.context.vaccineInfo,
  );

  const testingInfo = useSelector(
    checkInService,
    (state: State<CheckInContext>) => state.context.testingInfo,
  );

  const patientVaccinations = (currentParticipant?.patient as Patient)
    ?.vaccinations;

  const patientTestResults =
    (currentParticipant?.patient as Patient)?.testResults || [];

  const vaccinationData = currentParticipant?.vaccinationData;

  // checkInService.onTransition((state) => {
  //   console.log("This is the machine state", state);
  // });

  return [
    {
      onSelectParticipant,
      onUpdateVaccinationInfo,
      onPersistChanges,
      onSelectLocation,
      onPartialEventParticipantUpdate,
      onUpdateTestingInfo,
    },
    {
      currentParticipant,
      patientVaccinations,
      vaccinationData,
      vaccineInfo,
      checkInLocation,
      testingInfo,
      patientTestResults,
    },
    checkInService,
  ];
};
