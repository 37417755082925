import { Machine } from "xstate";

import { REGISTRATION_MACHINE } from "../../constants";

import { clearContext, saveError, saveRegistration, saveUser } from "./actions";
import {
  RESET,
  UNREGISTERED,
  REGISTERING,
  REGISTERED,
  FAIL,
  FAILED,
  SUCCEED,
  CREATE,
} from "./constants";
import { isRegistrationValid } from "./guards";
import { createRegisterPromise } from "./promises";
import {
  RegistrationContext,
  RegistrationEvents,
  RegistrationSchema,
} from "./types";

export const registerMachine = Machine<
  RegistrationContext,
  RegistrationSchema,
  RegistrationEvents
>(
  {
    id: REGISTRATION_MACHINE,
    initial: UNREGISTERED,
    context: {
      errors: [],
      acceptedTerms: false,
      email: "",
      firstName: "",
      lastName: "",
      mobile: "",
      password: "",
      confirmation: "",
    },
    states: {
      [UNREGISTERED]: {
        on: {
          [CREATE]: [
            {
              target: REGISTERING,
              cond: { type: "isRegistrationValid" },
              actions: "saveRegistration",
            },
            {
              target: FAILED,
            },
          ],
        },
      },
      [REGISTERING]: {
        invoke: {
          src: createRegisterPromise,
          id: "register",
          onDone: {
            target: REGISTERED,
            actions: ["clearContext", "saveUser"],
          },
          onError: {
            target: FAILED,
            actions: ["clearContext", "saveError"],
          },
        },
      },
      [FAILED]: {
        on: {
          [CREATE]: [
            {
              target: REGISTERING,
              cond: { type: "isRegistrationValid" },
              actions: ["clearContext", "saveRegistration"],
            },
            {
              target: FAILED,
            },
          ],
          [RESET]: {
            target: UNREGISTERED,
            actions: "clearContext",
          },
        },
      },
      [REGISTERED]: {
        type: "final",
        data: ({ token, user }) => ({
          token,
          user,
        }),
      },
    },
  },
  {
    actions: {
      clearContext,
      saveError,
      saveRegistration,
      saveUser,
    },
    guards: {
      isRegistrationValid,
    },
  },
);
