import { Box } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { TAB_SUFFIX, TAB_PANEL_SUFFIX } from "./constants";
import { TabPanelProps } from "./types";

export const TabPanel: FunctionComponent<TabPanelProps> = ({
  children,
  name,
  ...other
}) => (
  <Box
    role="tabpanel"
    id={`${name}-${TAB_PANEL_SUFFIX}`}
    aria-labelledby={`${name}-${TAB_SUFFIX}`}
    {...other}
  >
    {children}
  </Box>
);
