import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
} from "@material-ui/core";
import { CheckCircleOutline, Cancel, Help } from "@material-ui/icons";
import React, { FunctionComponent, ReactElement } from "react";

import {
  useGetScreeningQuestions,
  ScreeningQuestion,
  EventParticipant,
  PossibleSceeninQuestionsAnswers,
} from "../core";
import { snakeToCamelCase } from "../helpers";

interface AnsweredQuestionsListProps {
  participant?: EventParticipant;
}

const answerToIcon: Record<PossibleSceeninQuestionsAnswers, ReactElement> = {
  yes: <CheckCircleOutline />,
  no: <Cancel />,
};

export const AnsweredQuestionsList: FunctionComponent<AnsweredQuestionsListProps> = ({
  participant,
}) => {
  const {
    data: questionsAsked,
    isLoading: isLoadingQuestions,
  } = useGetScreeningQuestions();

  const maybeQuestionAnswers = participant?.screenData || {};

  return isLoadingQuestions ? (
    <LinearProgress />
  ) : (
    <List>
      {questionsAsked?.map(({ key, question }: ScreeningQuestion) => (
        <ListItem key={key} disableGutters>
          <ListItemText>{question}</ListItemText>
          <ListItemIcon>
            {answerToIcon[maybeQuestionAnswers![snakeToCamelCase(key)]] ?? (
              <Help />
            )}
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};
