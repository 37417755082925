import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

type SupportedTypes = { [key: string]: string | undefined | string[] | number };
export type SortOptions = "asc" | "desc";
export type SortOptionsMapType = Record<SortOptions, string>;

export interface RoutePaginationResult {
  page?: number;
  sort?: string;
  onChangePage: (event: object, page: number) => void;
  onSort: (sortOp: SortOptions, sort: string) => void;
  key?: string;
}

const sortOptionsMap: SortOptionsMapType = {
  asc: "",
  desc: "-",
};
const queryObjectToQueryString = (queryObject: SupportedTypes): string =>
  Object.entries(queryObject)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

export const useRouteAsPagination = (): RoutePaginationResult => {
  const { search, pathname, key } = useLocation();
  const queryParams = useMemo(() => {
    const query = new URLSearchParams(search);
    return Array.from(query.keys()).reduce(
      (acc: SupportedTypes, objIdx: string) => {
        const everyParam = query.getAll(objIdx);
        if (everyParam.length > 1) {
          acc[objIdx] = everyParam;
        } else {
          const valOrNotVal: string | undefined =
            query.get(objIdx) || undefined;
          const maybeNumber = parseInt(valOrNotVal!, 10);
          acc[objIdx] = (Number.isNaN(maybeNumber)
            ? valOrNotVal
            : maybeNumber) as string | number | undefined;
        }
        return acc;
      },
      {},
    );
  }, [search]);
  const history = useHistory();
  const onChangePage = useCallback(
    (_event: object, page: number) => {
      const newQueryParams = queryObjectToQueryString({ ...queryParams, page });
      history.push(`${pathname}?${newQueryParams}`);
    },
    [pathname, history, queryParams],
  );
  const onSort = useCallback(
    (sortOp: SortOptions, sortBy: string) => {
      const newQueryParams = queryObjectToQueryString({
        ...queryParams,
        sort: `${sortOptionsMap[sortOp]}${sortBy}`,
      });
      history.push(`${pathname}?${newQueryParams}`);
    },
    [pathname, history, queryParams],
  );

  return { ...queryParams, onChangePage, onSort, key };
};
