import {
  Avatar,
  Box,
  DialogTitle as MuiDialogTitle,
  IconButton,
  fade,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import { accent } from "../../styles";

import { DialogTitleProps } from "./types";

const useDialogTitleStyles = makeStyles((theme) => ({
  title: {
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    width: "100%",
  },
  avatar: {
    background: fade(accent, 0.1),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
    width: theme.spacing(6),
  },
}));

export const DialogTitle: FunctionComponent<DialogTitleProps> = ({
  icon: Icon,
  onClose,
  title,
  ...props
}) => {
  const classes = useDialogTitleStyles();

  const icon = Icon ? (
    <Avatar classes={{ colorDefault: classes.avatar }}>
      <Icon htmlColor={accent} />
    </Avatar>
  ) : null;

  let closeIcon = null;
  if (onClose) {
    closeIcon = (
      <IconButton aria-label="close" onClick={onClose}>
        <Close />
      </IconButton>
    );
  }

  return (
    <MuiDialogTitle classes={{ root: classes.title }} disableTypography>
      {icon}
      <Typography variant="h1" {...props}>
        {title}
      </Typography>
      <Box flexGrow={1} />
      {closeIcon}
    </MuiDialogTitle>
  );
};
