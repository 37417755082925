import {
  EventParticipant,
  fetchPatientInsurance,
  getPatient,
  getUserPromise,
  getVideoVisitPromise,
  HealthInsurance,
  marshallPatient,
  marshallUser,
  marshallVideoVisit,
  Patient,
  User,
  VideoVisit,
} from "@relieftelemed/platform";
import { useState } from "react";

import { createLargeSpecimentLabel } from "../helpers";

import { useWirelessLabelPrinter } from "./use-wireless-label-printer";

type LargeSpecimenLabelHook = [boolean, () => void];

export const useLargeSpecimenLabel = (
  participant?: EventParticipant,
): LargeSpecimenLabelHook => {
  const [isPrinting, setPrintStatus] = useState(false);
  const label = useWirelessLabelPrinter();

  const callback = () => {
    if (!participant) {
      return;
    }

    setPrintStatus(true);
    label.reset();

    const patientId =
      typeof participant.patient === "string"
        ? participant.patient
        : participant.patient?.id;
    const visitId =
      typeof participant.visit === "string"
        ? participant.visit
        : participant.visit?.id;
    const collectorId =
      typeof participant.checkedInBy === "string"
        ? participant.checkedInBy
        : participant.checkedInBy?.id;

    const visitPromise = visitId
      ? getVideoVisitPromise(visitId).then(marshallVideoVisit)
      : Promise.resolve(undefined);
    const collectorPromise = collectorId
      ? getUserPromise(collectorId).then(marshallUser)
      : Promise.resolve(undefined);

    Promise.all<
      Patient,
      HealthInsurance,
      VideoVisit | undefined,
      User | undefined
    >([
      getPatient(patientId).then(marshallPatient),
      fetchPatientInsurance(patientId),
      visitPromise,
      collectorPromise,
    ])
      .then(([patient, insurance, visit, checkedInBy]) => {
        const detailedParticipant: EventParticipant = {
          ...participant,
          patient: {
            ...patient,
            insurance,
          },
          visit: visit ?? participant.visit,
          checkedInBy,
        };
        createLargeSpecimentLabel(label, detailedParticipant);
        label.print();
      })
      .then(() => {
        setPrintStatus(false);
      });
  };

  return [isPrinting, callback];
};
