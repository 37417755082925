import { makeStyles, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { DashboardCard } from "../../components";
import { prop } from "../../helpers";
import {
  useParticipantsAtLocation,
  useParticipantsCheckedInAt,
  useParticipantsForDateFilter,
} from "../../hooks";
import { useAppService } from "../../state";

export interface ParticipantCountProps {
  label?: string;
}

const useBigCountStyles = makeStyles({
  root: {
    fontSize: "3rem",
  },
});

const checkInMapper = prop("checkedInAt");

export const ParticipantCount: FunctionComponent<ParticipantCountProps> = ({
  label = "Participant Count",
}) => {
  const classes = useBigCountStyles();
  const [{ participants }] = useAppService();

  const participantsAtLocation = useParticipantsAtLocation(participants);
  const particpantsInTimeframe = useParticipantsCheckedInAt(
    participantsAtLocation,
  );
  const participantsAfterDateFilter = useParticipantsForDateFilter(
    particpantsInTimeframe,
    checkInMapper,
  );

  return (
    <DashboardCard title={label}>
      <Typography align="center" className={classes.root}>
        {participantsAfterDateFilter.length}
      </Typography>
    </DashboardCard>
  );
};
