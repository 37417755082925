export enum DeviceFontSize {
  "6PT" = "0",
  "8PT" = "1",
  "10PT" = "2",
  "12PT" = "3",
  "14PT" = "7",
  "15PT" = "4",
  "18PT" = "8",
  "20PT" = "5",
  "24PT" = "9",
  "30PT" = "6",
}

export enum DeviceFontType {
  ASCII = "U",
  KS56 = "K",
  BIG5 = "B",
  GB23 = "G",
  JIS = "J",
  OCRA = "a",
  OCRB = "b",
}

export enum DeviceFontAlignment {
  LEFT = 0,
  RIGHT = 1,
  CENTER = 2,
}

export enum DeviceFontRotation {
  NONE = 0,
  RIGHT = 1,
  UPSIDE_DOWN = 2,
  LEFT = 3,
}

export enum DeviceBarcodeType {
  CODE39 = 0,
  CODE128 = 1,
  ITF = 2,
  CODABAR = 3,
  CODE93 = 4,
  UPCA = 5,
  UPCE = 6,
  EAN13 = 7,
  EAN8 = 8,
  UCC = 9,
  CODE11 = 10,
  PLANET = 11,
  INDUSTRIAL = 12,
  TWO_FIVE = 13,
  LOGMARS = 14,
  EAN_EXTENDED = 15,
  POSTNET = 16,
}

export enum DeviceBarcodeTextPosition {
  NONE = 0,
  BELOW = 1,
  ABOVE = 2,
}

export enum DeviceBlockType {
  SOLID = "O",
  INVERT = "E",
  REPLACE = "D",
  DIAGONAL = "S",
  OUTLINE = "B",
}

export interface LabelBarcodeElementOptions {
  type?: DeviceBarcodeType;
  width?: number;
  wideWidth?: number;
  height?: number;
  rotation?: DeviceFontRotation;
  textPosition?: DeviceBarcodeTextPosition;
  textSize?: 1 | 2 | 3 | 4;
}

interface LabelMarginConfig {
  horizontal: number;
  vertical: number;
}

export enum LabelMediaType {
  GAP = "G",
  CONTINUOUS = "C",
  BLACK_MARK = "B",
}

export enum LabelPrintingType {
  DIRECT = "D",
  TRANSFER = "T",
}

interface LabelLengthConfig {
  base: number;
  gap: number;
  media: string;
  offset: number;
}

export enum DeviceOrientation {
  TOP = "T",
  BOTTOM = "B",
}

export interface LabelConfig {
  cutInterval?: number;
  density?:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20;
  id?: number;
  length?: LabelLengthConfig;
  margin?: LabelMarginConfig;
  orientation?: DeviceOrientation;
  offset?: number; // Range is -100 <> 100
  speed?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  tearoffPosition?: number; // Range is -100 <> 100
  type?: string;
  width?: number;
}

export type LabelElementPosition = [number, number]; // [x, y]

export interface LabelTextElementOptions {
  align?: DeviceFontAlignment;
  bold?: boolean;
  font?: DeviceFontType;
  invert?: boolean;
  italics?: boolean;
  position: LabelElementPosition;
  rotation?: DeviceFontRotation;
  rtl?: boolean;
  width?: number;
  height?: number;
}

export interface LabelBlockElementOptions {
  type?: DeviceBlockType;
  thickness?: number;
}

export interface PrintFunction {
  [key: string]: any;
}

export type PrintFunctionList = Record<string, PrintFunction>;

export interface PrintConfig {
  id: number;
  functions: PrintFunctionList;
}

export interface PrintResponse {
  RequestID: string;
  ResponseID: string;
  Result: string;
}
