import {
  getAllEventParticipantsPromise,
  getEventLocations,
  getEventParticipants,
  logger,
  logoutPromise,
  marshallEvent,
  marshallEventLocation,
  marshallEventParticipant,
  getOrganizations,
  Organization,
  marshallOrganization,
  getOrganizationEvents,
  ApiEvent,
  ApiEventType,
  Event,
  getEventTypesPromise,
} from "../core";

import { FINISH_LOGOUT } from "./constants";
import {
  ApplicationContext,
  FinishLogoutEvent,
  GLOBAL_EVENT,
  UpdateEventsEvent,
  UpdateLocationsEvent,
  UpdateParticipantsEvent,
} from "./types";

export const createLogoutObservable = async (): Promise<FinishLogoutEvent> => {
  try {
    await logoutPromise();
  } catch (error) {
    const errorResponse = error.response.json();
    logger.error("There was a problem logging out", errorResponse);
  }
  return {
    type: FINISH_LOGOUT,
  };
};

export const fetchOrganizations = async (): Promise<Organization[]> => {
  try {
    const apiOrganizations = await getOrganizations();
    return apiOrganizations.map(marshallOrganization);
  } catch (error) {
    const messages = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};

export const fetchForOrganizationEvents = async (
  context: ApplicationContext,
): Promise<Event[]> => {
  const orgId = context.currentOrg?.id;
  try {
    const apiEvents: ApiEvent[] = await getOrganizationEvents(orgId!);
    return apiEvents.map(marshallEvent);
  } catch (error) {
    const messages = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};

export const fetchForEventTypes = async (
  context: ApplicationContext,
): Promise<ApiEventType[]> => {
  const orgId = context.currentOrg?.id;
  try {
    const eventTypes: ApiEventType[] = await getEventTypesPromise();
    return eventTypes;
  } catch (error) {
    const messages = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};

// export const fetchEvents = async (): Promise<UpdateEventsEvent> => {
//   try {
//     const events = await getEvents();
//     return events.map(marshallEvent);
//   } catch (error) {
//     const messages = await error.response.json();
//     return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
//   }
// };

export const fetchParticipants = async (
  context: ApplicationContext,
): Promise<UpdateParticipantsEvent> => {
  if (!context.currentEvent) {
    return [];
  }
  try {
    const participantArray =
      context.currentEvent.id === GLOBAL_EVENT
        ? await getAllEventParticipantsPromise()
        : await getEventParticipants(context.currentEvent.id);

    return participantArray.map(marshallEventParticipant);
  } catch (error) {
    const errorResponse = error.response.json();
    logger.error(
      "There was a problem fetching event participants",
      errorResponse,
    );
  }

  return [];
};

export const fetchLocations = async (
  context: ApplicationContext,
): Promise<UpdateLocationsEvent> => {
  if (!context.currentEvent || context.currentEvent.id === GLOBAL_EVENT) {
    return [];
  }

  try {
    const eventLocations = await getEventLocations(context.currentEvent.id)();

    return eventLocations.map(marshallEventLocation);
  } catch (error) {
    const errorResponse = await error.response.json();

    logger.error("There was a problem fetching event locations", errorResponse);
  }

  return [];
};
