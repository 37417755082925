import { Promotion } from "../core";

import { renderCurrency } from "./number";

export const formatPromotionDetails = (promotion: Promotion): string => {
  let details = "";

  if (promotion.percentage) {
    details = `${promotion.percentage}% off`;

    if (promotion.percentage === 100) {
      details = "free";
    }
  }

  if (promotion.amount) {
    details = `flat cost of ${renderCurrency(promotion.amount)}`;
  }

  if (promotion.reliefFee) {
    details += ` with fee of ${renderCurrency(promotion.reliefFee)}`;
  }

  return details;
};
