export const FLAG_ADMIN = "clinicAdmin";
export const FLAG_COLLECT_SPECIMEN = "collectSpecimen";
export const FLAG_NOTIFICATIONS = "notifications";
export const FLAG_NURSE_VISIT = "nurseVisit";
export const FLAG_PAST_VISITS = "pastVisits";
export const FLAG_VIDEO_VISIT = "videoVisit";
export const FLAG_VIEW_PATIENTS = "viewPatients";
export const FLAG_VIEW_PLANS = "viewActionPlans";

export const PATH_ROOT = "";
export const PATH_HOME = `${PATH_ROOT}/`;

// Authentication
export const PATH_FORGOT_EMAIL = `${PATH_ROOT}/forgot-email`;
export const PATH_FORGOT_PASSWORD = `${PATH_ROOT}/forgot-password`;
export const PATH_RESET_PASSWORD = `${PATH_ROOT}/reset-password/:email/:token`;
export const PATH_LOGIN = `${PATH_ROOT}/login`;
export const PATH_LOGOUT = `${PATH_ROOT}/logout`;

// Users
export const PATH_REGISTER = `${PATH_ROOT}/register`;

// App
export const PATH_EVENT_HOME = `${PATH_ROOT}/events`;
export const PATH_ACTION_PLANS = `${PATH_ROOT}/action-plans`;
export const PATH_POSITIVES = `${PATH_ROOT}/positives`;
export const PATH_HISTORY = `${PATH_ROOT}/history/:organization?/:patient`;
export const PATH_RESULTS = `${PATH_ROOT}/results`;
export const PATH_ROSTER = `${PATH_ROOT}/roster`;
export const PATH_STAGES = `${PATH_ROOT}/stages`;
export const PATH_REVIEW = `${PATH_ROOT}/review`;

export const PATH_COLLECTION = `${PATH_ROOT}/collection`;

export const PATH_METABASE = `${PATH_ROOT}/metabase`;
export const PATH_PROFILE = `${PATH_ROOT}/profile`;
// Machine Names
export const AUTHENTICATION_MACHINE = "authentication";
export const REGISTRATION_MACHINE = "registration";
