import React, { FunctionComponent } from "react";

import { TabTitle } from "../../../components";
import { useCheckinContext } from "../context";
import { CheckinTabs, CheckinTitleProps } from "../types";

export const Title: FunctionComponent<CheckinTitleProps> = ({
  prefix,
  ...props
}) => {
  return (
    <TabTitle
      disabled={false}
      label={`${prefix} Vaccinations`}
      name={CheckinTabs.VACCINATIONS}
      id="tab-title-vaccination"
      {...props}
    />
  );
};
