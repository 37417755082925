import { User } from "@relieftelemed/platform";
import { useActor, useSelector } from "@xstate/react";

import { Event, EventLocation, MinimalOrganization } from "../core";
import { Timeframe } from "../helpers";

import {
  CHANGE_EVENT,
  CHANGE_LOCATION,
  CHANGE_NAME_FILTER,
  CHANGE_ORG,
  CHANGE_TIME,
  CHANGE_DATE_FILTER,
  END_SESSION,
  FETCH_EVENTS,
  LOGGED_IN,
  TOGGLE_GLOBAL,
  READY,
  UPDATE_USER,
} from "./constants";
import { appService } from "./machine";
import {
  selectAllEvents,
  selectCurrentEvent,
  selectCurrentLocation,
  selectCurrentOrganization,
  selectDateFilter,
  selectEvents,
  selectIsGlobalEvent,
  selectLocations,
  selectNameFilter,
  selectOrganizations,
  selectUser,
  selectEventTypes,
} from "./selectors";
import { ApplicationHooks, ApplicationServices, GLOBAL_EVENT } from "./types";

// Exposed API Below
export * from "./types";

export const useAppService = (): ApplicationHooks => {
  const [state, send] = useActor(appService);

  const isLoggedIn = state.matches(LOGGED_IN);
  const isReady = state.matches({ [LOGGED_IN]: READY });

  const user = useSelector(appService, selectUser);
  const currentEvent = useSelector(appService, selectCurrentEvent);
  const currentLocation = useSelector(appService, selectCurrentLocation);
  const currentOrganization = useSelector(
    appService,
    selectCurrentOrganization,
  );
  const dateFilter = useSelector(appService, selectDateFilter);
  const events = useSelector(appService, selectEvents);
  const locations = useSelector(appService, selectLocations);
  const allEvents = useSelector(appService, selectAllEvents);
  const nameFilter = useSelector(appService, selectNameFilter);
  const organizations = useSelector(appService, selectOrganizations);
  const isGlobalEvent = useSelector(appService, selectIsGlobalEvent);
  const eventTypes = useSelector(appService, selectEventTypes);

  const updateUser = (userData: User) => {
    send({
      type: UPDATE_USER,
      data: {
        user: userData,
      },
    });
  };

  const changeEvent = (event?: Event) => {
    send({
      type: CHANGE_EVENT,
      event,
    });
  };

  const changeLocation = (location?: EventLocation) => {
    send({
      type: CHANGE_LOCATION,
      location,
    });
  };

  const changeNameFilter = (query: string) => {
    send({
      type: CHANGE_NAME_FILTER,
      query,
    });
  };

  const changeOrganization = (
    organization?: MinimalOrganization,
    event?: Event,
  ) => {
    send({
      type: CHANGE_ORG,
      organization,
      event,
    });
  };

  const changeTimeframe = (timeframe: Timeframe) => {
    send({
      type: CHANGE_TIME,
      timeframe,
    });
  };

  const changeDateFilter = (dateFilterp: Date | null) => {
    send({
      type: CHANGE_DATE_FILTER,
      dateFilter: dateFilterp,
    });
  };

  const fetchEvents = () => {
    send(FETCH_EVENTS);
  };

  const logout = () => {
    send(END_SESSION);
  };

  const toggleGlobal = () => {
    send(TOGGLE_GLOBAL);
  };

  return [
    {
      currentEvent,
      currentLocation,
      currentOrganization,
      dateFilter,
      events,
      eventTypes,
      locations,
      allEvents,
      nameFilter,
      organizations,
      participants: state.context.participants,
      isGlobalEvent,
      isLoggedIn,
      isReady,
      showGlobal: state.context.showGlobal,
      timeframe: state.context.timeframe ?? Timeframe.lifetime,
      user,
    },
    (state.children as unknown) as ApplicationServices,
    {
      changeEvent,
      changeDateFilter,
      changeLocation,
      changeNameFilter,
      changeOrganization,
      changeTimeframe,
      fetchEvents,
      logout,
      toggleGlobal,
      updateUser,
    },
  ];
};
// delete this comment its jsut here to make a change so I can commit
