import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { emptyValue } from "../config";
import { Payment, renderPhoneNumber } from "../core";
import { renderAge, renderDate } from "../helpers";
import { inputPlaceholder, reliefTeal, accent } from "../styles";

import { DefinedProperty } from "./defined-property";

interface VisitDetailProps {
  address?: string;
  dob?: Date;
  gender?: string;
  name: string;
  payment?: Payment;
  phone?: string;
  sticky?: boolean;
}

const useVisitDetailCardStyles = makeStyles((theme) => ({
  sticky: {
    top: 0,
    position: "sticky",
    zIndex: 1,
  },
  avatar: {
    background: reliefTeal,
    color: theme.palette.common.white,
    height: 64,
    margin: "4px auto",
    width: 64,
  },
  stats: {
    color: inputPlaceholder,
    marginTop: 4,
  },
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  secondLine: {
    marginTop: 4,
  },
  insurance: {
    alignSelf: "center",
  },
  insuranceTitle: {
    color: accent,
    textTransform: "uppercase",
  },
}));

export const VisitDetailCard: FunctionComponent<VisitDetailProps> = ({
  address,
  children,
  dob,
  gender,
  name,
  payment,
  phone,
  sticky = true,
}) => {
  const classes = useVisitDetailCardStyles();

  const initials = name
    .split(" ")
    .map((chunk) => chunk.slice(0, 1))
    .join("");

  const stats = [
    dob ? renderAge(dob) : undefined,
    gender
      ? `${gender.slice(0, 1).toUpperCase()}${gender.slice(1)}`
      : undefined,
  ]
    .filter(Boolean)
    .join(", ");

  const cardClass = sticky ? ` ${classes.sticky}` : "";
  return (
    <Card className={`visit-details${cardClass}`} elevation={6}>
      <CardContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Avatar alt={name} classes={{ root: classes.avatar }}>
              {initials}
            </Avatar>
            <Typography align="center" variant="h3">
              {name}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              className={classes.stats}
            >
              {stats}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={7}>
            <DefinedProperty title="Address">
              {address ?? emptyValue}
            </DefinedProperty>
            <Grid container spacing={1} className={classes.secondLine}>
              <Grid item xs={12} md={6}>
                <DefinedProperty title="DOB">
                  {dob ? renderDate(dob) : emptyValue}
                </DefinedProperty>
              </Grid>
              <Grid item xs={12} md={6}>
                <DefinedProperty title="Patient ID">
                  {emptyValue}
                </DefinedProperty>
              </Grid>
              <Grid item xs={12} md={6}>
                <DefinedProperty title="Phone">
                  {phone ? renderPhoneNumber(phone) : emptyValue}
                </DefinedProperty>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent className={classes.content}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid className={classes.insurance} item xs={3}>
            <Typography variant="body2" className={classes.insuranceTitle}>
              Primary
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <DefinedProperty title="Type">
              {payment?.type ?? emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={3}>
            <DefinedProperty title="Plan">
              {payment?.provider ?? emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={3}>
            <DefinedProperty title="Member ID">
              {payment?.memberId ?? emptyValue}
            </DefinedProperty>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      {children}
    </Card>
  );
};
