import {
  makeStyles,
  MenuItem,
  TableCell,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, ReactNode, ChangeEventHandler } from "react";

import { EventParticipant, matchChange, TestResultChange } from "../../core";
import {
  getParticipantEventId,
  getResult,
  parseBooleanResult,
} from "../../helpers";
import { useIsLsu } from "../../hooks";
import { allCovid, lsuCovid } from "../../labs";

import { useEditContext } from "./edit-context";

interface TableResultsProps {
  participant: EventParticipant;
}

const positive = (
  <Tooltip title="Positive">
    <AddCircleOutline color="error" />
  </Tooltip>
);
const negative = (
  <Tooltip title="Negative">
    <RemoveCircleOutline color="primary" />
  </Tooltip>
);

const useTableResultsStyles = makeStyles((theme) => ({
  highlight: {
    background: theme.palette.warning.light,
  },
}));

export const TableResultsSegment: FunctionComponent<TableResultsProps> = ({
  participant,
}) => {
  const classes = useTableResultsStyles();
  const flags = useFlags();
  const changeHandler = useEditContext();
  const isLsu = useIsLsu();
  const tests = isLsu ? lsuCovid : allCovid;

  if (!flags.viewResults && !participant.orgConsent) {
    return (
      <TableCell colSpan={allCovid.length} align="center">
        The participant has not given consent to view their results.
      </TableCell>
    );
  }

  return (
    <>
      {tests.map((test) => {
        let value: ReactNode = "No Results";
        let initialValue = "";
        const result = getResult(test.name, participant);

        if (result) {
          const realValue = parseBooleanResult(result);
          initialValue = realValue ? "Positive" : "Negative";
          value = realValue ? positive : negative;
        }

        let select = null;
        if (changeHandler) {
          let selectedValue = initialValue;

          const patientId =
            typeof participant.patient === "string"
              ? participant.patient
              : participant.patient.id;

          const search: TestResultChange = result
            ? {
                test: result.id,
                // This is for mapping legacy results which did not include a visit ID
                visit:
                  typeof participant.visit === "string"
                    ? participant.visit
                    : participant.visit?.id,
              }
            : {
                name: test.name,
                patient: patientId,
                event: getParticipantEventId(participant),
                visit:
                  typeof participant.visit === "string"
                    ? participant.visit
                    : participant.visit?.id,
              };
          const existingChange = changeHandler.changes.find(
            matchChange(search),
          );

          let InputProps;
          if (existingChange) {
            selectedValue = existingChange.result!;
            InputProps = {
              className: classes.highlight,
            };
          }

          const handleChange: ChangeEventHandler<HTMLInputElement> = (
            event,
          ) => {
            const newValue = event.target.value;

            if (newValue === initialValue) {
              changeHandler.revert(search);
              return;
            }

            changeHandler.add({
              ...search,
              result: event.target.value,
              visit:
                typeof participant.visit === "string"
                  ? participant.visit
                  : participant.visit?.id,
            });
          };

          select = (
            <TextField
              InputProps={InputProps}
              label="Value"
              onChange={handleChange}
              select
              value={selectedValue}
            >
              <MenuItem value="">--</MenuItem>
              <MenuItem value="Positive">Positive</MenuItem>
              <MenuItem value="Negative">Negative</MenuItem>
            </TextField>
          );
        }

        return (
          <TableCell key={test.id} align="center">
            {changeHandler ? select : value}
          </TableCell>
        );
      })}
    </>
  );
};
