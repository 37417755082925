import React, { FunctionComponent } from "react";

import { TabTitle } from "../../../components";
import { useCheckinContext } from "../context";
import { CheckinTabs, CheckinTitleProps } from "../types";

export const Title: FunctionComponent<CheckinTitleProps> = ({
  prefix,
  ...props
}) => {
  const { patientId } = useCheckinContext();

  return (
    <TabTitle
      disabled={!patientId}
      label={`${prefix}Identification`}
      name={CheckinTabs.IDENTIFICATION}
      {...props}
    />
  );
};
