import { CircularProgress, Grid } from "@material-ui/core";
import jwt from "jsonwebtoken";
import React, { FunctionComponent, useMemo } from "react";

import { metabaseSecretKey, metabaseDashboardId } from "../../config";
import { useAppService } from "../../state";

const EXPIRATION_MINUTES = 60;
const METABASE_SITE_URL = "https://metabase.relieftelemed.com";

const dashboard = parseInt(metabaseDashboardId, 10);

export const VaccinationMetabaseDashboard: FunctionComponent = () => {
  const [{ currentEvent, currentLocation, user }] = useAppService();
  const metabasePayload = useMemo(
    () => ({
      resource: { dashboard },
      params: {
        organizationid: currentEvent?.organization,
        eventid: currentEvent?.id,
        locationid: currentLocation?.id,
        // single_date: "2020-01-01",
      },
      exp: Math.round(Date.now() / 1000) + EXPIRATION_MINUTES * 60,
    }),
    [currentEvent, currentLocation],
  );

  const iframeUrl = useMemo(() => {
    const token = jwt.sign(metabasePayload, metabaseSecretKey);
    return `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=false&hide_parameters=locationid,single_date`;
  }, [metabasePayload]);

  return currentEvent == null ? (
    <CircularProgress />
  ) : (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <iframe
          title="Metabase"
          src={iframeUrl}
          frameBorder={0}
          width="100%"
          height={800}
          allowTransparency
        />
      </Grid>
    </Grid>
  );
};
