import {
  Accordion,
  AccordionSummary,
  IconButton,
  AccordionActions,
  Typography,
  AccordionDetails,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import {
  ExpandMore,
  CheckCircleOutline,
  Edit,
  Cancel,
  CancelOutlined,
} from "@material-ui/icons";
import { TestResult } from "@relieftelemed/platform";
import React, { FunctionComponent, useState, ChangeEvent } from "react";

import { TabPanel } from "../../../components";
import { renderDate } from "../../../helpers";
import { useCheckinContext } from "../context";
import { CheckinTabs } from "../types";

import { CovidTestingForm } from "./covid-testing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

export const AccordionTestingReadOnlyDetails: FunctionComponent<TestResult> = ({
  name,
  result,
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography className={classes.heading}>Test name</Typography>
        <Typography className={classes.secondaryHeading}>{name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Test result</Typography>
        <Typography className={classes.secondaryHeading}>{result}</Typography>
      </Grid>
    </Grid>
  );
};

export const AccordionTestingBody: FunctionComponent<TestResult> = (
  testResult,
) => {
  const [isEditing, setIsEditing] = useState(false);

  const { onUpdateTestingInfo } = useCheckinContext();
  // const { vaccinationInfo, updateVaccinationInfo } = useChecklistContext();
  const handleEdit = () => {
    if (!isEditing) {
      onUpdateTestingInfo({
        testName: testResult.name,
        testResult: testResult.result,
        testResultId: testResult.id,
        emailUser: false,
      });
    }

    setIsEditing(!isEditing);
  };
  return (
    <>
      <AccordionDetails>
        {isEditing ? (
          <CovidTestingForm />
        ) : (
          <AccordionTestingReadOnlyDetails {...testResult} />
        )}
      </AccordionDetails>
      <AccordionActions>
        <IconButton onClick={handleEdit} size="small">
          <Edit />
        </IconButton>
      </AccordionActions>
    </>
  );
};
export const Panel: FunctionComponent = () => {
  const { patientTestResults } = useCheckinContext();

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange = (vaccineId: string) => (
    event: ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? vaccineId : false);
  };

  return (
    <TabPanel name={CheckinTabs.TESTING} id="testing-tab-panel">
      {patientTestResults?.map((testResult: TestResult) => (
        <Accordion
          expanded={expanded === testResult.id}
          onChange={handleChange(testResult.id)}
          key={testResult.id}
        >
          <AccordionSummary expandIcon={<ExpandMore />} id={testResult.id}>
            <Typography variant="overline">{`Testing ${
              testResult.name
            } ${renderDate(testResult.createdAt)}`}</Typography>
          </AccordionSummary>
          <AccordionTestingBody {...testResult} />
        </Accordion>
      ))}
      <Accordion
        expanded={expanded === "new-exisisting"}
        onChange={handleChange("new-exisisting")}
      >
        <AccordionSummary expandIcon={<ExpandMore />} id="new-exisisting">
          <Typography variant="overline">New Testing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CovidTestingForm />
        </AccordionDetails>
      </Accordion>
    </TabPanel>
  );
};
