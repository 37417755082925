import { ConditionPredicate } from "xstate";

import { CheckInContext, CheckInEvents, SetParticipantEvent } from "./types";

export const hasParticipantId: ConditionPredicate<
  CheckInContext,
  CheckInEvents
> = (context): boolean => {
  return context.participantId != null;
};

export const validPatientSelection: ConditionPredicate<
  CheckInContext,
  CheckInEvents
> = (_context, event): boolean => {
  return (event as SetParticipantEvent).participantId != null;
};
