import { Button, Input, InputLabel, makeStyles } from "@material-ui/core";
import React, { ChangeEventHandler, FunctionComponent } from "react";

interface FileInputProps {
  accepts?: string[];
  fullWidth?: boolean;
  id?: string;
  label: string;
  onChange: (file?: File) => void;
}

const normalizeExtension = (extension: string): string => {
  if (/^\.[a-z]+/i.test(extension)) {
    return extension;
  }

  return `.${extension}`;
};

const normalizeAccepts = (extensions?: string[]): string | undefined => {
  if (!extensions) {
    return undefined;
  }
  return extensions.map(normalizeExtension).join(",");
};

const useFileStyles = makeStyles({
  input: {
    display: "none",
  },
});

export const FileInput: FunctionComponent<FileInputProps> = ({
  accepts,
  fullWidth = false,
  id = "file-input",
  label,
  onChange,
}) => {
  const classes = useFileStyles();

  const handleSetFile: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newFile = event.target.files?.item(0);

    onChange(newFile ?? undefined);
  };

  return (
    <InputLabel htmlFor={id}>
      <Input
        className={classes.input}
        id={id}
        inputProps={{
          accept: accepts ? normalizeAccepts(accepts) : undefined,
        }}
        type="file"
        onChange={handleSetFile}
      />
      <Button component="span" fullWidth={fullWidth}>
        {label}
      </Button>
    </InputLabel>
  );
};
