import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, useState } from "react";

import { DashboardCard } from "../../components";
import { UpdateEventForm } from "../../events";
import { renderDatetime } from "../../helpers";
import { useIsLsu } from "../../hooks";
import { useAppService } from "../../state";

const formatDatetime = (value?: Date, fallback?: string): string => {
  if (!value) {
    return fallback ?? "No time set";
  }

  return renderDatetime(value);
};

const useBigTextStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.overline,
    fontSize: "1rem",
  },
  bigHeader: {
    ...theme.typography.overline,
    fontSize: "1.25rem",
  },
  promo: {
    fontSize: "2rem",
  },
}));

export const EventDetails: FunctionComponent = () => {
  const classes = useBigTextStyles();
  const [{ currentEvent }] = useAppService();
  const flags = useFlags();
  const isLsu = useIsLsu();

  const [open, changeOpen] = useState(false);

  const toggleEvent = () => changeOpen(!open);

  let editEvent;
  let form;

  if (flags.addEvent) {
    editEvent = (
      <IconButton aria-label="edit event" onClick={toggleEvent}>
        <Edit />
      </IconButton>
    );

    if (currentEvent && open) {
      form = (
        <UpdateEventForm
          event={currentEvent!}
          open={open}
          onClose={toggleEvent}
        />
      );
    }
  }

  let promoCode = "No Promo Code Set";
  if (currentEvent?.promoCode) {
    promoCode = currentEvent.promoCode;
  }
  if (isLsu) {
    promoCode = "Individual access codes are sent by email.";
  }

  return (
    <DashboardCard title="Event Details" action={editEvent}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell variant="head" component="th" scope="row">
                <Typography className={classes.header}>Start Time</Typography>
              </TableCell>
              <TableCell>
                {formatDatetime(currentEvent?.startedAt, "No Start Time")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" component="th" scope="row">
                <Typography className={classes.header}>End Time</Typography>
              </TableCell>
              <TableCell>
                {formatDatetime(currentEvent?.endedAt, "No End Time")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" component="th" scope="row">
                <Typography className={classes.bigHeader}>
                  Promo Code
                </Typography>
              </TableCell>
              <TableCell>
                <Typography className={isLsu ? "" : classes.promo}>
                  {promoCode}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {form}
    </DashboardCard>
  );
};
