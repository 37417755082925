import { makeStyles, Typography, Link } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { DashboardCard } from "../../components";
import { isNil } from "../../helpers";
import { useAppService } from "../../state";

const useJobSheetStyles = makeStyles({
  root: {
    fontSize: "2rem",
  },
});
const INFORMATION_NOT_AVAILABLE = "--";

export const JobSheet: FunctionComponent = () => {
  const classes = useJobSheetStyles();
  const [{ currentEvent }] = useAppService();

  return (
    <DashboardCard title="Job Sheet">
      <Typography align="center" className={classes.root}>
        {isNil(currentEvent?.planningDocument) ? (
          INFORMATION_NOT_AVAILABLE
        ) : (
          <Link href={currentEvent?.planningDocument}>Planning Document</Link>
        )}
      </Typography>
    </DashboardCard>
  );
};
