import { EventParticipant } from "@relieftelemed/platform";
import { ComponentType, createContext, useContext } from "react";

import { createTabContext, createTabContextProvider } from "../../components";

import { HookActions, HookSelectors, VaccineInfo, TestingInfo } from "./state";
import { CheckinTabs } from "./types";

export const [
  CheckinTabContext,
  useCheckinTabContext,
] = createTabContext<CheckinTabs>();

export const CheckinTabContextProvider = createTabContextProvider(
  CheckinTabContext,
);

export interface CheckinState extends HookActions, HookSelectors {
  update: (details: Partial<EventParticipant>) => void;
  participant?: EventParticipant;
  patientId?: string;
  setParticipant: (participant?: EventParticipant) => void;
  finish: () => void;
}

export const CheckinContext = createContext<CheckinState>({
  update: () => {},
  finish: () => {},
  setParticipant: () => {},
  onSelectParticipant: (maybeParticipant?: EventParticipant) => {},
  onUpdateVaccinationInfo: (vaccineInfo: VaccineInfo) => {},
  onPersistChanges: () => {},
  onSelectLocation: (checkInLocation: string) => {},
  onPartialEventParticipantUpdate: (
    partialParticipant: Partial<EventParticipant>,
  ) => {},
  onUpdateTestingInfo: (testingInfo: TestingInfo) => {},
});

export const useCheckinContext = () => useContext(CheckinContext);
