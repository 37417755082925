import { Box, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { FunctionComponent, useMemo } from "react";

import {
  InputImage,
  TabPanel,
  StyledImage,
  ImageNotAvailable,
  OpenImageInNewTabButton,
} from "../../../components";
import { CheckinTabs } from "../types";

import { usePhotoCardContext } from "./context";

export const Panel: FunctionComponent = () => {
  const {
    identification,
    insuranceBack,
    insuranceFront,
    patient,
  } = usePhotoCardContext();
  const maybeCovidUrl = useMemo(
    () =>
      patient?.covidVaccinationCardUrl == null ? (
        <ImageNotAvailable content="Covid vaccination card not available" />
      ) : (
        <div>
          <StyledImage
            src={patient?.covidVaccinationCardUrl}
            imageStyle={{
              width: "100%",
              maxHeight: 300,
              minHeight: 200,
              objectFit: "contain",
            }}
          />
          <OpenImageInNewTabButton src={patient?.covidVaccinationCardUrl} />
        </div>
      ),
    [patient],
  );

  return (
    <TabPanel name={CheckinTabs.IDENTIFICATION}>
      <Grid container spacing={1}>
        <Grid item xs={12} alignItems="center" container>
          <Typography variant="h3">Photo Id</Typography>
        </Grid>

        <Grid item xs={12}>
          {identification.isUploading ? (
            <Skeleton variant="rect" height={300} />
          ) : (
            <InputImage
              id="identification-image-upload"
              value={identification.value ?? patient?.identificationUrl}
              onChange={identification.setValue}
              placeholder="Photo Id not available"
              imageStyle={{
                width: "100%",
                maxHeight: 300,
                minHeight: 200,
                objectFit: "contain",
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} alignItems="center" container>
          <Typography variant="h3">Insurance Card</Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          {insuranceFront.isUploading ? (
            <Skeleton variant="rect" height={200} />
          ) : (
            <>
              <InputImage
                value={insuranceFront.value ?? patient?.insCardFrontImageUrl}
                onChange={insuranceFront.setValue}
                placeholder="Insurance image not available"
                imageStyle={{
                  width: "100%",
                  maxHeight: 200,
                  minHeight: 200,
                  objectFit: "contain",
                }}
                fileName="front"
              />
              <Box marginTop={1}>
                <Typography variant="caption">Front</Typography>
              </Box>
            </>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          {insuranceBack.isUploading ? (
            <Skeleton variant="rect" height={200} />
          ) : (
            <>
              <InputImage
                value={insuranceBack.value ?? patient?.insCardBackImageUrl}
                onChange={insuranceBack.setValue}
                placeholder="Insurance image not available"
                fileName="back"
                imageStyle={{
                  width: "100%",
                  maxHeight: 200,
                  minHeight: 200,
                  objectFit: "contain",
                }}
              />
              <Box marginTop={1}>
                <Typography variant="caption">Back</Typography>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} alignItems="center" container>
          <Typography variant="h3">Covid Vaccination Card</Typography>
        </Grid>
        <Grid item xs={12}>
          {maybeCovidUrl}
        </Grid>
      </Grid>
    </TabPanel>
  );
};
