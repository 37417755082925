import { Fragment } from "react";

import { TabConfiguration } from "../../../components";
import { CheckinTabs } from "../types";

import { PrimaryActionWrapper } from "./actions";
import { Panel } from "./panel";
import { Title } from "./title";

export const CovidTestingTab: TabConfiguration<CheckinTabs> = {
  primaryAction: PrimaryActionWrapper,
  context: Fragment,
  name: CheckinTabs.TESTING,
  panel: Panel,
  shouldShow: (event, isVaccinationEvent) => !isVaccinationEvent,
  title: Title,
};
