import { useTheme } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import {
  BarChart as ReBarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ChartProps } from "./chart-types";

export const VerticalBarChart: FunctionComponent<ChartProps> = ({
  children,
  data,
  multiple,
  ...props
}) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer aspect={2.5} {...props}>
      <ReBarChart
        data={data}
        layout="vertical"
        margin={{
          top: theme.spacing(0.5),
          bottom: theme.spacing(0.5),
          left: theme.spacing(1),
          right: theme.spacing(0.5),
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type={multiple ? undefined : "number"}
          dataKey={multiple ? undefined : "value"}
        />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        {children}
      </ReBarChart>
    </ResponsiveContainer>
  );
};
