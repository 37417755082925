import { Grid, Button } from "@material-ui/core";
import React, { FunctionComponent, ReactElement } from "react";

import { useCheckinContext } from "./context";
import { ActionProps } from "./types";

export const PrimaryAction: FunctionComponent<ActionProps> = ({
  disabled,
  icon,
  label,
  onClick,
}) => {
  const { update } = useCheckinContext();

  const defaultNext = () => update({});
  const handleNext = onClick ?? defaultNext;

  return (
    <Grid item xs={6} md={3}>
      <Button disabled={disabled} startIcon={icon} onClick={handleNext}>
        {label ?? "Next"}
      </Button>
    </Grid>
  );
};
