import React, { FunctionComponent, useEffect } from "react";
import { useParams } from "react-router";

import { ApiState, usePatientEventHistory } from "../../core";
import { usePageTitle } from "../../hooks";
import { useAppService } from "../../state";

import { ParticipantsTable } from "./table";

interface DetailsRouteParams {
  organization?: string;
  patient: string;
}

export const PatientDetailsPage: FunctionComponent = () => {
  const setTitle = usePageTitle();
  const [{ currentOrganization }] = useAppService();
  const { organization, patient } = useParams<DetailsRouteParams>();
  const { isLoading, data: history } = usePatientEventHistory({
    organization: organization ?? currentOrganization!.id,
    patient,
  });

  useEffect(() => {
    if (!isLoading && history && history!.length > 0) {
      const { patientName = "" } = history[0];

      setTitle(`${patientName} History`);
    }
  }, [isLoading, history, setTitle]);

  return <ParticipantsTable patientHistory={history ?? []} variant="details" />;
};
