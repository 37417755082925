import { Box } from "@material-ui/core";
import { Queue } from "@material-ui/icons";
import React, { FunctionComponent, Fragment, ComponentType } from "react";

import { FormModal } from "../../components";

import { Actions } from "./actions";
import { FormHeader } from "./form-header";
import { useTabContext } from "./tab-context";
import { Tabs } from "./tabs";

export interface CheckInFormProps {
  onClose?: () => void;
  open?: boolean;
}

export const CheckInForm: FunctionComponent<CheckInFormProps> = ({
  onClose,
  open = false,
}) => {
  const { currentTab } = useTabContext();
  const { context } = currentTab || { context: Fragment };
  const TabContext: ComponentType = context as ComponentType;
  return (
    <TabContext>
      <FormModal
        icon={Queue}
        fullWidth
        onClose={onClose}
        open={open}
        scroll="body"
        title="Check In"
        titleProps={{ variant: "h1" }}
        actions={<Actions />}
      >
        <Box style={{ height: "32rem" }} id="check-in-form">
          <FormHeader />
          <Tabs />
        </Box>
      </FormModal>
    </TabContext>
  );
};
