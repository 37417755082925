import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import React, {
  ChangeEvent,
  FunctionComponent,
  useMemo,
  useState,
  useEffect,
} from "react";

import { EventLocation } from "../../core";
import { useAppService } from "../../state";

export interface SelectLocationProps {
  onChange?: (selectedLocation: string) => void;
  value?: string;
}

interface Location {
  label: string;
  value: string;
}

const mapLocations = (eventLocations: EventLocation[]): Location[] => {
  if (eventLocations.length === 0) {
    return [];
  }

  return eventLocations.map((location) => ({
    label: location.name,
    value: location.id,
  }));
};

const valueRenderer = (locations: Location[]) => (value: unknown) => {
  if (typeof value !== "string") {
    return value as string;
  }

  let label = value;
  if (value.length > 0) {
    const maybeLocation = locations.find(
      (location) => location.value === value,
    );

    if (maybeLocation) {
      label = maybeLocation.label;
    }
  }

  return label;
};

export const SelectLocation: FunctionComponent<
  SelectLocationProps & Omit<TextFieldProps, "onChange">
> = ({ onChange, SelectProps, value: passedValue, ...props }) => {
  const [{ locations, currentLocation }] = useAppService();
  const shownLocations = useMemo(() => mapLocations(locations), [locations]);
  const [internalValue, changeValue] = useState(
    currentLocation != null
      ? currentLocation.id
      : passedValue ?? shownLocations[0].value,
  );

  useEffect(() => {
    if (!passedValue && internalValue && onChange) {
      onChange(internalValue);
    }
  }, [passedValue, internalValue, onChange]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? "";
    const callback = onChange ?? changeValue;

    callback(newValue);
  };

  const usedValue =
    passedValue && passedValue.length > 0 ? passedValue : internalValue;

  return (
    <TextField
      label="Check-in Location"
      {...props}
      onChange={handleChange}
      value={usedValue}
      required={locations.length > 0}
      select
      SelectProps={{
        ...SelectProps,
        renderValue: valueRenderer(shownLocations),
      }}
    >
      <MenuItem value="" />
      {shownLocations.map((location) => (
        <MenuItem key={location.value} value={location.value}>
          {location.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
