import { useMemo } from "react";

import { createSpecimenLabel, SpecimenLabelDetails } from "../helpers";

import { useDymoLabel } from "./dymo";

export const useSpecimenLabel = (details?: SpecimenLabelDetails) => {
  const rawLabel = useMemo(() => {
    if (!details) {
      return undefined;
    }

    return createSpecimenLabel(details);
  }, [details]);

  const label = useDymoLabel(rawLabel);

  return label;
};
