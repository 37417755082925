import { TextField, TextFieldProps as ITextField } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import React, { ChangeEvent, FunctionComponent, useMemo } from "react";

import { EventParticipant } from "../../core";
import { useAppService } from "../../state";

export type ParticipantFilter = "checkedIn" | "notCheckedIn" | "visited";

export interface SelectParticipantProps {
  onChange?: (selectedParticipant: EventParticipant | undefined) => void;
  value?: EventParticipant;
  filter?: ParticipantFilter;
  TextFieldProps?: ITextField;
}

const hasCheckedIn = (participant: EventParticipant) =>
  participant.checkedInAt !== undefined;
const hasVisited = (participant: EventParticipant) =>
  participant.visitedAt !== undefined || !participant.isVisitRequired;
const hasntCheckedIn = (participant: EventParticipant) =>
  hasVisited(participant) && !hasCheckedIn(participant);

export const SelectParticipant: FunctionComponent<
  SelectParticipantProps &
    Partial<
      Omit<AutocompleteProps<EventParticipant, false, false, false>, "onChange">
    >
> = ({ filter, onChange, value: passedValue, TextFieldProps, ...props }) => {
  const [{ participants }] = useAppService();

  const filteredParticipants = useMemo(() => {
    if (!filter) {
      return participants;
    }
    let filterFn: (participant: EventParticipant) => boolean = () => true;

    switch (filter) {
      case "checkedIn":
        filterFn = hasCheckedIn;
        break;
      case "notCheckedIn":
        filterFn = hasntCheckedIn;
        break;
      case "visited":
        filterFn = hasVisited;
        break;
      default:
        break;
    }

    return participants.filter(filterFn);
  }, [filter, participants]);

  const handleChange = (
    _event: ChangeEvent<{}>,
    newValue: EventParticipant | null,
  ) => {
    if (onChange) {
      onChange(newValue ?? undefined);
    }
  };

  return (
    <Autocomplete
      id="participant"
      blurOnSelect
      {...props}
      options={filteredParticipants}
      getOptionLabel={(option: EventParticipant) => option.patientName ?? ""}
      getOptionSelected={(option, selectedValue) =>
        option.id === selectedValue.id
      }
      renderInput={(params: {}) => (
        <TextField label="Participant" {...TextFieldProps} {...params} />
      )}
      onChange={handleChange}
      value={passedValue ?? null}
    />
  );
};
