import {
  LinearProgress,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import React, { FunctionComponent, useState, ChangeEvent } from "react";

import { useGetPromotions, ApiState } from "../../core";
import { formatPromotionDetails } from "../../helpers";

export interface SelectPromotionProps {
  onChange?: (selected: string) => void;
  value?: string;
}

export const SelectPromotion: FunctionComponent<
  SelectPromotionProps & Omit<TextFieldProps, "onChange">
> = ({ onChange, SelectProps, value: passedValue = "", ...props }) => {
  const { data: promotions, isLoading } = useGetPromotions();
  const [internalValue, changeValue] = useState(passedValue);

  const valueRenderer = (value: unknown) => {
    if (typeof value !== "string") {
      return value as string;
    }

    let label = value;
    if (value.length > 0) {
      const maybePromo = promotions?.find(
        (promotion) => promotion.id === value,
      );

      if (maybePromo) {
        label = `${maybePromo.code} (${formatPromotionDetails(maybePromo)})`;
      }
    }

    return label;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? "";
    const callback = onChange ?? changeValue;

    callback(newValue);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TextField
      label="Promotion"
      helperText="Select the promotion to associate with the event"
      {...props}
      onChange={handleChange}
      value={passedValue || internalValue}
      select
      SelectProps={{ ...SelectProps, renderValue: valueRenderer }}
    >
      {promotions!.map((promotion) => (
        <MenuItem key={promotion.id} value={promotion.id}>
          {promotion.code} ({formatPromotionDetails(promotion)})
        </MenuItem>
      ))}
    </TextField>
  );
};
