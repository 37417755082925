import React, { FunctionComponent } from "react";

import { TabList } from "../../components";

import { useTabContext } from "./tab-context";

export const Tabs: FunctionComponent = () => {
  const { currentTab, tabs, selectedTab, setSelectedTab } = useTabContext();
  const { panel: Panel } = currentTab!;
  return (
    <>
      <TabList selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
        {tabs.map((tab, index) => {
          const { title: Title } = tab;

          return (
            <Title
              key={`${tab.name}-title`}
              value={index}
              prefix={`${index + 1}. `}
            />
          );
        })}
      </TabList>
      <Panel />
    </>
  );
};
