import { useFlags } from "launchdarkly-react-client-sdk";

import { ProviderUser } from "../core";
import { useAppService } from "../state";

export const useCanSeeGlobal = (includeNurse = true): boolean => {
  const [{ user }] = useAppService();
  const flags = useFlags();

  let hasClinicNurseRole = false;
  let hasReliefNurseRole = false;
  let hasProviderRole = false;
  if (user?.roles) {
    hasClinicNurseRole = user.roles.includes("clinic_nurse");
    hasReliefNurseRole = user.roles.includes("relief_nurse");
    hasProviderRole = user.roles.includes("provider");
  }

  return (
    flags.addOrganization ||
    (user as ProviderUser).providerId ||
    hasProviderRole ||
    (includeNurse && (hasClinicNurseRole || hasReliefNurseRole))
  );
};
