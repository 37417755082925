import { useMemo } from "react";

import { EventParticipant } from "../core";
import { useAppService } from "../state";

export const useParticipantsAtLocation = (participants: EventParticipant[]) => {
  const [{ currentLocation }] = useAppService();

  const participantsAtLocation = useMemo(() => {
    return participants.filter((participant) => {
      const checkInLocation =
        typeof participant.checkInLocation === "string"
          ? participant.checkInLocation
          : participant.checkInLocation?.id;

      return currentLocation ? checkInLocation === currentLocation.id : true;
    });
  }, [currentLocation, participants]);

  return participantsAtLocation;
};
