import {
  Box,
  Divider,
  Grid,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { Visit } from "../core";

import { VisitCardProps } from "./visit-quick-card";

interface VisitListProps {
  onSelect: (visit: Visit) => () => void;
  selected?: Visit;
  title: string;
  visits: Visit[];
  VisitCard: FunctionComponent<VisitCardProps>;
}

const useVisitListGroupStyles = makeStyles((theme) => ({
  title: {
    alignItems: "center",
    background: theme.palette.background.default,
    margin: `${theme.spacing(2)}px 0`,
    display: "flex",
  },
}));

export const VisitListGroup: FunctionComponent<VisitListProps> = ({
  onSelect,
  selected,
  title,
  visits,
  VisitCard,
}) => {
  const classes = useVisitListGroupStyles();
  if (visits.length === 0) {
    return null;
  }

  return (
    <>
      <ListSubheader className={classes.title} component="div" disableGutters>
        <Box pr={1} flex="0 1 auto">
          <Typography display="inline" color="secondary">
            {title}
          </Typography>
        </Box>
        <Box pl={1} flex="1 0 auto">
          <Divider />
        </Box>
      </ListSubheader>
      <Grid
        container
        spacing={2}
        className={`visits-list visits-list-${title}`}
      >
        {visits.map((visit) => (
          <Grid key={visit.id} item xs={12}>
            <VisitCard
              visit={visit}
              isSelected={selected?.id === visit.id}
              onClick={onSelect(visit)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
