import { EventParticipant } from "@relieftelemed/platform";
import React, { FunctionComponent } from "react";

import { useIsVaccinationEvent } from "../../../hooks";
import { useCheckinContext } from "../context";
import { PrimaryAction } from "../primary-action";

import { canSubmitForVaccination } from "./validators";

export const PrimaryActionWrapper: FunctionComponent<{ label?: string }> = ({
  label,
}) => {
  const { update, finish, vaccineInfo } = useCheckinContext();
  const isVaccinationEvent = useIsVaccinationEvent();

  const handleClick = () => {
    const extraDetails: Partial<EventParticipant> = {
      orgConsent: true,
    };
    if (isVaccinationEvent) {
      update(extraDetails);
    }
    finish();
  };

  return (
    <PrimaryAction
      disabled={vaccineInfo == null || !canSubmitForVaccination(vaccineInfo!)}
      onClick={handleClick}
      label={label}
    />
  );
};
