import { Box } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useLocation } from "react-router";

import { TitleContextProvider } from "../../hooks";
import { DownloadParticipantButton } from "../../participants";
import { Layout, LayoutProps, LayoutWidth } from "../layout";

import { AppointmentDateFilterMenu } from "./appointment-date-filter-menu";
import { EventsMenu } from "./events-menu";
import { LocationMenu } from "./location-menu";
import { Navigation } from "./navigation";
import { ParticipantFilter } from "./participant-filter";
import { ToggleGlobal } from "./toggle-global";

export const AppLayout: FunctionComponent<LayoutProps> = ({
  children,
  ...props
}) => {
  const { pathname } = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [a11yTitle, setA11yTitle] = useState<string>();

  const changeTitle = (title: string, srTitle?: string): void => {
    setPageTitle(title);
    setA11yTitle(srTitle);
  };

  return (
    <Layout
      {...props}
      dark
      width={LayoutWidth.narrow}
      showAppBar={!pathname.includes("metabase")}
      appBarLeft={
        <>
          <EventsMenu />
          <LocationMenu />
          {/* <TimeframeMenu /> Im Disabling this for now */}
          <AppointmentDateFilterMenu />
        </>
      }
      appBarRight={
        <>
          <ParticipantFilter />
          <Box m={1}>
            <DownloadParticipantButton />
          </Box>
        </>
      }
      sidebar={<Navigation />}
      title={pageTitle}
      srTitle={a11yTitle}
    >
      <ToggleGlobal />
      <TitleContextProvider value={changeTitle}>
        {children}
      </TitleContextProvider>
    </Layout>
  );
};
