import {
  Dialog as MuiDialog,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { DialogProps } from "./types";

const useDialogStyles = makeStyles((theme) => ({
  padded: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
}));

export const Dialog: FunctionComponent<DialogProps> = ({
  children,
  classes: passedClasses = {},
  padded = false,
  ...props
}) => {
  const theme = useTheme();
  const isSvp = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useDialogStyles();

  const dialogClasses = passedClasses;
  if (padded) {
    dialogClasses.paper = dialogClasses.paper
      ? `${dialogClasses.paper} ${padded}`
      : classes.padded;
  }

  return (
    <MuiDialog
      fullScreen={isSvp}
      maxWidth="md"
      {...props}
      classes={dialogClasses}
    >
      {children}
    </MuiDialog>
  );
};
