import { formatWithOptions } from "date-fns/fp";
import { ChangeEvent } from "react";

import { ValueSetter } from "./types";

export const renderDateTimeYearFirst = formatWithOptions(
  {},
  "yyyy-MM-dd hh:mm",
);

function testForCorrectDatetimeString(input: string) {
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}\s(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/i;
  return regex.test(input);
}

export const valueFromTarget = (funcTakeValue: ValueSetter) => ({
  target: { value },
}: ChangeEvent<HTMLInputElement>) => funcTakeValue(value);
