import { Button } from "@material-ui/core";
import { EventParticipant } from "@relieftelemed/platform";
import React, { FunctionComponent } from "react";

import { PrintLabelWrapper } from "./print-label-wrapper";
import { PrintLabelRenderer } from "./types";

interface PrintLabelProps {
  participant: EventParticipant;
}

export const PrintLabelButton: FunctionComponent<PrintLabelProps> = ({
  participant,
}) => {
  const button: PrintLabelRenderer = (handleReprint, isDisabled) => (
    <Button
      onClick={handleReprint}
      variant="outlined"
      size="small"
      disabled={isDisabled}
    >
      Reprint Specimen Label
    </Button>
  );

  return <PrintLabelWrapper participant={participant} render={button} />;
};
