import { createContext, useContext } from "react";

import { TestResultChange } from "../../core";

export interface ChangesContext {
  changes: TestResultChange[];
  add: (newChange: TestResultChange) => void;
  revert: (existingChange: TestResultChange) => void;
}

export const EditContext = createContext<ChangesContext | undefined>(undefined);

export const useEditContext = () => useContext(EditContext);
