import {
  DialogContentText,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Assignment } from "@material-ui/icons";
import React, {
  ChangeEventHandler,
  Fragment,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";

import { Button, FormModal } from "../../components";
import {
  ApiState,
  EventParticipant,
  filterChange,
  matchChange,
  TestResultChange,
  useBulkAddResults,
} from "../../core";
import {
  getParticipantEventId,
  getResult,
  parseBooleanResult,
} from "../../helpers";
import { allCovid } from "../../labs";
import { useAppService } from "../../state";

interface AddResultFormProps {
  onClose?: () => void;
  participant: EventParticipant;
}

const rapidTests = allCovid.filter((test) => test.type === "rapid");

const useVisitModalStyles = makeStyles({
  primaryAction: {
    textAlign: "right",
  },
});

export const AddResultForm: FunctionComponent<AddResultFormProps> = ({
  onClose,
  participant,
}) => {
  const queryClient = useQueryClient();
  const classes = useVisitModalStyles();
  const [changes, setChanges] = useState<TestResultChange[]>([]);
  const { mutate: addResultsMutate } = useBulkAddResults();

  const handleSubmit = () => {
    addResultsMutate(changes, {
      onSuccess: () => {
        queryClient.invalidateQueries("get-event-participants");
        if (onClose) {
          onClose();
        }
      },
    });
  };

  return (
    <FormModal
      icon={Assignment}
      fullWidth
      onClose={onClose}
      open
      scroll="body"
      title="Add Test Result"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button onClick={handleSubmit}>Submit</Button>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText variant="h3">
        Add Rapid Test Results for {participant.patientName}
      </DialogContentText>
      <Grid container spacing={2}>
        {rapidTests.map((test) => {
          let initialValue = "";
          const result = getResult(test.name, participant);

          if (result) {
            const realValue = parseBooleanResult(result);
            initialValue = realValue ? "Positive" : "Negative";
          }

          let selectedValue = initialValue;

          const patientId =
            typeof participant.patient === "string"
              ? participant.patient
              : participant.patient.id;

          const search: TestResultChange = result
            ? { test: result.id }
            : {
                name: test.name,
                patient: patientId,
                event: getParticipantEventId(participant),
              };
          const existingChange = changes.find(matchChange(search));

          if (existingChange) {
            selectedValue = existingChange.result!;
          }

          const handleChange: ChangeEventHandler<HTMLInputElement> = (
            event,
          ) => {
            const newValue = event.target.value;

            if (newValue === initialValue) {
              setChanges([...changes.filter(filterChange(search))]);
              return;
            }

            const newChange = {
              ...search,
              result: event.target.value,
            };

            setChanges([...changes.filter(filterChange(search)), newChange]);
          };

          return (
            <Fragment key={test.id}>
              <Grid item xs={12} lg={6}>
                {test.name}
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label="Result"
                  onChange={handleChange}
                  select
                  value={selectedValue}
                >
                  <MenuItem value="">--</MenuItem>
                  <MenuItem value="Positive">Positive</MenuItem>
                  <MenuItem value="Negative">Negative</MenuItem>
                </TextField>
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </FormModal>
  );
};
