import { logger } from "@relieftelemed/platform";

import { print } from "./common";
import {
  DEFAULT_CONFIG,
  BARCODE_WIDTH_MULTIPLIER,
  PRINTER_WIDTH,
  DEFAULT_BARCODE_HEIGHT,
  DEFAULT_BARCODE_WIDTH,
  DEFAULT_FONT_SIZE,
  DEFAULT_BOX_THICKNESS,
  MAXIMUM_PRINTER_WIDTH,
} from "./constants";
import {
  DeviceBlockType,
  DeviceFontAlignment,
  DeviceFontRotation,
  DeviceFontType,
  LabelConfig,
  LabelBarcodeElementOptions,
  LabelElementPosition,
  LabelTextElementOptions,
  LabelBlockElementOptions,
  PrintConfig,
  PrintFunction,
  DeviceBarcodeType,
  DeviceBarcodeTextPosition,
} from "./types";

export class LabelMaker {
  private actions: PrintFunction[] = [];

  private config: LabelConfig;

  public constructor(config?: LabelConfig) {
    this.actions = [];
    this.config = config ?? DEFAULT_CONFIG;
  }

  private createConfigCommands(): PrintFunction[] {
    const configCommands = [];

    let width = this.config.width ?? PRINTER_WIDTH;
    if (width > MAXIMUM_PRINTER_WIDTH) {
      logger.info("Attempted to configure printer beyond its maximum width");
      width = MAXIMUM_PRINTER_WIDTH;
    }

    configCommands.push({ setWidth: [width] });

    if (this.config.type) {
      configCommands.push({ setPrintingType: [this.config.type] });
    }

    if (this.config.margin) {
      configCommands.push({
        setMargin: [this.config.margin.vertical, this.config.margin.horizontal],
      });
    }

    if (this.config.length) {
      configCommands.push({
        setLength: [
          this.config.length.base,
          this.config.length.gap,
          this.config.length.media,
          this.config.length.offset,
        ],
      });
    }

    if (this.config.speed) {
      configCommands.push({ setSpeed: [this.config.speed] });
    }

    if (this.config.density) {
      configCommands.push({ setDensity: [this.config.density] });
    }

    if (this.config.orientation) {
      configCommands.push({ setOrientation: [this.config.orientation] });
    }

    if (this.config.offset) {
      configCommands.push({ setOffset: [this.config.offset] });
    }

    if (this.config.tearoffPosition) {
      configCommands.push({
        setTearoffPosition: [this.config.tearoffPosition],
      });
    }

    if (this.config.cutInterval) {
      configCommands.push({ setAutoCutter: [true, this.config.cutInterval] });
    }

    return configCommands;
  }

  private createPrintConfig(): PrintConfig {
    const actions = this.createConfigCommands().concat(this.actions);

    return {
      id: this.config.id ?? Math.round(Math.random() * 10),
      functions: {
        func0: {
          clearBuffer: [],
        },
        ...actions.reduce(
          (aggregator, action, index) => ({
            ...aggregator,
            [`func${index + 1}`]: action,
          }),
          {},
        ),
        [`func${actions.length + 1}`]: {
          printBuffer: [],
        },
      },
    };
  }

  public reset() {
    this.actions = [];
  }

  public print(printerName?: string): Promise<string | undefined> {
    return print(this.createPrintConfig(), printerName);
  }

  public addText(text: string, options: LabelTextElementOptions) {
    this.actions.push({
      drawVectorFont: [
        text,
        options.position[0],
        options.position[1],
        options.font ?? DeviceFontType.ASCII,
        options.width ?? DEFAULT_FONT_SIZE,
        options.height ?? DEFAULT_FONT_SIZE,
        options.bold ? 1 : 0,
        options.invert ? 1 : 0,
        options.italics ? 1 : 0,
        options.rotation ?? DeviceFontRotation.NONE,
        options.align ?? DeviceFontAlignment.LEFT,
        options.rtl ? 1 : 0,
      ],
    });
  }

  public addBox(
    topLeft: LabelElementPosition,
    bottomRight: LabelElementPosition,
    options?: LabelBlockElementOptions,
  ) {
    this.actions.push({
      drawBlock: [
        topLeft[0],
        topLeft[1],
        bottomRight[0],
        bottomRight[1],
        options?.type ?? DeviceBlockType.OUTLINE,
        options?.thickness ?? DEFAULT_BOX_THICKNESS,
      ],
    });
  }

  public addImage(
    image: string,
    position: LabelElementPosition,
    width: number,
    dither?: boolean,
  ) {
    this.actions.push({
      drawBitmap: [image, position[0], position[1], width, dither ? 1 : 0],
    });
  }

  public addBarcode(
    text: string,
    position: LabelElementPosition,
    options?: LabelBarcodeElementOptions,
  ) {
    const baseWidth = options?.width ?? DEFAULT_BARCODE_WIDTH;
    let labelOption = 0;
    const textSize = options?.textSize ?? 1;
    const textPosition =
      options?.textPosition ?? DeviceBarcodeTextPosition.NONE;
    switch (textPosition) {
      case DeviceBarcodeTextPosition.BELOW:
        labelOption = 2 * textSize - 1;
        break;
      case DeviceBarcodeTextPosition.ABOVE:
        labelOption = 2 * textSize;
        break;
      case DeviceBarcodeTextPosition.NONE:
      default:
        break;
    }

    this.actions.push({
      draw1DBarcode: [
        text,
        position[0],
        position[1],
        options?.type ?? DeviceBarcodeType.CODE39,
        baseWidth,
        options?.wideWidth ?? baseWidth * BARCODE_WIDTH_MULTIPLIER,
        options?.height ?? DEFAULT_BARCODE_HEIGHT,
        options?.rotation ?? DeviceFontRotation.NONE,
        labelOption,
      ],
    });
  }
}
