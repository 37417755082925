import { Button, Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import React, { FunctionComponent, MouseEvent, useState } from "react";

import { Event } from "../../core";
import { useAppService } from "../../state";

export const EventsMenu: FunctionComponent = () => {
  const [{ currentEvent, events }, , { changeEvent }] = useAppService();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (event: Event) => () => {
    changeEvent(event);
    handleClose();
  };

  if (events.length === 0) {
    return null;
  }

  let menu = null;
  if (events.length > 1) {
    menu = (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {events.map((event) => (
          <MenuItem
            key={event.id}
            className="event_menu_item"
            onClick={handleSelect(event)}
          >
            {event.name}
          </MenuItem>
        ))}
      </Menu>
    );
  }

  return (
    <>
      <Button
        id="events_menu"
        aria-controls={events.length > 1 ? "events-menu" : undefined}
        aria-haspopup={events.length > 1 ? "true" : undefined}
        disabled={events.length === 1}
        endIcon={events.length > 1 ? <KeyboardArrowDown /> : undefined}
        onClick={events.length > 1 ? handleClick : undefined}
        variant="text"
      >
        {currentEvent?.name ?? "Select Event"}
      </Button>
      {menu}
    </>
  );
};
