import React, { FunctionComponent, useEffect } from "react";

import { usePageTitle } from "../../hooks";
import { useAppService } from "../../state";

import { ParticipantsTable } from "./table";

export const ActionPlansPage: FunctionComponent = () => {
  const setTitle = usePageTitle();
  const [{ currentEvent }] = useAppService();

  useEffect(() => {
    const title = currentEvent
      ? `${currentEvent.orgName ?? "Event"} Action Plans (${currentEvent.name})`
      : undefined;
    setTitle("Action Plans", title);
  }, [currentEvent, setTitle]);

  return <ParticipantsTable variant="plans" />;
};
