import { logger } from "@relieftelemed/platform";
import ky from "ky";

import { DEFAULT_PRINTER_NAME, MPRINT_SERVER_URL } from "./constants";
import { PrintConfig, PrintResponse } from "./types";

const mPrintServer = ky.create({
  prefixUrl: MPRINT_SERVER_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  throwHttpErrors: false,
});

const updateRequest = async (
  printerName: string,
  RequestID: number,
  ResponseID: string,
) => {
  return mPrintServer.post(`${printerName}/checkStatus`, {
    body: JSON.stringify({
      RequestID,
      ResponseID,
      Timeout: 30,
    }),
  });
};

const handleDelayedResponse = async (
  printerName: string,
  RequestID: number,
  ResponseID: string,
): Promise<string | undefined> => {
  try {
    const response = await updateRequest(printerName, RequestID, ResponseID);

    if (response.status === 404) {
      return undefined;
    }

    const { Result: result } = await response.json();

    if (result.search("success") < 0 && result.search("error") < 0) {
      return await handleDelayedResponse(printerName, RequestID, ResponseID);
    }

    return result;
  } catch (error) {
    if (error.name === "TimeoutError") {
      logger.error("Caught timeout error");
    }
  }

  return handleDelayedResponse(printerName, RequestID, ResponseID);
};

export const print = async (
  payload: PrintConfig,
  printerName = DEFAULT_PRINTER_NAME,
) => {
  const response = await mPrintServer.post(printerName, {
    body: JSON.stringify(payload),
  });

  if (response.status === 404) {
    logger.error(`Printer ${printerName} not found.`);
    return undefined;
  }

  if (response.status > 399) {
    logger.error("An unknown error occurred during printing.");
    return undefined;
  }

  const { ResponseID, Result: result }: PrintResponse = await response.json();

  if (result.search("ready") >= 0 || result.search("progress") >= 0) {
    return handleDelayedResponse(printerName, payload.id, ResponseID);
  }

  return result;
};
