import { isSameDay, parseISO } from "date-fns";
import { useMemo } from "react";

import { EventParticipant } from "../core";
import { prop } from "../helpers";
import { useAppService } from "../state";

export const useParticipantsForDateFilter = (
  participants: EventParticipant[],
  valueMapper: (obj: Record<string, any>) => any = prop("appointmentAt"),
) => {
  const [{ dateFilter }] = useAppService();

  const participantsForToday = useMemo(() => {
    return dateFilter != null
      ? participants.filter(
          (participant) =>
            valueMapper(participant) != null &&
            isSameDay(valueMapper(participant), parseISO(dateFilter)),
        )
      : participants;
  }, [participants, dateFilter, valueMapper]);

  return participantsForToday;
};
