import { Tabs } from "@material-ui/core";
import React, { ChangeEvent, FunctionComponent } from "react";

import { TabListProps } from "./types";

export const TabList: FunctionComponent<TabListProps> = ({
  children,
  selectedTab,
  setSelectedTab,
}) => {
  const handleChange = (_event: ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Tabs value={selectedTab} onChange={handleChange}>
      {children}
    </Tabs>
  );
};
