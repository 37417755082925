import { useMemo } from "react";

import { EventParticipant } from "../core";
import { getResult, isResultInTimeframe } from "../helpers";
import { covidTest, covidRapidTest } from "../labs";
import { useAppService } from "../state";

export const useParticipantsWithResultsAt = (
  participants: EventParticipant[],
) => {
  const [{ timeframe }] = useAppService();

  const participantsInTimeframe = useMemo(() => {
    return participants.filter((participant) => {
      const maybePcrResult = getResult(covidTest.name, participant);
      const pcrInTimeframe = isResultInTimeframe(maybePcrResult, timeframe);

      const maybeRapidResult = getResult(covidRapidTest.name, participant);
      const rapidInTimeframe = isResultInTimeframe(maybeRapidResult, timeframe);

      return pcrInTimeframe || rapidInTimeframe;
    });
  }, [participants, timeframe]);

  return participantsInTimeframe;
};
