import { Tab, TabProps } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { TAB_SUFFIX, TAB_PANEL_SUFFIX } from "./constants";
import { TabTitleProps } from "./types";

export const TabTitle: FunctionComponent<TabTitleProps> = ({
  name,
  value,
  ...props
}) => {
  const elementName = name ?? value;
  return (
    <Tab
      aria-controls={`${elementName}-${TAB_PANEL_SUFFIX}`}
      id={`${elementName}-${TAB_SUFFIX}`}
      value={value}
      {...props}
    />
  );
};
