import {
  Button,
  DialogContentText,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import {
  ApiState,
  EventParticipant,
  EventParticipantStatus,
  useUpdateParticipant,
} from "@relieftelemed/platform";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { AnsweredQuestionsList, FormModal } from "../../components";

interface ReviewProps {
  onClose?: () => void;
  open?: boolean;
  participant?: EventParticipant;
}

const useVisitModalStyles = makeStyles({
  primaryAction: {
    textAlign: "right",
  },
});

export const Review: FunctionComponent<ReviewProps> = ({
  participant,
  open = false,
  onClose,
}) => {
  const classes = useVisitModalStyles();
  const queryClient = useQueryClient();
  const { mutate: updateParticipantMutate } = useUpdateParticipant();
  const mutationDone = () => {
    queryClient.invalidateQueries("get-event-participants");
    if (onClose) {
      onClose();
    }
  };

  const handleRefer = () => {
    updateParticipantMutate(
      {
        ...participant!,
        status: EventParticipantStatus.DENIED,
      },
      {
        onSuccess: mutationDone,
      },
    );
  };
  const handleApprove = () => {
    updateParticipantMutate(
      {
        ...participant!,
        status: EventParticipantStatus.APPROVED,
      },
      {
        onSuccess: mutationDone,
      },
    );
  };

  return (
    <FormModal
      icon={CheckBox}
      title="Review"
      fullWidth
      titleProps={{ variant: "h1" }}
      open={open}
      onClose={onClose}
      actions={
        <Grid container justify="flex-end">
          <Grid item>
            <Button variant="outlined" onClick={handleRefer}>
              Refer to Provider
            </Button>
          </Grid>
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button onClick={handleApprove}>Approve</Button>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText variant="h3">Patient</DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography>{participant?.patientName}</Typography>
        </Grid>
      </Grid>
      <DialogContentText variant="h3">Screening Questions</DialogContentText>
      <AnsweredQuestionsList participant={participant} />
    </FormModal>
  );
};
