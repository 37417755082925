import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  ButtonGroup,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, {
  FunctionComponent,
  MouseEvent,
  useState,
  useRef,
  useMemo,
} from "react";

import { useAnalytics } from "../../core";
import { lastN, notEmptyElementFilter } from "../../helpers";
import {
  useIsVaccinationEvent,
  useActionMenuContext,
  ActionMenuActions,
} from "../../hooks";
import { narrowDrawerWidth } from "../../styles";

const useAddVisitStyles = makeStyles((theme) => ({
  buttonGroup: {
    margin: theme.spacing(2),
  },
  button: {
    width: narrowDrawerWidth - theme.spacing(8),
  },
  menu: {
    width: narrowDrawerWidth - theme.spacing(4),
  },
}));

interface MenuItemAttribs {
  label: string;
  itemName: string;
}

type ActionSetter = (itemName: MenuItemAttribs) => () => void;

const toMenuItemForAction = (action: ActionSetter) => ({
  label,
  itemName,
  ...rest
}: MenuItemAttribs) => (
  <MenuItem key={itemName} onClick={action({ label, itemName })} {...rest}>
    {label}
  </MenuItem>
);

export const ActionButton: FunctionComponent = () => {
  const [isOpen, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const classes = useAddVisitStyles();
  const flags = useFlags();
  /* const canSeeGlobal = useCanSeeGlobal(); */
  const { track } = useAnalytics();
  const isVaccineEvent = useIsVaccinationEvent();

  const { setActiveAction } = useActionMenuContext();

  const toggleMenu = (event: MouseEvent<HTMLButtonElement> | {}) => {
    if (!isOpen) {
      track("action.start");
    }
    setOpen(!isOpen);
  };

  const addActionPlan = useMemo(
    () =>
      flags.addActionPlans && !isVaccineEvent
        ? {
            label: "Add Action Plan",
            itemName: ActionMenuActions.addActionPlan,
          }
        : null,
    [flags, isVaccineEvent],
  );

  const addOrganization = useMemo(
    () =>
      // flags.addOrganization
      //   ?
      [
        {
          label: "Add Event",
          itemName: ActionMenuActions.event,
          id: "add-event-action",
        },
        // {
        //   label: "Add Organization Contact",
        //   itemName: ActionMenuActions.contact,
        // },
      ],
    //     : null,
    [],
  );

  const addNurse = useMemo(
    () =>
      flags.addNurse
        ? { label: "Add Nurse", itemName: ActionMenuActions.nurse }
        : null,
    [flags],
  );

  const checkIn = useMemo(
    () =>
      flags.checkInPatient
        ? { label: "Check In A Patient", itemName: ActionMenuActions.checkin }
        : null,
    [flags],
  );

  const addResult = useMemo(
    () =>
      flags.addTestResults && !isVaccineEvent
        ? {
            label: "Add Rapid Test Results",
            itemName: ActionMenuActions.addResults,
          }
        : null,
    [flags, isVaccineEvent],
  );

  const uploadParticipants = useMemo(
    () =>
      flags.addParticipants && !isVaccineEvent
        ? {
            label: "Upload Participants",
            itemName: ActionMenuActions.uploadParticipants,
          }
        : null,
    [flags, isVaccineEvent],
  );

  const addParticipant = useMemo(
    () => ({
      label: "Add Participant",
      itemName: ActionMenuActions.addParticipant,
    }),
    [],
  );

  const menuItems: MenuItemAttribs[] = useMemo(
    () =>
      [
        addActionPlan,
        addOrganization,
        addNurse,
        checkIn,
        addResult,
        uploadParticipants,
        addParticipant,
      ]
        .flat()
        .filter(notEmptyElementFilter),
    [
      addActionPlan,
      addOrganization,
      addNurse,
      checkIn,
      addResult,
      uploadParticipants,
      addParticipant,
    ],
  );

  const defaultSelectedElement = useMemo(
    (): MenuItemAttribs => lastN<MenuItemAttribs>(1, menuItems),
    [menuItems],
  );

  const [selectedAction, setSelectedAction] = useState<
    MenuItemAttribs | undefined
  >(defaultSelectedElement!);

  const startAction = (menuItem: MenuItemAttribs) => () => {
    toggleMenu({});
    track("action.type", { type: menuItem.itemName });
    setSelectedAction(menuItem);
  };

  const toMenuItem = toMenuItemForAction(startAction);

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        className={classes.buttonGroup}
        ref={anchorRef}
      >
        <Button
          aria-controls="action-menu"
          aria-haspopup="true"
          onClick={() => setActiveAction(selectedAction?.itemName)}
          classes={{ root: classes.button }}
          id="selected-action-button"
        >
          {selectedAction != null ? selectedAction.label : "Action"}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={isOpen ? "split-button-menu" : undefined}
          aria-expanded={isOpen ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={toggleMenu}
          id="action-button-menu"
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>

      <Menu
        id="action-menu"
        anchorEl={anchorRef.current!}
        classes={{ paper: classes.menu }}
        open={isOpen}
        onClose={toggleMenu}
        transformOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        {menuItems.map(toMenuItem)}
      </Menu>
    </>
  );
};
