import { makeStyles, Typography } from "@material-ui/core";
import React, { FunctionComponent, useMemo } from "react";

import { DashboardCard } from "../../components";
import { getResult } from "../../helpers";
import {
  useParticipantsAtLocation,
  useParticipantsWithResultsAt,
} from "../../hooks";
import { covidTest, covidRapidTest } from "../../labs";
import { useAppService } from "../../state";

const useBigTextStyles = makeStyles({
  rate: {
    fontSize: "3rem",
  },
});

export const PositivityRate: FunctionComponent = () => {
  const classes = useBigTextStyles();
  const [{ participants }] = useAppService();

  const participantsAtLocation = useParticipantsAtLocation(participants);
  const participantsInTimeframe = useParticipantsWithResultsAt(
    participantsAtLocation,
  );

  const positiveParticipants = useMemo(() => {
    return participantsInTimeframe.filter((participant) => {
      const maybePcrResult = getResult(covidTest.name, participant);
      const pcrIsPositive = /positive/i.test(maybePcrResult?.result ?? "");

      const maybeRapidResult = getResult(covidRapidTest.name, participant);
      const rapiIsPositive = /positive/i.test(maybeRapidResult?.result ?? "");

      return pcrIsPositive || rapiIsPositive;
    });
  }, [participantsInTimeframe]);

  const positivity =
    participantsInTimeframe.length === 0
      ? 0
      : Math.round(
          (positiveParticipants.length / participantsInTimeframe.length) * 100,
        );

  return (
    <DashboardCard title="Positivity Rate">
      <Typography align="center" className={classes.rate}>
        {positivity}%
      </Typography>
    </DashboardCard>
  );
};
