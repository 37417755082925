import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { PersonAdd as AddPersonIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import {
  useCreateEventParticipant,
  useInsuranceProviders,
  raceChoices,
  RegisterParticipantForEventRequest,
  states,
  logger,
  validateEmail,
  Classification,
  Gender,
} from "@relieftelemed/platform";
import React, { FunctionComponent, useState, useMemo } from "react";

import {
  Button,
  DateInput,
  FormModal,
  InputImage,
  DatetimeInput,
} from "../../components";
import {
  renderDateYearFirst,
  renderAge,
  capitalize,
  renderDateTimeYearFirst,
  curryValidator,
} from "../../helpers";
import { useValidatedPhone, useValidatedState } from "../../hooks";
import { useAppService } from "../../state";

type FormProps = Omit<
  RegisterParticipantForEventRequest,
  "dob" | "appointmentTime" | "email" | "phone" | "classification" | "gender"
> & {
  dob: Date | null;
  appointmentTime: Date | null;
  email?: string;
  phone?: string;
  classification: string;
  gender: string;
};

interface NewParticipantProps {
  onCancel?: () => void;
  open: boolean;
}

const disabledInsuranceFields = {
  insSelfReportedPayer: undefined,
  insMemberId: undefined,
  insGroupId: undefined,
  insPlanId: undefined,
  insRelationship: undefined,
  insPayer: undefined,
};

const classificationMenuItems = Object.entries(Classification).map(
  ([key, value]) => (
    <MenuItem key={key} value={value}>
      {key}
    </MenuItem>
  ),
);

const genderMenuItems = Object.entries(Gender).map(([key, value]) => (
  <MenuItem key={key} value={value}>
    {key}
  </MenuItem>
));

export const NewParticipantForm: FunctionComponent<NewParticipantProps> = ({
  onCancel,
  open,
}) => {
  const [{ locations, currentEvent, user }] = useAppService();
  const { isLoading, data: insuranceProviders } = useInsuranceProviders();

  const { mutate } = useCreateEventParticipant(currentEvent?.id!);
  const [isPrestine, setPrestine] = useState(false);
  const [email, isEmailValid, changeEmail] = useValidatedState(
    "",
    curryValidator<string>(validateEmail),
  );
  const [mobile, isMobileValid, changeMobile] = useValidatedPhone();
  const [formState, setFormState] = useState<FormProps>({
    orgConsent: false,
    firstName: "",
    middleName: "",
    lastName: "",
    ssn: "",
    dob: null,
    insCheck: false,
    insSelfReportedPayer: "",
    insMemberId: "",
    insGroupId: "",
    insPlanId: "",
    insRelationship: "",
    insPayer: "other",
    address1: "",
    address2: "",
    city: "",
    state: "LA",
    zip: "",
    appointmentType: "",
    appointmentLocation: "",
    race: "",
    gender: "",
    identification: undefined,
    covidVaccinationCard: undefined,
    insCardFront: undefined,
    insCardBack: undefined,
    appointmentTime: null,
    classification: "",
  });

  const { canSubmit, maybeError } = useMemo(() => {
    // console.log("Form state: ", formState);
    if (
      currentEvent?.filesRequired &&
      // formState.covidVaccinationCard == null ||
      !formState.insCheck &&
      (formState.insCardBack == null || formState.insCardBack == null)
    ) {
      return { canSubmit: false, maybeError: "Files are required" };
    }
    if (!isEmailValid || !isMobileValid) {
      return { canSubmit: false, maybeError: "There are invalid fields" };
    }
    return { canSubmit: true };
  }, [formState, currentEvent, isEmailValid, isMobileValid]);

  const isRequiredIfAdult = useMemo(
    () =>
      formState.dob == null
        ? false
        : parseInt(renderAge(formState.dob), 10) >= 18,
    [formState],
  );
  const isInsSelfReportedPayerVisible = useMemo(
    () => formState.insPayer === "other",
    [formState],
  );

  const handleFormUpdate = (mods: Partial<FormProps>) => {
    setFormState((oldValues) => ({ ...oldValues, ...mods }));
  };
  return (
    <FormModal
      icon={AddPersonIcon}
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      onSubmit={(event) => {
        event.preventDefault();
        if (canSubmit) {
          mutate(
            {
              ...formState,
              dob: renderDateYearFirst(formState.dob! as Date),
              ...(formState.insCheck ? disabledInsuranceFields : {}),
              appointmentTime:
                formState.appointmentTime == null
                  ? undefined
                  : renderDateTimeYearFirst(formState.appointmentTime),
              email,
              phone: mobile,
              classification: formState.classification as Classification,
              gender: formState.gender as Gender,
            },
            {
              onSuccess: (_success) => onCancel!(),
              onError: (error) =>
                logger.error(
                  "There was an error and handler is not implemented yet",
                  error,
                ), // Ask James this.
            },
          );
        } else {
          setPrestine(true);
        }
      }}
      open={open}
      scroll="body"
      title="Add new participant"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3}>
            <Button type="submit" disabled={false} id="submit-modal-form">
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Participant</Typography>
        </Grid>
        {isPrestine && maybeError && (
          <Grid item xs={12}>
            <Alert severity="error">{maybeError}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            label="Participant consents to having their information shared with appropiate state healthcare agencies."
            control={
              <Checkbox
                required
                checked={formState.orgConsent}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": "antibody-disclosure-label" }}
                onChange={() =>
                  handleFormUpdate({ orgConsent: !formState.orgConsent })
                }
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="appointmentType"
            label="Appointment type"
            value={formState.appointmentType}
            onChange={({ target: { value: appointmentType } }) =>
              handleFormUpdate({ appointmentType })
            }
            required
            select
            defaultValue=""
          >
            <MenuItem key="empty" value="">
              ----
            </MenuItem>
            {currentEvent?.appointmentTypeOptions!.map((apType) => (
              <MenuItem key={apType} value={apType}>
                {apType.split("_").map(capitalize).join(" ")}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="location"
            label="Location"
            value={formState.appointmentLocation}
            onChange={({ target: { value: appointmentLocation } }) =>
              handleFormUpdate({ appointmentLocation })
            }
            select
            defaultValue=""
          >
            <MenuItem key="empty" value="">
              ----
            </MenuItem>
            {locations.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <InputImage
            id="government-issued-id"
            value={formState.identification}
            onChange={(identification: File) =>
              handleFormUpdate({ identification })
            }
            placeholder="Government Issued ID"
            showOpenImageInNewTabButton={false}
            imageStyle={{
              width: "100%",
              maxHeight: 200,
              minHeight: 100,
              objectFit: "contain",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputImage
            id="covid-vaccination-card"
            value={formState.covidVaccinationCard}
            onChange={(covidVaccinationCard: File) =>
              handleFormUpdate({ covidVaccinationCard })
            }
            placeholder="Covid Vaccination Card"
            showOpenImageInNewTabButton={false}
            imageStyle={{
              width: "100%",
              maxHeight: 200,
              minHeight: 100,
              objectFit: "contain",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="participant-classification"
            label="Participant Classification"
            value={formState.classification}
            onChange={({ target: { value: classification } }) =>
              handleFormUpdate({ classification })
            }
            required
            select
            defaultValue=""
          >
            <MenuItem key="empty" value="">
              ----
            </MenuItem>
            {classificationMenuItems}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="participant-first-name"
            label="Participant First Name"
            onChange={({ target: { value } }) =>
              handleFormUpdate({ firstName: value })
            }
            value={formState.firstName}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="participant-middle-name"
            onChange={({ target: { value } }) =>
              handleFormUpdate({ middleName: value })
            }
            value={formState.middleName}
            label="Participant Middle Name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="participant-last-name"
            onChange={({ target: { value } }) =>
              handleFormUpdate({ lastName: value })
            }
            value={formState.lastName}
            label="Participant Last Name"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="participant-email"
            label="Participant Email"
            onChange={changeEmail}
            error={!isEmailValid}
            value={email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="participant-phone"
            label="Participant Phone"
            error={!isMobileValid}
            onChange={changeMobile}
            value={mobile}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="participant-ssn"
            label="Participant Social Security Number (SSN)"
            helperText="(required if participant is an adult)"
            onChange={({ target: { value } }) =>
              handleFormUpdate({ ssn: value })
            }
            value={formState.ssn}
            required={isRequiredIfAdult}
          />
        </Grid>
        <Grid item xs={12}>
          <DateInput
            id="participant-dob"
            label="Participant Date of Birth"
            onChange={(birthDate: Date | null) =>
              handleFormUpdate({ dob: birthDate })
            }
            value={formState.dob}
            maxDate={new Date()}
            required
          />
        </Grid>
        <Grid item xs={12}>
          {/* Where does this data come from */}
          <TextField
            id="participant-gender"
            label="Participant Gender"
            onChange={({ target: { value: gender } }) =>
              handleFormUpdate({ gender })
            }
            value={formState.gender}
            required
            select
            defaultValue=""
          >
            <MenuItem value="" key="empty">
              ----
            </MenuItem>
            {genderMenuItems}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          {/* Where does this data come from */}
          <TextField
            id="participant-race"
            label="Participant Race"
            onChange={({ target: { value: race } }) =>
              handleFormUpdate({ race })
            }
            value={formState.race}
            required
            select
            defaultValue=""
          >
            <MenuItem key="empty" value="">
              ----
            </MenuItem>
            {raceChoices.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Insurance</Typography>
        </Grid>
        <Grid item xs={12}>
          {/* Will this disable the form that follows if he does not have insurance */}
          <FormControlLabel
            label="Select if you do not have health insurance."
            control={
              <Checkbox
                checked={formState.insCheck}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": "antibody-disclosure-label" }}
                onChange={() =>
                  handleFormUpdate({ insCheck: !formState.insCheck })
                }
              />
            }
          />
        </Grid>
        {!formState.insCheck && (
          <>
            <Grid item xs={12}>
              <TextField
                id="healthInsurance"
                label="Health Insurance"
                value={formState.insPayer}
                onChange={({ target: { value: insPayer } }) =>
                  handleFormUpdate({ insPayer })
                }
                InputProps={{
                  endAdornment: isLoading ? (
                    <div style={{ marginRight: "1.5em" }}>
                      <CircularProgress color="inherit" size={20} />
                    </div>
                  ) : null,
                }}
                required
                select
              >
                <MenuItem key="other" value="other">
                  Other
                </MenuItem>
                {insuranceProviders?.map(({ name, id }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {isInsSelfReportedPayerVisible && (
              <Grid item xs={12}>
                <TextField
                  id="healthInsurance"
                  label="Health Insurance"
                  helperText="(Required only if Other was selected above)"
                  value={formState.insSelfReportedPayer}
                  onChange={({ target: { value: insSelfReportedPayer } }) =>
                    handleFormUpdate({
                      insSelfReportedPayer,
                    })
                  }
                  required
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id="healthInsuranceMemberId"
                label="Health Insurance Member ID"
                value={formState.insMemberId}
                onChange={({ target: { value: insMemberId } }) => {
                  handleFormUpdate({
                    insMemberId,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="healthInsuranceGroupId"
                label="Health Insurance Group ID"
                value={formState.insGroupId}
                onChange={({ target: { value: insGroupId } }) => {
                  handleFormUpdate({
                    insGroupId,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="healthInsurancePlanID"
                label="Health Insurance Plan ID"
                helperText="(Optional)"
                value={formState.insPlanId}
                onChange={({ target: { value: insPlanId } }) => {
                  handleFormUpdate({
                    insPlanId,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="relationshipToPerson"
                label="Relationship to Person Insured (self, spouse, child, etc)"
                value={formState.insRelationship}
                onChange={({ target: { value: insRelationship } }) => {
                  handleFormUpdate({
                    insRelationship,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputImage
                value={formState.insCardFront}
                onChange={(insCardFront: File) =>
                  handleFormUpdate({ insCardFront })
                }
                id="insurance-card-front"
                placeholder="Insurance Card Front"
                showOpenImageInNewTabButton={false}
                imageStyle={{
                  width: "100%",
                  maxHeight: 200,
                  minHeight: 100,
                  objectFit: "contain",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputImage
                value={formState.insCardBack}
                onChange={(insCardBack: File) =>
                  handleFormUpdate({ insCardBack })
                }
                id="insurance-card-back"
                placeholder="Insurance Card Back"
                showOpenImageInNewTabButton={false}
                imageStyle={{
                  width: "100%",
                  maxHeight: 200,
                  minHeight: 100,
                  objectFit: "contain",
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Address</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address1"
            label="Address 1"
            onChange={({ target: { value: address1 } }) => {
              handleFormUpdate({
                address1,
              });
            }}
            value={formState.address1}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            label="Address 2"
            onChange={({ target: { value: address2 } }) => {
              handleFormUpdate({
                address2,
              });
            }}
            value={formState.address2}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="city"
            label="City"
            onChange={({ target: { value: city } }) =>
              handleFormUpdate({ city })
            }
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="state"
            label="State"
            onChange={({ target: { value: state } }) => {
              handleFormUpdate({
                state,
              });
            }}
            value={formState.state}
            defaultValue="LA"
            required
            select
          >
            {states.map(({ name, id }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="zipCode"
            label="Zip Code"
            onChange={({ target: { value: zip } }) => {
              handleFormUpdate({
                zip,
              });
            }}
            value={formState.zip}
            required
          />
        </Grid>
        <Grid item xs={12}>
          {user?.roles?.includes("relief_admin") && (
            <DatetimeInput
              label="Appointment Time"
              value={formState.appointmentTime}
              onChange={(appointmentTime: Date | null) =>
                handleFormUpdate({ appointmentTime })
              }
            />
          )}
        </Grid>
      </Grid>
    </FormModal>
  );
};
