import { EventParticipant } from "@relieftelemed/platform";
import { assign } from "xstate";

import {
  UPDATE_TESTING_INFO,
  SET_PARTICIPANT_ID,
  PARTIAL_UPDATE_PARTICIPANT,
  UPDATE_VACCINE_INFO,
  SET_SELECTED_LOCATION,
} from "./constants";
import {
  UpdateTestInfoEvent,
  PartialUpdateParticipantEvent,
  CheckInContext,
  SetSelectedLocationEvent,
  CheckInEvents,
  SetParticipantEvent,
  UpdateVaccineInfoEvent,
  isDoneEvent,
} from "./types";

export const setParticipantId = assign<CheckInContext, CheckInEvents>({
  participantId: (context, event) =>
    event.type === SET_PARTICIPANT_ID
      ? (event as SetParticipantEvent).participantId
      : context.participantId,
});

const extractLocationIdFromParticipant = (participant: EventParticipant) =>
  typeof participant?.checkInLocation === "string"
    ? participant?.checkInLocation
    : participant?.checkInLocation?.id;

export const assignParticipant = assign<CheckInContext, CheckInEvents>({
  participant: (context, event) =>
    isDoneEvent(event) ? event.data : context.participant,
  checkInLocation: (context, event) =>
    isDoneEvent(event)
      ? extractLocationIdFromParticipant(event.data)
      : context.checkInLocation,
});

export const updatePatientVaccinationInfo = assign<
  CheckInContext,
  CheckInEvents
>({
  vaccineInfo: (context, event) =>
    event.type === UPDATE_VACCINE_INFO
      ? (event as UpdateVaccineInfoEvent).vaccineInfo
      : context.vaccineInfo,
});

export const setSelectedLocation = assign<CheckInContext, CheckInEvents>({
  checkInLocation: (context, event) =>
    event.type === SET_SELECTED_LOCATION
      ? (event as SetSelectedLocationEvent).checkInLocation
      : context.checkInLocation,
});

export const partialUpdateParticipant = assign<CheckInContext, CheckInEvents>({
  participant: (context, event) =>
    event.type === PARTIAL_UPDATE_PARTICIPANT
      ? ({
          ...(context.participant || {}),
          ...(event as PartialUpdateParticipantEvent).participant,
        } as EventParticipant)
      : context.participant,
});

export const updateTestInfo = assign<CheckInContext, CheckInEvents>({
  testingInfo: (context, event) =>
    event.type === UPDATE_TESTING_INFO
      ? (event as UpdateTestInfoEvent).testingInfo
      : context.testingInfo,
});
