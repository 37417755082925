import { differenceInYears } from "date-fns";
import { formatWithOptions } from "date-fns/fp";

import { renderNumber } from "./number";

const dateFormatter = Intl.DateTimeFormat("en-US");

const datetimeFormatter = Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: undefined,
});

export const renderDate = (date: Date) => {
  return dateFormatter.format(date);
};

export const renderTime = formatWithOptions({}, "h:mm b");

export const renderDateYearFirst = formatWithOptions({}, "yyyy-MM-dd");

export const renderDateTimeYearFirst = formatWithOptions(
  {},
  "yyyy-MM-dd hh:mm",
);

export const renderDatetime = (date: Date) => datetimeFormatter.format(date);

export const renderAge = (date: Date) => {
  const years = differenceInYears(new Date(), date);

  return renderNumber(years);
};

export const datetimeFormatterLocalTime = Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export function toIsoStringNoSeconds(date: Date) {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  function pad(num: any) {
    return (num < 10 ? "0" : "") + num;
  }

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate(),
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
}
