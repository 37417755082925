import "typeface-roboto";
import { inspect } from "@xstate/inspect";
import React from "react";
import ReactDOM from "react-dom";

import { App } from "./app";
import { registerWebVitals } from "./core";
import { Routes } from "./routes";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line global-require
  require("whatwg-fetch"); // eslint-disable-line import/no-extraneous-dependencies
}

if (process.env.NODE_ENV === "development") {
  inspect({
    // options
    // url: 'https://statecharts.io/inspect', // (default)
    url: "https://stately.ai/viz?inspect=1",
    iframe: false, // open in new window
  });
}

// Start New Relic APM
if (process.env.NODE_ENV === "production") {
  const script = document.createElement("script");
  script.src = `${process.env.PUBLIC_URL}/assets/newrelic.js`;
  script.async = true;
  document.head.appendChild(script);
}

ReactDOM.render(
  <App>
    <Routes />
  </App>,
  document.getElementById("root"),
);

registerWebVitals();
