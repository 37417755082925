import React, { FunctionComponent } from "react";

import { createTabContextProvider } from "../../components";

import { TabContext } from "./tab-context";
import { useTabs } from "./use-tabs";

export const CheckinTabContextProvider = createTabContextProvider(TabContext);

export const TabContextProvider: FunctionComponent = ({ children }) => {
  const tabsToShow = useTabs();

  return (
    <CheckinTabContextProvider tabs={tabsToShow}>
      {children}
    </CheckinTabContextProvider>
  );
};
