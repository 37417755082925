import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, ReactNode } from "react";

interface DashboardCardProps {
  action?: ReactNode;
  className?: string;
  title: string;
}

const useDashboardCardStyles = makeStyles((theme) => ({
  root: {
    minHeight: 180,
    height: "100%",
  },
  content: {
    padding: theme.spacing(2),
  },
}));

export const DashboardCard: FunctionComponent<DashboardCardProps> = ({
  action,
  children,
  className,
  title,
}) => {
  const classes = useDashboardCardStyles();

  const cardClass = className ? ` ${className}` : "";

  return (
    <Card className={`${classes.root} dashboard_card`} elevation={1}>
      <CardHeader
        action={action}
        title={title}
        titleTypographyProps={{ variant: "h2", component: "div" }}
      />
      <CardContent className={`${classes.content}${cardClass}`}>
        {children}
      </CardContent>
    </Card>
  );
};
