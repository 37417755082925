import { useEffect, useState } from "react";

import { useCheckDymoService } from "./use-check-dymo-service";

type Printer = dymo.label.framework.PrinterInfo;

export const useDymoFetchPrinters = (printerType = "LabelWriterPrinter") => {
  const isReady = useCheckDymoService();
  const [availablePrinters, setPrinters] = useState<Printer[]>([]);

  // Fetch printers when isReady
  useEffect(() => {
    if (isReady) {
      const printers = window.dymo.label.framework
        .getPrinters()
        .filter((printer) => {
          if (printer.printerType !== printerType) {
            return false;
          }

          return printer.isConnected;
        });
      setPrinters(printers);
    }
  }, [isReady, printerType]);

  // Clear available printers when isReady is false
  useEffect(() => {
    if (!isReady && availablePrinters.length > 0) {
      setPrinters([]);
    }
  }, [availablePrinters, isReady]);

  return availablePrinters;
};
