import { State } from "xstate";

import { ApplicationContext, GLOBAL_EVENT } from "./types";

export const selectAllEvents = (state: State<ApplicationContext>) =>
  state.context.events;

export const selectCurrentEvent = (state: State<ApplicationContext>) =>
  state.context.currentEvent;

export const selectCurrentLocation = (state: State<ApplicationContext>) =>
  state.context.currentLocation;

export const selectCurrentOrganization = (state: State<ApplicationContext>) =>
  state.context.currentOrg;

export const selectDateFilter = (state: State<ApplicationContext>) =>
  state.context.dateFilter;

export const selectEvents = (state: State<ApplicationContext>) =>
  state.context.orgEvents;

export const selectIsGlobalEvent = (state: State<ApplicationContext>) =>
  state.context.currentEvent?.id === GLOBAL_EVENT;

export const selectLocations = (state: State<ApplicationContext>) =>
  state.context.locations;

export const selectNameFilter = (state: State<ApplicationContext>) =>
  state.context.nameFilter;

export const selectOrganizations = (state: State<ApplicationContext>) =>
  state.context.organizations;

export const selectUser = (state: State<ApplicationContext>) =>
  state.context.user;

export const selectEventTypes = (state: State<ApplicationContext>) =>
  state.context.eventTypes ?? [];
