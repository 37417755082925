import {
  Accordion,
  AccordionSummary,
  IconButton,
  AccordionActions,
  Typography,
  AccordionDetails,
  Grid,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import {
  ExpandMore,
  CheckCircleOutline,
  Edit,
  Cancel,
  CancelOutlined,
} from "@material-ui/icons";
import {
  PatientVaccination,
  useGetVaccinations,
} from "@relieftelemed/platform";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useCallback,
  useMemo,
} from "react";

import { TabPanel } from "../../../components";
import { renderDate } from "../../../helpers";
import { useCheckinContext } from "../context";
import { CheckinTabs } from "../types";

import { CovidVaccinationForm } from "./covid-vaccination";

function testForCorrectDatetimeString(input: string) {
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}\s(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/i;
  return regex.test(input);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }),
);

export const AccordionVaccinationReadOnlyDetails: FunctionComponent<
  Partial<PatientVaccination>
> = ({
  id,
  vaccineLot,
  vaccineSeries,
  administeredDate,
  linksReported,
  shotPlacement,
  dose,
  doseUnit,
  administeredDateString,
  anatomicalRoute,
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography className={classes.heading}>Vaccine Lot</Typography>
        <Typography className={classes.secondaryHeading}>
          {vaccineLot}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Vaccine Series</Typography>
        <Typography className={classes.secondaryHeading}>
          {vaccineSeries}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Dose</Typography>
        <Typography className={classes.secondaryHeading}>{dose}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Dose Unit</Typography>
        <Typography className={classes.secondaryHeading}>{doseUnit}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.heading}>AnatomicalRoute</Typography>
        <Typography className={classes.secondaryHeading}>
          {anatomicalRoute}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Shot Placement</Typography>
        <Typography className={classes.secondaryHeading}>
          {shotPlacement}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.heading}>Links Reported</Typography>
        <Typography className={classes.secondaryHeading}>
          {linksReported ? <CheckCircleOutline /> : <CancelOutlined />}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const AccordionVaccinationBody: FunctionComponent<PatientVaccination> = ({
  id,
  vaccineLot,
  vaccineSeries,
  administeredDate,
  linksReported,
  shotPlacement,
  attmidCode,
  vaccineId,
  dose,
  doseUnit,
  administeredDateString,
  anatomicalRoute,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const { onUpdateVaccinationInfo } = useCheckinContext();
  // const { vaccinationInfo, updateVaccinationInfo } = useChecklistContext();
  const handleEdit = () => {
    if (!isEditing) {
      onUpdateVaccinationInfo({
        vaccineId: vaccineId!,
        vaccineLot: `${vaccineLot}`,
        vaccineSeries: `${vaccineSeries}`,
        linksReady: linksReported!,
        patientVaccinationId: id,
        shotPlacement,
        attmidCode,
        dose: `${dose}`,
        doseUnit: `${doseUnit}`,
        administeredDateString: `${administeredDateString}`,
        anatomicalRoute: `${anatomicalRoute}`,
      });
    }
    setIsEditing(!isEditing);
  };
  return (
    <>
      <AccordionDetails>
        {isEditing ? (
          <CovidVaccinationForm />
        ) : (
          <AccordionVaccinationReadOnlyDetails
            administeredDate={administeredDate}
            linksReported={linksReported}
            shotPlacement={shotPlacement}
            vaccineId={id}
            vaccineLot={vaccineLot}
            vaccineSeries={vaccineSeries}
            dose={dose}
            doseUnit={doseUnit}
            administeredDateString={administeredDateString}
            anatomicalRoute={anatomicalRoute}
          />
        )}
      </AccordionDetails>
      <AccordionActions>
        {linksReported !== true ? (
          <IconButton onClick={handleEdit} size="small">
            <Edit />
          </IconButton>
        ) : null}
      </AccordionActions>
    </>
  );
};

export const Panel: FunctionComponent = () => {
  const {
    data: vaccines,
    isLoading: isLoadingVaccinations,
  } = useGetVaccinations();
  const vaccineNameMap: Record<string, string> = useMemo(() => {
    return vaccines?.reduce(
      (acc, vac) => ({ ...acc, [vac.id]: vac.manufacturer }),
      {},
    ) as Record<string, string>;
  }, [vaccines]);

  const vaccineIdToName = useCallback(
    (vaccineId: string): string => {
      if (isLoadingVaccinations) {
        return "loading...";
      }
      return ((vaccineNameMap[vaccineId] as unknown) as string) || "unknown";
    },
    [isLoadingVaccinations, vaccineNameMap],
  );
  const { patientVaccinations } = useCheckinContext();
  const classes = useStyles();

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange = (vaccineId: string) => (
    event: ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? vaccineId : false);
  };
  return (
    <TabPanel name={CheckinTabs.QUESTIONNAIRE}>
      {patientVaccinations?.map((vaccination: PatientVaccination) => {
        return (
          <Accordion
            expanded={expanded === vaccination.id}
            onChange={handleChange(vaccination.id)}
            key={vaccination.id}
          >
            <AccordionSummary expandIcon={<ExpandMore />} id={vaccination.id}>
              <Typography variant="overline">{`Vaccine ${vaccineIdToName(
                vaccination.vaccineId,
              )} ${
                vaccination.administeredDate == null
                  ? "unknown"
                  : vaccination.administeredDate
              }`}</Typography>
            </AccordionSummary>
            <AccordionVaccinationBody {...vaccination} />
          </Accordion>
        );
      })}
      <Accordion
        expanded={expanded === "new-exisisting"}
        onChange={handleChange("new-exisisting")}
      >
        <AccordionSummary expandIcon={<ExpandMore />} id="new-exisisting">
          <Typography variant="overline">New Vaccination</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CovidVaccinationForm />
        </AccordionDetails>
      </Accordion>
    </TabPanel>
  );
};
