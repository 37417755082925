import { createContext } from "react";

export enum FrameworkStatus {
  error = "error",
  initializing = "initializing",
  loaded = "loaded",
  loading = "loading",
  unloaded = "unloaded",
  unsupported = "unsupported",
}

export const DymoFrameworkContext = createContext(FrameworkStatus.unloaded);
