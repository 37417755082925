import {
  FormControlLabel,
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import {
  useGetVaccinations,
  LastVaccinationData,
} from "@relieftelemed/platform";
import React, {
  FunctionComponent,
  ChangeEvent,
  useMemo,
  useState,
} from "react";

import { DatetimeInput } from "../../../components";
import { useCheckinContext } from "../context";
import { valueFromTarget, renderDateTimeYearFirst } from "../helpers";
import { VaccineInfo } from "../state";
import { ValueSetter } from "../types";

import { validateDatetimeString } from "./validators";

export const CovidVaccinationForm: FunctionComponent = () => {
  const {
    data: vaccines,
    isLoading: isLoadingVaccinations,
  } = useGetVaccinations();

  const {
    onUpdateVaccinationInfo,
    vaccineInfo = {
      vaccineLot: "222",
      vaccineId: "",
      vaccineSeries: "",
      linksReady: false,
      shotPlacement: "",
      attmidCode: "",
      dose: "0",
      doseUnit: "mL",
      administeredDateString: renderDateTimeYearFirst(new Date()),
      anatomicalRoute: "",
    },
  } = useCheckinContext();

  type FormProps = { vaccinationTime: Date | null };

  const updateVaccinationInfo = (props: Partial<VaccineInfo>) =>
    onUpdateVaccinationInfo({ ...vaccineInfo, ...props });

  const changeLotNumber = (lotNumber: string) => {
    updateVaccinationInfo({
      vaccineLot: lotNumber,
    });
  };

  const changeVaccineId = (vaccineId: string) => {
    updateVaccinationInfo({
      vaccineId,
    });
  };

  const changeVaccineSeries = (vaccineSeries: string) => {
    updateVaccinationInfo({
      vaccineSeries,
    });
  };

  const changeAttmidCode = (attmidCode: string) => {
    updateVaccinationInfo({
      attmidCode,
    });
  };

  const changeShotPlacement = (shotPlacement: string) => {
    updateVaccinationInfo({
      shotPlacement,
    });
  };

  const changeLinksReady = () => {
    updateVaccinationInfo({
      linksReady: !vaccineInfo.linksReady,
    });
  };

  const changedose = (dose: string) => {
    updateVaccinationInfo({
      dose,
    });
  };

  const changedoseUnit = (doseUnit: string) => {
    updateVaccinationInfo({
      doseUnit,
    });
  };

  const changeAdministeredDateString = (date: Date | null) => {
    if (date == null) {
      updateVaccinationInfo({ administeredDateString: undefined });
    } else {
      updateVaccinationInfo({
        administeredDateString: renderDateTimeYearFirst(date),
      });
    }
  };

  const changeAnatomicalRoute = (anatomicalRoute: string) => {
    updateVaccinationInfo({
      anatomicalRoute,
    });
  };

  const availableSeries = useMemo(() => {
    const selectedVaccine = vaccines?.find(
      (vaccine) => vaccine.id === vaccineInfo!.vaccineId,
    );
    return Array.from({ length: selectedVaccine?.numberRequired ?? 0 }).map(
      (_, index) => (
        <MenuItem key={`${index + 1}`} value={`${index + 1}`}>
          {index + 1}
        </MenuItem>
      ),
    );
  }, [vaccines, vaccineInfo]);

  return (
    <>
      <DialogContentText variant="h3">Vaccination Form</DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Vaccine Manufacturer"
            onChange={valueFromTarget(changeVaccineId)}
            value={vaccineInfo!.vaccineId}
            InputProps={{
              endAdornment: isLoadingVaccinations ? (
                <div style={{ marginRight: "1.5em" }}>
                  <CircularProgress color="inherit" size={20} />
                </div>
              ) : null,
            }}
            select
          >
            {vaccines?.map((vaccine) => (
              <MenuItem key={vaccine.id} value={vaccine.id}>
                {`${vaccine.manufacturer} (${vaccine.name})`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="lot-number"
            autoComplete="on"
            label="Lot Number"
            onChange={valueFromTarget(changeLotNumber)}
            value={vaccineInfo.vaccineLot}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Shot Series"
            helperText="Select the shot series"
            onChange={valueFromTarget(changeVaccineSeries)}
            value={vaccineInfo.vaccineSeries}
            select
          >
            {availableSeries}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Attmid Code"
            helperText="Attmid Code"
            onChange={valueFromTarget(changeAttmidCode)}
            value={vaccineInfo.attmidCode}
          >
            {availableSeries}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Dose"
            helperText="Select the vaccine dose"
            onChange={valueFromTarget(changedose)}
            value={vaccineInfo.dose}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Dose Unit"
            onChange={valueFromTarget(changedoseUnit)}
            value={vaccineInfo!.doseUnit}
            select
          >
            <MenuItem key="mL" value="mL">
              mL
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Anatomical Route"
            onChange={valueFromTarget(changeAnatomicalRoute)}
            value={vaccineInfo!.anatomicalRoute}
            select
          >
            <MenuItem key="intramuscular" value="intramuscular">
              Intramuscular
            </MenuItem>
            <MenuItem key="intradermal" value="intradermal">
              Intradermal
            </MenuItem>
            <MenuItem key="subcutaneous" value="subcutaneous">
              Subcutaneous
            </MenuItem>
            <MenuItem key="oral" value="oral">
              Oral
            </MenuItem>
            <MenuItem key="nasal" value="nasal">
              Nasal
            </MenuItem>
            <MenuItem key="other" value="other">
              Other
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Shot Placement (if Applicable)"
            onChange={valueFromTarget(changeShotPlacement)}
            value={vaccineInfo!.shotPlacement}
            select
          >
            <MenuItem key="not_applicable" value="not_applicable">
              Not Applicable
            </MenuItem>
            <MenuItem key="left_arm" value="left_arm">
              Left Arm
            </MenuItem>
            <MenuItem key="right_arm" value="right_arm">
              Right Arm
            </MenuItem>
            <MenuItem key="right_thigh" value="right_thigh">
              Right Thigh
            </MenuItem>
            <MenuItem key="left_thigh" value="left_thigh">
              Left Thigh
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <DatetimeInput
            label="Administered Date"
            value={vaccineInfo.administeredDateString}
            onChange={(vaccinationTime) => {
              changeAdministeredDateString(vaccinationTime);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {validateDatetimeString(vaccineInfo.administeredDateString)
            ? "Datetime string is valid"
            : "INVALID DATETIME STRING. SUBMIT DISABLED UNTIL VALID DATETIME STRING IS ENTERED"}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="Links Ready?"
            control={
              <Checkbox
                checked={vaccineInfo.linksReady}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": "antibody-disclosure-label" }}
                onChange={changeLinksReady}
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
