import {
  Assignment,
  CheckBox,
  Feedback,
  LocalShipping,
  Timeline,
} from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import { NavItem } from "../../components";
import {
  FLAG_COLLECT_SPECIMEN,
  PATH_ACTION_PLANS,
  PATH_COLLECTION,
  PATH_POSITIVES,
  PATH_RESULTS,
  PATH_STAGES,
} from "../../constants";
import { useCanSeeGlobal } from "../../hooks";
import { useAppService } from "../../state";

export const TestingNavigation: FunctionComponent = () => {
  const canSeeGlobal = useCanSeeGlobal(false);
  const canSeeGlobalNurse = useCanSeeGlobal(true);
  const [{ currentEvent }] = useAppService();

  const isNurse = canSeeGlobalNurse && !canSeeGlobal;

  return (
    <>
      {/* <NavItem icon={Timeline} link={PATH_STAGES} text="Protocol Stages" /> */}
      <NavItem
        isVisible={currentEvent?.showResults || canSeeGlobalNurse}
        icon={Assignment}
        link={PATH_RESULTS}
        text="Results"
        id="results_link"
      />
      <NavItem
        isVisible={canSeeGlobal}
        icon={CheckBox}
        link={PATH_POSITIVES}
        text="PCR List"
        id="pcr_list_link"
      />
      <NavItem
        isVisible={currentEvent?.showActionPlans && !isNurse}
        icon={Feedback}
        link={PATH_ACTION_PLANS}
        text="Action Plans"
        id="action_plans_link"
      />
      <NavItem
        // flag={FLAG_COLLECT_SPECIMEN}
        icon={LocalShipping}
        link={PATH_COLLECTION}
        text="Specimen Collection"
        id="specimen_collection_link"
      />
    </>
  );
};
