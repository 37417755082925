import { useMemo } from "react";

import { EventParticipant } from "../core";
import { isInTimeframe } from "../helpers";
import { useAppService } from "../state";

export const useParticipantsCheckedInAt = (
  participants: EventParticipant[],
) => {
  const [{ timeframe }] = useAppService();

  const participantsInTimeframe = useMemo(() => {
    return participants.filter((participant) => {
      return isInTimeframe(participant.checkedInAt, timeframe);
    });
  }, [participants, timeframe]);

  return participantsInTimeframe;
};
