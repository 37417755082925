import { EventParticipant } from "@relieftelemed/platform";
import React, { FunctionComponent, useMemo } from "react";

import { useIsVaccinationEvent } from "../../../hooks";
import { useCheckinContext } from "../context";
import { PrimaryAction } from "../primary-action";
import { useTabContext } from "../tab-context";

import { canSubmitForTesting } from "./validators";

export const PrimaryActionWrapper: FunctionComponent<{ label?: string }> = ({
  label,
}) => {
  const { update, finish, testingInfo } = useCheckinContext();
  const { next } = useTabContext();
  const isVaccinationEvent = useIsVaccinationEvent();

  const handleClick = () => {
    const extraDetails: Partial<EventParticipant> = {
      orgConsent: true,
    };
    if (isVaccinationEvent) {
      update(extraDetails);
      next();
    }
    finish();
  };

  return (
    <PrimaryAction
      disabled={testingInfo == null || !canSubmitForTesting(testingInfo!)}
      onClick={handleClick}
      label={label}
    />
  );
};
