import { validateMinLength } from "@relieftelemed/platform";

import { VaccineInfo } from "../state";

function useRegex(input: string) {
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}\s(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/i;
  return regex.test(input);
}

export function validateDatetimeString(input: string) {
  /* validateDatetimestring function validates datetime strings of the fomat YYY-MM-DD HH-mm */
  const regex = /^[0-2][0-9]{3}-[0-1][0-9]-[0-3][0-9] (0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/i;
  return regex.test(input);
}

export const canSubmitForVaccination = (info: VaccineInfo) => {
  const { vaccineId, vaccineSeries, administeredDateString } = info;

  const hasCorrectDatetimeString = validateDatetimeString(
    administeredDateString,
  );
  const hasVaccineId = validateMinLength(vaccineId);
  const hasVaccineSeries =
    typeof vaccineSeries === "string" && vaccineSeries !== "";

  return hasVaccineId && hasVaccineSeries && hasCorrectDatetimeString;
};
