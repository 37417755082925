import { useAppService } from "../state";

import { useIsEventVaccination } from "./use-is-event-vaccination";

export const useIsVaccinationEvent = () => {
  const [{ currentEvent }] = useAppService();
  const value = useIsEventVaccination(currentEvent);

  return value;
};
