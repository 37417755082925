export const lastN = <T>(n: number, elemArray: T[]): T =>
  elemArray.slice(-n)[0];

export const notEmptyElementFilter = <TValue>(
  value: TValue | null | undefined,
): value is TValue => value !== null && value !== undefined;

export const capitalize = (word: string): string =>
  word.length > 0 ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : "";

export const snakeToCamelCase = (aString: string) => {
  const [firstWord, ...otherWords]: string[] = aString.split("_");
  return [firstWord, ...otherWords.map(capitalize)].join("");
};

type MapFunc<T = any> = (val: T, index?: number, arr?: T[]) => T;

export const groupBy = <T = any>(arr: T[], fn: MapFunc<T> | string) =>
  arr
    .map(typeof fn === "string" ? (val: any) => val[fn] : fn)
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i]);
      return acc;
    }, {});

export const isNil = (a: any): boolean => a == null;

export const applyIfNotNull = <T = any, R = any>(
  func: (arg: T) => R,
  value: T | null | undefined,
  defaultValue: R | null = null,
): R | null => (isNil(value) ? defaultValue : func(value!));

export const prop = (propName: string) => (objectMap: Record<string, any>) =>
  objectMap[propName];
