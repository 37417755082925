import {
  AppBar,
  Chip,
  Drawer,
  DrawerProps,
  Hidden,
  IconButton,
  makeStyles,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import clsx from "clsx";
import React, { FunctionComponent, ReactNode, SyntheticEvent } from "react";

import { PageTitle } from "../components";
import {
  drawerWidth,
  narrowDrawerWidth,
  nightBlue,
  wideDrawerWidth,
} from "../styles";

export enum LayoutWidth {
  default = "default",
  narrow = "narrow",
  wide = "wide",
}

export interface LayoutProps {
  appBarLeft?: ReactNode;
  appBarRight?: ReactNode;
  children: ReactNode;
  dark?: boolean;
  fullHeight?: boolean;
  padded?: boolean;
  showAppBar?: boolean;
  sidebar?: ReactNode;
  SidebarProps?: DrawerProps;
  title?: string;
  srTitle?: string;
  width?: LayoutWidth;
}

const useLayoutStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.background.default,
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  appBarNarrow: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${narrowDrawerWidth}px)`,
      marginLeft: narrowDrawerWidth,
    },
  },
  appBarWide: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${wideDrawerWidth}px)`,
      marginLeft: wideDrawerWidth,
    },
  },
  title: {
    padding: "0 8px 0 8px",
  },
  content: {
    backgroundColor: theme.palette.background.default,
    padding: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth,
    },
  },
  contentNarrow: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: narrowDrawerWidth,
    },
  },
  contentWide: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: wideDrawerWidth,
    },
  },
  drawer: {
    background: theme.palette.common.white,
    width: drawerWidth,
    overflowX: "hidden",
  },
  drawerDark: {
    background: nightBlue,
  },
  drawerNarrow: {
    width: narrowDrawerWidth,
  },
  drawerWide: {
    width: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: wideDrawerWidth,
    },
  },
  fullHeight: {
    height: "calc(100vh - 64px)",
    [theme.breakpoints.up("lg")]: {
      height: "100vh",
    },
  },
  fullHeightPadded: {
    [theme.breakpoints.up("lg")]: {
      height: `calc(100vh - ${theme.spacing(6)}px)`,
    },
  },
  padded: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
  },
  showAppBar: {
    marginTop: theme.spacing(2),
  },
}));

export const Layout: FunctionComponent<LayoutProps> = ({
  appBarLeft,
  appBarRight,
  children,
  dark = false,
  fullHeight = false,
  padded = false,
  showAppBar = false,
  sidebar,
  SidebarProps,
  title = "",
  srTitle,
  width = LayoutWidth.default,
}) => {
  const classes = useLayoutStyles();

  const [isOpen, setState] = React.useState(false);

  const toggleDrawer = (event: SyntheticEvent<{}>) => {
    if (event && event.type === "keydown") {
      return;
    }

    setState(!isOpen);
  };

  const drawerClasses = clsx(classes.drawer, {
    [classes.drawerNarrow]: width === LayoutWidth.narrow,
    [classes.drawerWide]: width === LayoutWidth.wide,
    [classes.drawerDark]: dark,
  });

  let srPageTitle = null;
  if (srTitle) {
    srPageTitle = (
      <Typography variant="srOnly" component="h1">
        {srTitle}
      </Typography>
    );
  }

  let fullAppBar = null;
  if (showAppBar) {
    fullAppBar = (
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarNarrow]: width === LayoutWidth.narrow,
          [classes.appBarWide]: width === LayoutWidth.wide,
        })}
        position="sticky"
      >
        <Toolbar disableGutters={Boolean(appBarLeft)}>
          {appBarLeft}
          <PageTitle title={title} />
          {srPageTitle}
          <div className={classes.title}>
            <Chip label={title} color="primary" />
          </div>
          {appBarRight}
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <>
      <Hidden lgUp>
        <AppBar
          className={classes.appBar}
          color="transparent"
          position="static"
        >
          <Toolbar>
            <IconButton
              id="main-menu-toggler"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <Menu />
            </IconButton>
            <PageTitle title={title} />
            {srPageTitle}
            <Typography variant="h2" component="h1" className={classes.title}>
              {title}
            </Typography>
            {appBarLeft}
            {appBarRight}
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden mdDown>{fullAppBar}</Hidden>

      <main
        className={clsx(classes.content, {
          [classes.contentNarrow]: width === LayoutWidth.narrow,
          [classes.contentWide]: width === LayoutWidth.wide,
          [classes.fullHeight]: fullHeight,
          [classes.padded]: padded,
          [classes.fullHeightPadded]: fullHeight && padded,
          [classes.showAppBar]: showAppBar,
        })}
      >
        {children}
      </main>
      <Hidden lgUp>
        <SwipeableDrawer
          {...SidebarProps}
          anchor="left"
          classes={{
            paper: drawerClasses,
          }}
          open={isOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          {sidebar}
        </SwipeableDrawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          {...SidebarProps}
          anchor="left"
          variant="permanent"
          classes={{
            root: drawerClasses,
            paper: drawerClasses,
          }}
        >
          {sidebar}
        </Drawer>
      </Hidden>
    </>
  );
};
