import { useEffect, useState } from "react";
import { usePreviousDistinct } from "react-use";
import { read, utils } from "xlsx";

const isArrayBuffer = (data: string | ArrayBuffer): data is ArrayBuffer => {
  return (data as ArrayBuffer).byteLength > 0;
};

export function useSpreadsheet<T = unknown>(data?: string | ArrayBuffer) {
  const lastData = usePreviousDistinct(data);
  const [sheetData, setSheetData] = useState<T[] | undefined>(undefined);

  useEffect(() => {
    if (data === lastData) {
      return;
    }

    // No file data, so clear out existing data
    if (!data) {
      setSheetData(undefined);
      return;
    }

    // Process file into JSON
    const workbook = read(data, {
      cellDates: true,
      type: isArrayBuffer(data) ? "array" : "binary",
    });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    const json = utils.sheet_to_json<T>(worksheet);

    setSheetData(json);
  }, [data, lastData]);

  return sheetData;
}
