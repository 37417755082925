import { DialogContentText, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { useAppService } from "../../state";
// import { SelectParticipant } from "../select";

import { useCheckinContext } from "./context";
import { SelectLocation } from "./select-location";

export const FormHeader: FunctionComponent = () => {
  const [{ locations }] = useAppService();
  const {
    checkInLocation,
    participant,
    onSelectLocation,
  } = useCheckinContext();

  const locationSelector =
    locations.length > 0 ? (
      <Grid item xs={12} lg={4}>
        <SelectLocation
          value={checkInLocation ?? ""}
          onChange={onSelectLocation}
        />
      </Grid>
    ) : null;

  return (
    <>
      <Grid container justify="flex-start" direction="row" spacing={1}>
        <Grid item>
          <DialogContentText variant="h3">Participant :</DialogContentText>
        </Grid>
        <Grid item style={{ marginTop: "auto", marginBottom: "auto" }}>
          <Typography variant="h3">{participant?.patientName}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {locationSelector}
      </Grid>
    </>
  );
};
