import {
  DialogContentText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Feedback } from "@material-ui/icons";
import React, {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from "react";

import { Button, FormModal } from "../../components";
import { ApiState, Note, useAddVisitNotes } from "../../core";

interface AddActionPlanFormProps {
  onClose?: () => void;
  open?: boolean;
  visit: string;
}

const useVisitModalStyles = makeStyles({
  primaryAction: {
    textAlign: "right",
  },
});

export const AddActionPlanForm: FunctionComponent<AddActionPlanFormProps> = ({
  onClose,
  open = false,
  visit,
}) => {
  const classes = useVisitModalStyles();
  const [note, changeNote] = useState("");
  const { mutate: addVisitsMutate } = useAddVisitNotes();

  const handleType: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    changeNote(event.target.value);
  };

  const handleSubmit = () => {
    addVisitsMutate(
      {
        visit,
        notes: [
          {
            note,
            noteType: "treatment_instructions",
          },
        ],
      },
      {
        onSuccess: () => {
          if (onClose) onClose();
        },
      },
    );
  };

  return (
    <FormModal
      icon={Feedback}
      fullWidth
      onClose={onClose}
      open={open}
      scroll="body"
      title="Add Action Plan"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button onClick={handleSubmit}>Submit</Button>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText variant="h3">Enter an action plan</DialogContentText>
      <TextField
        label="Action Plan"
        helperText="This will be appended to any existing action plan"
        multiline
        value={note}
        onChange={handleType}
      />
    </FormModal>
  );
};
