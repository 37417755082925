import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent } from "react";

import { EventForm } from "./form";

interface CreateEventProps {
  onCancel?: () => void;
  open: boolean;
}

export const CreateEvent: FunctionComponent<CreateEventProps> = ({
  onCancel,
  open,
}) => {
  const flags = useFlags();

  /*   if (!flags.addEvent || !open) {
    return null;
  }
 */
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return <EventForm onClose={handleClose} open={open} />;
};
