import { assign } from "xstate";

import { SucceedEvent } from "../../authentication/state";

import { CREATE } from "./constants";
import {
  RegistrationContext,
  RegistrationEvents,
  isDoneEvent,
  CreateEvent,
} from "./types";

export const clearContext = assign<RegistrationContext, RegistrationEvents>({
  errors: [],
  email: "",
  password: "",
  confirmation: "",
  mobile: "",
  acceptedTerms: false,
});

export const saveError = assign<RegistrationContext, RegistrationEvents>({
  errors: (context, event) =>
    isDoneEvent(event) ? [event.data.toString()] : context.errors,
});

export const saveRegistration = assign<RegistrationContext, RegistrationEvents>(
  {
    acceptedTerms: (_context, event) =>
      event.type !== CREATE ? false : (event as CreateEvent).acceptedTerms,
    confirmation: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).confirmation,
    email: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).email,
    firstName: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).firstName,
    lastName: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).lastName,
    mobile: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).mobile,
    password: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).password,
  },
);

export const saveUser = assign<RegistrationContext, RegistrationEvents>({
  user: (_context, event) =>
    isDoneEvent<SucceedEvent>(event) ? event.data.user : undefined,
  token: (_context, event) =>
    isDoneEvent<SucceedEvent>(event) ? event.data.token : undefined,
});
