import { DialogContentText, Grid, makeStyles } from "@material-ui/core";
import { LockOpen } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";

import { Button, FormModal } from "../../components";
import { useCreateOrganizationMember, ApiState, User } from "../../core";
import { useAppService } from "../../state";
import { SelectUser } from "../../users";
import { SelectOrganization } from "../select";

interface AddContactFormProps {
  onClose?: () => void;
  open?: boolean;
}

const useVisitModalStyles = makeStyles({
  primaryAction: {
    textAlign: "right",
  },
});

export const AddContactForm: FunctionComponent<AddContactFormProps> = ({
  onClose,
  open = false,
}) => {
  const classes = useVisitModalStyles();
  const [{ currentOrganization }] = useAppService();
  const [organization, changeOrg] = useState(currentOrganization?.id ?? "");
  const [user, changeUser] = useState<User>();
  const [params, setParams] = useState<string[]>(["", ""]);
  const {
    mutate: createOrganizationMemberMutate,
  } = useCreateOrganizationMember();

  const handleSubmit = () => {
    createOrganizationMemberMutate(
      {
        organization,
        user: user?.id ?? "",
      },
      {
        onSuccess: () => {
          if (onClose) onClose();
        },
      },
    );
  };

  return (
    <FormModal
      icon={LockOpen}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      scroll="body"
      title="Add Organization Contact"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button onClick={handleSubmit}>Submit</Button>
          </Grid>
        </Grid>
      }
    >
      <SelectOrganization value={organization} onChange={changeOrg} />
      <DialogContentText variant="h3">Select a user</DialogContentText>
      <SelectUser value={user} onChange={changeUser} />
    </FormModal>
  );
};
