const getEnv = (key: string, defaultValue: string = ""): string => {
  let value = defaultValue;

  if (process.env[key]) {
    value = process.env[key] as string;
  }

  return value;
};

export const platformUrl = getEnv(
  "REACT_APP_PLATFORM_URL",
  "https://production.relieftelemed.com/relief_api/v1/",
);

export const appId = getEnv(
  "REACT_APP_ID",
  "7243756a-2fc9-4c3a-9f9f-c8d4da59350f",
);

export const flagProviderId = getEnv(
  "REACT_APP_FLAG_PROVIDER",
  "5efa38bd75baf80bbab4d5eb",
);

export const analyticsProjectToken = getEnv(
  "REACT_APP_ANALYTICS_PROVIDER",
  "c856eee09a8386418e0130f57ef2842b",
);

export const defaultPromoClinic = getEnv(
  "REACT_APP_DEFAULT_PROMO_CLINIC",
  "f4eb2f64-ab6b-4932-bae4-39d3c806b6a4",
);

export const metabaseSecretKey = getEnv(
  "REACT_METABASE_SECRET_KEY",
  "d10e2e54c61e36551e032e5e2798e96afeecf26ff810d5519c77edc749cdba47",
);

export const metabaseDashboardId = getEnv("REACT_METABASE_DASHBOARD_ID", "19");

const enableDebug = getEnv("REACT_APP_DEBUG", "true");

export const minLogLevel = enableDebug === "true" ? "debug" : "warn";

export const emptyValue = "--";

export const validationDelay = 600;

export const pageTitle = "COVID Mitigation Platform";

export const createPageTitle = (subtitle?: string): string => {
  let text = pageTitle;

  if (subtitle) {
    text += ` - ${subtitle}`;
  }

  return text;
};
