import { Machine } from "xstate";

import {
  setParticipantId,
  assignParticipant,
  updatePatientVaccinationInfo,
  setSelectedLocation,
  partialUpdateParticipant,
  updateTestInfo,
} from "./actions";
import {
  CHECK_IN_PATIENT,
  PATIENT_SELECTION,
  FETCH_FOR_PARTICIPANT_DETAILS,
  INIT,
  PERSIST_CHANGES,
  DONE,
} from "./constants";
import { hasParticipantId, validPatientSelection } from "./guards";
import {
  fetchForParticipantDetails,
  persistVaccinationChanges,
} from "./promises";
import { CheckInContext, CheckInSchema, CheckInEvents } from "./types";

export const checkInStateMachine = Machine<
  CheckInContext,
  CheckInSchema,
  CheckInEvents
>(
  {
    id: "checkinapplication",
    initial: INIT,
    context: {
      participantId: undefined,
      participant: undefined,
      vaccineInfo: undefined,
      checkInLocation: undefined,
      testingInfo: undefined,
    },
    states: {
      [INIT]: {
        always: [
          { target: FETCH_FOR_PARTICIPANT_DETAILS, cond: "hasParticipantId" },
          { target: CHECK_IN_PATIENT },
        ],
      },
      [CHECK_IN_PATIENT]: {
        on: {
          selectPatient: {
            actions: "setParticipantId",
            target: "fetchForParticipantDetails",
          },
          selectLocation: {
            actions: "setSelectedLocation",
          },
          updateVaccineInfo: {
            actions: "updatePatientVaccinationInfo",
          },
          updateTestingInfo: {
            actions: "updateTestInfo",
          },
          persistVaccineInfoChanges: {
            target: PERSIST_CHANGES,
          },
          partialUpdateParticipant: {
            actions: "partialUpdateParticipant",
          },
        },
      },
      [PATIENT_SELECTION]: {
        on: {
          selectPatient: [
            {
              cond: "validParticipantId",
              actions: "setParticipantId",
              target: "fetchForParticipantDetails",
            },
            {
              target: PATIENT_SELECTION,
            },
          ],
          selectLocation: {
            actions: "setSelectedLocation",
          },
        },
      },
      [FETCH_FOR_PARTICIPANT_DETAILS]: {
        invoke: {
          src: "fetchForParticipantDetails",
          id: "fetch-for-participant-details",
          onError: {
            target: PATIENT_SELECTION,
          },
          onDone: {
            actions: "assignParticipant",
            target: CHECK_IN_PATIENT,
          },
        },
      },
      [PERSIST_CHANGES]: {
        invoke: {
          src: "persistVaccinationChanges",
          id: "persist-vaccination-changes",
          onError: {
            target: PATIENT_SELECTION,
          },
          onDone: {
            actions: "assignParticipant",
            target: DONE,
          },
        },
      },
      [DONE]: {
        type: "final",
      },
    },
  },
  {
    actions: {
      setParticipantId,
      assignParticipant,
      updatePatientVaccinationInfo,
      setSelectedLocation,
      partialUpdateParticipant,
      updateTestInfo,
    },
    guards: {
      hasParticipantId,
      validPatientSelection,
    },
    services: {
      fetchForParticipantDetails,
      persistVaccinationChanges,
    },
  },
);
