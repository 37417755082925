import { useEffect, useState } from "react";

import { useDymoFetchPrinters } from "./dymo";

type Printer = dymo.label.framework.PrinterInfo;

export const useLabelPrinter = () => {
  const [printer, setPrinter] = useState<Printer>();
  const printers = useDymoFetchPrinters();

  useEffect(() => {
    // No printers available
    if (printers.length === 0) {
      // Unset existing printer
      if (printer) {
        setPrinter(undefined);
      }

      return;
    }

    // Current printer still available, so do nothing
    if (
      printer &&
      printers.find((newPrinter) => newPrinter.name === printer.name)
    ) {
      return;
    }

    // Select the first available printer
    setPrinter(printers[0]);
  }, [printer, printers]);

  return printer;
};
