import React, { FunctionComponent } from "react";

import { Dialog } from "./dialog";
import { DialogActions } from "./dialog-actions";
import { DialogContent } from "./dialog-content";
import { DialogTitle } from "./dialog-title";
import { DialogModalProps } from "./types";

export const DialogModal: FunctionComponent<DialogModalProps> = ({
  actions,
  children,
  icon,
  onClose,
  showDividers = false,
  title,
  titleProps,
  ...props
}) => {
  let dialogActions = null;
  if (actions) {
    dialogActions = <DialogActions>{actions}</DialogActions>;
  }

  return (
    <Dialog {...props}>
      <DialogTitle
        icon={icon}
        onClose={onClose}
        title={title}
        {...titleProps}
      />
      <DialogContent showDividers={showDividers}>{children}</DialogContent>
      {dialogActions}
    </Dialog>
  );
};
