import { interpret, Machine, State, actions, sendUpdate } from "xstate";

import { authMachine } from "../authentication/state";
import { AUTHENTICATION_MACHINE, REGISTRATION_MACHINE } from "../constants";
import { logger } from "../core";
import { Timeframe } from "../helpers";
import { registerMachine } from "../registration/state";

import {
  removeUser,
  revokeToken,
  saveEvents,
  saveGlobal,
  saveLocations,
  saveNameFilter,
  saveOrganizations,
  saveParticipants,
  saveUser,
  saveEventTypes,
  setCurrentEvent,
  setDateFilter,
  setCurrentLocation,
  setCurrentOrg,
  setOrgEvents,
  setTimeframe,
  setToken,
  validateCurrentEvent,
  validateCurrentOrg,
  updateUser,
} from "./actions";
import {
  CHANGE_EVENT,
  CHANGE_LOCATION,
  CHANGE_DATE_FILTER,
  CHANGE_NAME_FILTER,
  CHANGE_ORG,
  CHANGE_TIME,
  DONE_FETCHING_DATA,
  FETCH_FOR_EVENT_LOCATIONS,
  END_SESSION,
  FETCH_EVENTS,
  FETCH_FOR_COMMON_DATA,
  FETCHING_ORGANIZATIONS,
  FETCHING_ORGANIZATION_EVENTS,
  INITIAL,
  LOGGED_IN,
  LOGGING_OUT,
  MAYBE_EVENT,
  MAYBE_ORGANIZATION,
  NOT_LOGGED_IN,
  READY,
  TOGGLE_GLOBAL,
  UPDATE_USER,
  FETCHING_EVENT_TYPES,
} from "./constants";
import {
  hasCurrentEvent,
  hasOrgEvents,
  hasSelectedAnOrganization,
} from "./guards";
import { clearSavedState, restoreState, saveState } from "./persistence";
import {
  createLogoutObservable,
  fetchForOrganizationEvents,
  fetchOrganizations,
  fetchLocations,
  fetchForEventTypes,
} from "./promises";
import {
  ApplicationContext,
  ApplicationSchema,
  ApplicationEvents,
} from "./types";

const MINUTE = 60 * 1000;
const REFETCH_DELAY = 5 * MINUTE;

export const appMachine = Machine<
  ApplicationContext,
  ApplicationSchema,
  ApplicationEvents
>(
  {
    id: "application",
    initial: NOT_LOGGED_IN,
    context: {
      events: [],
      nameFilter: "",
      dateFilter: null,
      eventTypes: [],
      organizations: [],
      orgEvents: [],
      locations: [],
      participants: [],
      showGlobal: false,
      timeframe: Timeframe.lifetime,
    },
    states: {
      [NOT_LOGGED_IN]: {
        invoke: [
          {
            id: AUTHENTICATION_MACHINE,
            src: authMachine,
            onDone: {
              target: LOGGED_IN,
              actions: ["saveUser", "setToken"],
            },
          },
          {
            id: REGISTRATION_MACHINE,
            src: registerMachine,
            onDone: {
              target: LOGGED_IN,
              actions: ["saveUser", "setToken"],
            },
          },
        ],
      },
      [LOGGED_IN]: {
        id: "logged_in",
        initial: INITIAL,
        on: {
          [CHANGE_NAME_FILTER]: {
            actions: "saveNameFilter",
          },
          [END_SESSION]: LOGGING_OUT,
          [FETCH_EVENTS]: `.${INITIAL}`,
          [TOGGLE_GLOBAL]: {
            target: `.${INITIAL}`,
            actions: "saveGlobal",
          },
          [UPDATE_USER]: {
            actions: "updateUser",
          },
        },
        states: {
          [INITIAL]: {
            always: FETCH_FOR_COMMON_DATA,
          },
          [FETCH_FOR_COMMON_DATA]: {
            initial: "doFetching",
            states: {
              doFetching: {
                type: "parallel",
                states: {
                  [FETCHING_ORGANIZATIONS]: {
                    initial: "fetching",
                    states: {
                      fetching: {
                        invoke: {
                          src: fetchOrganizations,
                          id: "organizations",
                          onDone: {
                            actions: ["saveOrganizations"],
                            target: "done",
                          },
                        },
                      },
                      done: {
                        type: "final",
                      },
                    },
                    onDone: {
                      actions: (context: any) =>
                        logger.debug("donce fetching for organizations"),
                    },
                  },
                  [FETCHING_EVENT_TYPES]: {
                    initial: "fetching",
                    states: {
                      fetching: {
                        invoke: {
                          src: fetchForEventTypes,
                          id: "eventTypes",
                          onDone: {
                            actions: ["saveEventTypes"],
                            target: "done",
                          },
                        },
                      },
                      done: {
                        type: "final",
                      },
                    },
                    onDone: {
                      actions: (context: any) =>
                        logger.debug("Done on event types"),
                    },
                  },
                },
                onDone: {
                  target: `#logged_in.${MAYBE_ORGANIZATION}`,
                },
              },
            },
          },
          [MAYBE_ORGANIZATION]: {
            entry: ["validateCurrentOrg"],
            always: [
              {
                target: FETCHING_ORGANIZATION_EVENTS,
                cond: {
                  type: "hasSelectedAnOrganization",
                },
              },
              {
                target: READY,
              },
            ],
          },
          [FETCHING_ORGANIZATION_EVENTS]: {
            invoke: {
              src: fetchForOrganizationEvents,
              id: "organizationEvents",
              onDone: {
                actions: ["saveEvents"],
                target: MAYBE_EVENT,
              },
            },
          },
          [FETCH_FOR_EVENT_LOCATIONS]: {
            invoke: {
              src: fetchLocations,
              id: "fetchForEventLocations",
              onDone: {
                actions: ["saveLocations"],
                target: READY,
              },
            },
          },
          [MAYBE_EVENT]: {
            entry: ["validateCurrentEvent"],
            always: FETCH_FOR_EVENT_LOCATIONS,
          },
          [READY]: {
            on: {
              [CHANGE_EVENT]: {
                actions: "setCurrentEvent",
                target: MAYBE_EVENT,
              },
              [CHANGE_LOCATION]: {
                actions: "setCurrentLocation",
              },
              [CHANGE_ORG]: {
                actions: "setCurrentOrg",
                target: MAYBE_ORGANIZATION,
              },
              [CHANGE_TIME]: {
                actions: "setTimeframe",
              },
              [CHANGE_DATE_FILTER]: {
                actions: "setDateFilter",
              },
            },
          },
        },
      },
      [LOGGING_OUT]: {
        invoke: {
          src: createLogoutObservable,
          id: "logout",
          onDone: {
            target: NOT_LOGGED_IN,
            actions: ["removeUser", "revokeToken"],
          },
        },
      },
    },
  },
  {
    actions: {
      removeUser,
      revokeToken,
      saveEvents,
      saveGlobal,
      saveOrganizations,
      saveNameFilter,
      saveLocations,
      saveParticipants,
      saveUser,
      saveEventTypes,
      setCurrentEvent,
      setDateFilter,
      setCurrentLocation,
      setCurrentOrg,
      setOrgEvents,
      setTimeframe,
      setToken,
      validateCurrentEvent,
      validateCurrentOrg,
      updateUser,
    },
    guards: {
      hasCurrentEvent,
      hasOrgEvents,
      hasSelectedAnOrganization,
    },
  },
);

const savedState = restoreState();
const resolvedState = savedState
  ? appMachine.resolveState(savedState)
  : undefined;

export const appService = interpret(appMachine).start(resolvedState);

if (savedState) {
  appService.send(FETCH_EVENTS);
}

appService.onTransition((state) => {
  logger.info("Application transitioned to", state.value, state.context);
  if (state.matches(LOGGED_IN)) {
    // console.log(state);
    saveState(state as State<ApplicationContext, ApplicationEvents, any, any>);
  }
  if (state.matches(NOT_LOGGED_IN)) {
    clearSavedState();
  }
});

appService.onEvent((event) => {
  logger.info("Application received event", event);
});
