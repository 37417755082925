import { makeStyles, Typography } from "@material-ui/core";
import { isToday, isTomorrow, parseISO } from "date-fns";
import React, { FunctionComponent } from "react";

import { DashboardCard } from "../../components";
import { renderDate } from "../../helpers";
import {
  useParticipantsForLocation,
  useParticipantsForDateFilter,
} from "../../hooks";
import { useAppService } from "../../state";

const useBigCountStyles = makeStyles({
  root: {
    fontSize: "3rem",
  },
});

const dateFilterToLabel = (dateFilter: string | null) => {
  if (!dateFilter) {
    return "Every Appointment";
  }

  const labelPrefix = "Appointments for ";
  const dateFilterDate = parseISO(dateFilter);
  if (isToday(dateFilterDate)) {
    return `${labelPrefix} Today`;
  }
  if (isTomorrow(dateFilterDate)) {
    return `${labelPrefix} Tommorrow`;
  }
  return `${labelPrefix} ${renderDate(dateFilterDate)}`;
};

export const AppointmentCount: FunctionComponent = () => {
  const classes = useBigCountStyles();
  const [{ participants, dateFilter }] = useAppService();

  const participantsAtLocation = useParticipantsForLocation(participants);
  const particpantsInTimeframe = useParticipantsForDateFilter(
    participantsAtLocation,
  );

  return (
    <DashboardCard title={dateFilterToLabel(dateFilter)}>
      <Typography align="center" className={classes.root}>
        {particpantsInTimeframe.length}
      </Typography>
    </DashboardCard>
  );
};
