import React, { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";

import { PrintDesktopLabel } from "./print-desktop-label";
import { PrintWirelessLabel } from "./print-wireless-label";
import { PrintLabelProps } from "./types";

export const PrintLabelWrapper: FunctionComponent<PrintLabelProps> = (
  props,
) => {
  const PrintComponent = isMobile ? PrintWirelessLabel : PrintDesktopLabel;

  return <PrintComponent {...props} />;
};
