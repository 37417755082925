import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent } from "react";

import { AddNurseForm } from "./form";

interface AddNurseProps {
  onCancel?: () => void;
  open: boolean;
}

export const AddNurse: FunctionComponent<AddNurseProps> = ({
  onCancel,
  open,
}) => {
  const flags = useFlags();

  if (!flags.addNurse || !open) {
    return null;
  }

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return <AddNurseForm onClose={handleClose} open={open} />;
};
