import DateFnsUtils from "@date-io/date-fns";
import { Box } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { parseISO } from "date-fns";
import React, { FunctionComponent } from "react";

import { applyIfNotNull } from "../../helpers";
import { useIsDashboard, useIsVaccinationEvent } from "../../hooks";
import { useAppService } from "../../state";

export const AppointmentDateFilterMenu: FunctionComponent = () => {
  const [{ dateFilter }, , { changeDateFilter }] = useAppService();
  const isVaccination = useIsVaccinationEvent();

  const handleChangeDateEvent = (date: Date | null) => {
    changeDateFilter(date);
  };

  if (!isVaccination) {
    return null;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box>
        <DatePicker
          disableToolbar
          variant="dialog"
          showTodayButton
          views={["year", "month", "date"]}
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date filter"
          value={applyIfNotNull(parseISO, dateFilter)}
          onChange={handleChangeDateEvent}
          clearable
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
};
