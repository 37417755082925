import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  RemoveShoppingCart,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import React, { FunctionComponent, useEffect, useState } from "react";

import { ApiState, EventParticipant, useBulkUpdateParticipants } from "../core";
import { renderDate, renderDatetime } from "../helpers";
import { useAppService } from "../state";

interface DeliveryCardInfo {
  participants: EventParticipant[];
}

const useCardStyles = makeStyles({
  list: {
    columns: 3,
  },
  listItem: {
    breakInside: "avoid-column",
  },
});

export const DeliveryCard: FunctionComponent<DeliveryCardInfo> = ({
  participants,
}) => {
  const classes = useCardStyles();
  const [{ user }] = useAppService();
  const [expanded, setExpanded] = useState(false);
  const { mutate: updateParticipants } = useBulkUpdateParticipants();

  const handleCollectClick = () => {
    const collectionTime = new Date();
    updateParticipants(
      participants.map(
        (participant): EventParticipant => ({
          ...participant,
          labCourier: user!.id,
          specimenDeliveredAt: collectionTime,
        }),
      ),
    );
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (participants.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        action={
          <>
            <IconButton onClick={handleCollectClick}>
              <RemoveShoppingCart color="primary" />
            </IconButton>
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show participants"
            >
              {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </>
        }
        title="Deliver to Lab"
        subheader={`${participants.length} Specimen`}
        titleTypographyProps={{ variant: "h2" }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <List className={classes.list}>
            {participants.map((participant) => {
              const dob = participant.patientDob
                ? ` (${renderDate(participant.patientDob)})`
                : null;
              const patientInfo = `${participant.patientName}${dob}`;
              return (
                <ListItem key={participant.id} className={classes.listItem}>
                  <ListItemText
                    primary={patientInfo}
                    secondary={`Checked in at ${renderDatetime(
                      participant.checkedInAt!,
                    )}. Picked up at ${renderDatetime(
                      participant.specimenCollectedAt!,
                    )}`}
                  />
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
};
