import { TabConfiguration } from "../../../components";
import { CheckinTabs } from "../types";

import { PrimaryActionWrapper } from "./actions";
import { ContextWrapper } from "./context-wrapper";
import { Panel } from "./panel";
import { Title } from "./title";

export const PhotoCardsTab: TabConfiguration<CheckinTabs> = {
  primaryAction: PrimaryActionWrapper,
  context: ContextWrapper,
  name: CheckinTabs.IDENTIFICATION,
  panel: Panel,
  shouldShow: () => true,
  title: Title,
};
