import {
  forgetEmail,
  forgetPassword,
  formatPhoneNumber,
  loginPromise,
  logger,
  marshallUserProvider,
  resetPasswordPromise,
} from "../../core";

import { DONE, FAIL, SUCCEED } from "./constants";
import {
  AuthenticationContext,
  FailEvent,
  RequestDoneEvent,
  SucceedEvent,
} from "./types";

export const createForgetEmailObservable = async (
  context: AuthenticationContext,
): Promise<RequestDoneEvent> => {
  try {
    const response = await forgetEmail({
      phone: formatPhoneNumber(context.mobile) ?? "",
    });
  } catch (error) {
    const errorResponse = await error.response.json();
    logger.error(Object.values(errorResponse.messages).flat().join(","));
  }
  return {
    type: DONE,
  };
};

export const createForgetPasswordObservable = async (
  context: AuthenticationContext,
): Promise<RequestDoneEvent> => {
  try {
    await forgetPassword({
      email: context.email,
    });
  } catch (error) {
    const errorResponse = await error.response.json();
    logger.error(Object.values(errorResponse.messages).flat().join(", "));
  }
  return {
    type: DONE,
  };
};

export const createLoginObservable = async (
  context: AuthenticationContext,
): Promise<SucceedEvent | FailEvent> => {
  try {
    const loginResponse = await loginPromise({
      email: context.email,
      password: context.password,
      code: context.code,
    });
    return {
      token: loginResponse.key,
      user: marshallUserProvider(loginResponse.user),
    };
  } catch (error) {
    const errorObj = await error.response.json();
    return Promise.reject(new Error(Object.values(errorObj).flat().join(", ")));
  }
};

export const createResetPasswordObservable = async (
  context: AuthenticationContext,
): Promise<FailEvent | RequestDoneEvent> => {
  try {
    await resetPasswordPromise({
      uid: context.email,
      newPassword1: context.password,
      newPassword2: context.confirmation,
      token: context.token,
    });
    return {
      type: DONE,
    };
  } catch (error) {
    const { messages } = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};
