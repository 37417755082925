import {
  LinearProgress,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import React, { FunctionComponent, useState, ChangeEvent } from "react";

import { useGetOrganizations, ApiState } from "../../core";

export interface SelectOrgProps {
  onChange?: (selectedOrg: string) => void;
  value?: string;
}

export const SelectOrganization: FunctionComponent<
  SelectOrgProps & Omit<TextFieldProps, "onChange">
> = ({ onChange, SelectProps, value: passedValue = "", ...props }) => {
  const { isLoading, data: organizations } = useGetOrganizations();
  const [internalValue, changeValue] = useState(passedValue);

  const valueRenderer = (value: unknown) => {
    if (typeof value !== "string") {
      return value as string;
    }

    let label = value;
    if (value.length > 0) {
      const maybeOrg = organizations?.find((org) => org.id === value);

      if (maybeOrg) {
        label = maybeOrg.name;
      }
    }

    return label;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? "";
    const callback = onChange ?? changeValue;

    callback(newValue);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <TextField
      label="Organization"
      helperText="Select the organization that is requesting to the event"
      {...props}
      onChange={handleChange}
      value={passedValue || internalValue}
      select
      SelectProps={{ ...SelectProps, renderValue: valueRenderer }}
    >
      {organizations!.map((org) => (
        <MenuItem key={org.id} value={org.id}>
          {org.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
