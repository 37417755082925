import React, {
  Context,
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { TabConfiguration, TabContextProviderProps, TabState } from "./types";

export function createTabContext<T = string>(
  currentTab?: TabConfiguration<T>,
): [Context<TabState<T>>, () => TabState<T>] {
  const context = createContext<TabState<T>>({
    currentTab,
    next: () => {},
    selectedTab: 0,
    setSelectedTab: () => {},
    tabs: [],
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const contextHook = () => useContext(context);

  return [context, contextHook];
}

export function createTabContextProvider<T = string>(
  ReactContext: Context<TabState<T>>,
): FunctionComponent<TabContextProviderProps<T>> {
  return ({ children, onNext, tabs }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const currentTab = useMemo(() => {
      let tabToShow = tabs[0];

      const foundTab = tabs[selectedTab];
      if (foundTab) {
        tabToShow = foundTab;
      }

      return tabToShow;
    }, [selectedTab, tabs]);

    const isFirstTab = selectedTab === 0;
    const lastIndex = tabs.length - 1;
    const isLastTab = selectedTab >= lastIndex;
    const nextTab = isLastTab ? lastIndex : selectedTab + 1;

    // Contextual Actions
    const handleNext = useCallback(() => {
      if (onNext) {
        onNext();
      }
      setSelectedTab(nextTab);
    }, [nextTab, onNext]);

    const tabContext: TabState<T> = {
      currentTab,
      selectedTab,
      setSelectedTab,
      isFirstTab,
      isLastTab,
      next: handleNext,
      tabs,
    };

    return (
      <ReactContext.Provider value={tabContext}>
        {children}
      </ReactContext.Provider>
    );
  };
}

export const [TabContext, useTabContext] = createTabContext<string>();

export const TabContextProvider = createTabContextProvider(TabContext);
