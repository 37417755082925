import { ConditionPredicate } from "xstate";

import { ApplicationContext, ApplicationEvents } from "./types";

export const hasCurrentEvent: ConditionPredicate<
  ApplicationContext,
  ApplicationEvents
> = (context): boolean => Boolean(context.currentEvent);

export const hasOrgEvents: ConditionPredicate<
  ApplicationContext,
  ApplicationEvents
> = (context): boolean => context.orgEvents.length > 0;

export const hasSelectedAnOrganization: ConditionPredicate<
  ApplicationContext,
  ApplicationEvents
> = (context): boolean => context.currentOrg != null;
