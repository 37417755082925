import { EventParticipant } from "@relieftelemed/platform";
import React, { FunctionComponent, useMemo } from "react";
import { useQueryClient } from "react-query";

import { CheckinState, CheckinContext } from "./context";
import { useCheckInService } from "./state";

interface ContextWrapperProps {
  onClose?: () => void;
  participant?: EventParticipant;
}

export const ContextWrapper: FunctionComponent<ContextWrapperProps> = ({
  children,
  onClose,
  participant,
}) => {
  const [actions, selectors, service] = useCheckInService({
    maybeParticipant: participant,
  });
  const queryClient = useQueryClient();

  const patient = participant?.patient;
  const patientId = useMemo(
    () => (typeof patient === "string" ? patient : patient?.id),
    [patient],
  );

  const {
    onSelectParticipant,
    onPartialEventParticipantUpdate,
    onPersistChanges,
  } = actions;
  const { currentParticipant } = selectors;

  const handleNext = (moreDetails: Partial<EventParticipant>) => {
    onPartialEventParticipantUpdate(moreDetails);
  };

  service.onDone(() => {
    queryClient.refetchQueries("get-event-participants");

    if (onClose) {
      onClose();
    }
  });

  const handleFinish = () => {
    onPersistChanges();
  };

  // Derived Data
  // const primaryLabel = isLastTab ? "Submit" : "Next";
  const tabContext: CheckinState = {
    update: handleNext,
    participant: currentParticipant,
    patientId,
    setParticipant: onSelectParticipant,
    finish: handleFinish,
    ...actions,
    ...selectors,
  };

  return (
    <CheckinContext.Provider value={tabContext}>
      {children}
    </CheckinContext.Provider>
  );
};
