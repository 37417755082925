import { FunctionComponent } from "react";
import { useDebounce } from "react-use";

import { useCanSeeGlobal } from "../../hooks";
import { useAppService } from "../../state";

export const ToggleGlobal: FunctionComponent = () => {
  const [{ showGlobal }, , { toggleGlobal }] = useAppService();
  const canGlobal = useCanSeeGlobal() && false;

  useDebounce(
    () => {
      if (canGlobal && !showGlobal) {
        toggleGlobal();
      }
    },
    5000,
    [canGlobal, showGlobal, toggleGlobal],
  );

  return null;
};
