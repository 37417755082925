import { Typography, makeStyles } from "@material-ui/core";
import React from "react";

import { reliefTeal } from "../../styles";

import { AccentLine } from "./accent-line";

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    color: reliefTeal,
  },
  gutterBottom: {
    marginBottom: theme.spacing(4),
  },
}));

export const Announcements = () => (
  <>
    <Typography
      classes={useHeaderStyles()}
      gutterBottom
      variant="h2"
      component="h1"
    >
      COVID Mitigation Platform
    </Typography>
    <Typography variant="body1">
      Track COVID-19 test results across your organization.
    </Typography>
    <AccentLine />
  </>
);
