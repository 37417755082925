import { createContext, useContext } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setTitle = (value: string, a11yTitle?: string): void => undefined;

const titleContext = createContext(setTitle);

export const usePageTitle = () => useContext(titleContext);

export const TitleContextProvider = titleContext.Provider;
