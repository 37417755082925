import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import React, {
  ChangeEventHandler,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { PromotionInfo } from "../../core";

interface CreatePromotionProps {
  helperText?: ReactNode;
  onChange?: (promotion: PromotionInfo) => void;
}

export const CreatePromotionForm: FunctionComponent<CreatePromotionProps> = ({
  helperText,
  onChange,
}) => {
  const [code, changeCode] = useState("");
  const [isPercentage, changeType] = useState(true);
  const [amount, changeAmount] = useState(100);

  useEffect(() => {
    if (onChange) {
      onChange({
        active: true,
        amount: isPercentage ? undefined : amount,
        percentage: isPercentage ? amount : undefined,
        code,
      });
    }
  }, [onChange, code, amount, isPercentage]);

  const handleChangeAmount: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = parseFloat(event.target.value);
    changeAmount(Number.isNaN(newValue) ? 0 : newValue);
  };

  const handleChangeCode: ChangeEventHandler<HTMLInputElement> = (event) => {
    changeCode(event.target.value);
  };

  const handleChangeType: ChangeEventHandler<HTMLInputElement> = (event) => {
    changeType(event.target.checked);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={7}>
        <TextField
          id="promo-code"
          helperText={helperText}
          label="Promo Code"
          required
          value={code}
          onChange={handleChangeCode}
        />
      </Grid>
      <Grid item xs={6} lg={2}>
        <FormControlLabel
          value="percentage"
          control={
            <Switch
              color="primary"
              checked={isPercentage}
              onChange={handleChangeType}
            />
          }
          label={isPercentage ? "Percentage" : "Flat cost"}
        />
      </Grid>
      <Grid item xs={6} lg={3}>
        <TextField
          id="promo-amount"
          label={isPercentage ? "Percentage Discount" : "Promotion Amount"}
          required
          value={amount}
          onChange={handleChangeAmount}
        />
      </Grid>
    </Grid>
  );
};
