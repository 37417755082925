import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent } from "react";

import { EventParticipant } from "../../core";

import { CheckInDialog } from "./dialog";

interface CheckInProps {
  onCancel?: () => void;
  open: boolean;
  participant?: EventParticipant;
}

export const CheckIn: FunctionComponent<CheckInProps> = ({
  onCancel,
  open,
  participant,
}) => {
  if (!open) {
    return null;
  }

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <CheckInDialog
      onClose={handleClose}
      open={open}
      participant={participant}
    />
  );
};
