import { DoneInvokeEvent, Interpreter, State } from "xstate";

import {
  Event,
  EventLocation,
  EventParticipant,
  MinimalOrganization,
  User,
  ApiEventType,
} from "../core";
import { Timeframe } from "../helpers";

export const GLOBAL_ORG = Symbol("relief-global").toString();
export const GLOBAL_EVENT = Symbol("global-event").toString();

export interface DoneLoginData {
  user: User;
  token: string;
}

export interface ApplicationContext {
  currentEvent?: Event;
  currentLocation?: EventLocation;
  currentOrg?: MinimalOrganization;
  events: Event[];
  showGlobal: boolean;
  nameFilter: string;
  organizations: MinimalOrganization[];
  eventTypes: ApiEventType[];
  orgEvents: Event[];
  locations: EventLocation[];
  participants: EventParticipant[];
  timeframe: Timeframe;
  token?: string;
  user?: User;
  dateFilter: string | null;
}

export interface ApplicationSchema {
  states: {
    loggedIn: {
      states: {
        initial: {};
        fetchForCommonData: {
          states: {
            doFetching: {};
          };
        };
        fetchingOrganizationEvents: {};
        fetchForEventLocations: {};
        maybeOrganization: {};
        maybeEvent: {};
        ready: {};
      };
    };
    notLoggedIn: {};
    loggingOut: {};
  };
}

// Events
export type EndSessionEvent = { type: "APP.END_SESSION" };

export type ToggleGlobalEvent = { type: "APP.TOGGLE_GLOBAL" };

export type FetchEventsEvent = { type: "APP.FETCH_EVENTS" };

export type FetchParticipantsEvent = { type: "APP.FETCH_PARTICIPANTS" };

export type FinishLogoutEvent = { type: "APP.FINISH_LOGOUT" };

export interface ChangeEventEvent {
  type: "APP.CHANGE_EVENT";
  event?: Event;
}

export interface ChangeLocationEvent {
  type: "APP.CHANGE_LOCATION";
  location?: EventLocation;
}

export interface ChangeNameFilterEvent {
  type: "APP.CHANGE_NAME_FILTER";
  query: string;
}

export interface ChangeOrganizationEvent {
  type: "APP.CHANGE_ORG";
  organization?: MinimalOrganization;
  event?: Event;
}

export interface ChangeTimeframeEvent {
  type: "APP.CHANGE_TIME";
  timeframe: Timeframe;
}

export interface ChangeDateFilterEvent {
  type: "APP.CHANGE_DATE_FILTER";
  dateFilter: Date | null;
}

export type UpdateEventsEvent = Event[];

export type UpdateLocationsEvent = EventLocation[];

export type UpdateParticipantsEvent = EventParticipant[];

export type ApplicationEvents =
  | ChangeEventEvent
  | ChangeLocationEvent
  | ChangeNameFilterEvent
  | ChangeOrganizationEvent
  | ChangeTimeframeEvent
  | EndSessionEvent
  | FetchEventsEvent
  | FetchParticipantsEvent
  | FinishLogoutEvent
  | ToggleGlobalEvent
  | DoneInvokeEvent<UpdateLocationsEvent>
  | DoneInvokeEvent<UpdateParticipantsEvent>
  | ChangeDateFilterEvent
  | DoneInvokeEvent<UpdateEventsEvent>
  | DoneInvokeEvent<any>;

export const isDoneEvent = <TData = {}>(
  event: ApplicationEvents,
): event is DoneInvokeEvent<TData> =>
  (event as DoneInvokeEvent<TData>).data !== undefined;

export type ApplicationState = State<
  ApplicationContext,
  ApplicationEvents,
  ApplicationSchema
>;

export type SendChangeEvent = (event: Event) => void;

export type SendChangeLocation = (location?: EventLocation) => void;

export type SendNameFilter = (query: string) => void;

export type SendChangeTimeframe = (timeframe: Timeframe) => void;

export type SendChangeDateFilterEvent = (dateFilter: Date | null) => void;

export type SendToggleGlobal = () => void;

export type SendChangeOrganization = (
  organization: MinimalOrganization,
  event?: Event,
) => void;

export type SendFetchEvents = () => void;

export type SendFetchParticipants = () => void;

export type SendLogout = () => void;

export type SendUpdateUser = (userData: User) => void;
export interface ApplicationActions {
  changeEvent: SendChangeEvent;
  changeDateFilter: SendChangeDateFilterEvent;
  changeLocation: SendChangeLocation;
  changeNameFilter: SendNameFilter;
  changeOrganization: SendChangeOrganization;
  changeTimeframe: SendChangeTimeframe;
  fetchEvents: SendFetchEvents;
  logout: SendLogout;
  toggleGlobal: SendToggleGlobal;
  updateUser: SendUpdateUser;
}

export interface ApplicationSelectors {
  currentEvent?: Event;
  currentLocation?: EventLocation;
  currentOrganization?: MinimalOrganization;
  dateFilter: string | null;
  events: Event[];
  eventTypes: ApiEventType[];
  locations: EventLocation[];
  allEvents: Event[];
  isGlobalEvent: boolean;
  isLoggedIn: boolean;
  isReady: boolean;
  nameFilter: string;
  organizations: MinimalOrganization[];
  participants: EventParticipant[];
  showGlobal: boolean;
  timeframe: Timeframe;
  user?: User;
}

export type ApplicationServices = Record<string, Interpreter<any, any>>;

export type ApplicationHooks = [
  ApplicationSelectors,
  ApplicationServices,
  ApplicationActions,
];
