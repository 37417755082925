import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import {
  EventDetails,
  JobSheet,
  VaccinationMetabaseDashboard,
} from "../components";

export const VaccinationDashboard: FunctionComponent = () => {
  return (
    <div id="vaccination_dashboard" className="dashboard_container">
      <Grid container spacing={2} justify="center">
        <Grid item xs={6} lg={4}>
          <EventDetails />
        </Grid>
        <Grid item xs={6} lg={4}>
          <JobSheet />
        </Grid>
      </Grid>
      <VaccinationMetabaseDashboard />
    </div>
  );
};
