/* eslint-disable react/destructuring-assignment */
import { Link, Typography, Tooltip, Button } from "@material-ui/core";
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { PrintLabelButton } from "../../components";
import {
  Event,
  EventParticipant,
  PatientVaccination,
  renderPhoneNumber,
} from "../../core";
import {
  getLabResults,
  getParticipantEvent,
  getPatientVaccination,
  getRapidResults,
  getResult,
  parseBooleanResult,
  renderAge,
  renderDate,
  renderDatetime,
  capitalize,
} from "../../helpers";
import { useActionMenuContext, useIsEventVaccination } from "../../hooks";
import { GLOBAL_EVENT } from "../../state";

export enum Fields {
  name = "name",
  gender = "gender",
  dob = "dob",
  email = "email",
  event = "event",
  mobile = "mobile",
  visitComplete = "visitComplete",
  checkedIn = "checkedIn",
  checkedInDate = "checkedInDate",
  appointmentDate = "appointmentDate",
  givenConsent = "givenConsent",
  hasRapidResults = "hasRapidResults",
  hasLabResults = "hasLabResults",
  actionPlan = "actionPlan",
  actionPlanReady = "actionPlanReady",
  vaccinationCheckIn = "vaccinationCheckIn",
  reviewName = "reviewName",
  participantStatus = "participantStatus",
  appointmentType = "appointmentType",
}

export interface FieldConfig<T = string> {
  id: Fields;
  label: string;
  rawValue?: (participant: EventParticipant, events?: Event[]) => T | undefined;
  timeValue?: (
    participant: EventParticipant,
    events: Event[],
    currentEvent?: Event,
  ) => ReactNode | undefined;
  value: (
    participant: EventParticipant,
    events: Event[],
    currentEvent?: Event,
  ) => ReactNode | undefined;
}

export const createResultField = (testName: string): FieldConfig<boolean> => {
  return {
    id: Fields.name, // fake this for TS
    label: "Result",
    rawValue: (participant: EventParticipant) => {
      const value = getResult(testName, participant);
      if (value === undefined) {
        return value;
      }

      return parseBooleanResult(value);
    },
    value: () => undefined,
  };
};

const done = <CheckCircleOutline color="primary" />;
const denied = <HighlightOff color="error" />;

interface NameProps {
  event?: Event;
  participant: EventParticipant;
  showEventName?: boolean;
}

const VaccinationNameField: FunctionComponent<NameProps> = ({
  event,
  participant,
  showEventName,
}) => {
  let orgName = "";
  let eventName = "";

  if (event) {
    eventName = event.name;
    const organization =
      typeof event?.organization === "string"
        ? event?.organization
        : event?.organization?.id;

    if (organization) {
      orgName = event.orgName!;
    }
  }

  const nameDetails = showEventName ? (
    <Typography variant="subtitle2">
      {orgName} - {eventName}
    </Typography>
  ) : null;

  return (
    <>
      <Typography variant="h4">{participant.patientName}</Typography>
      {nameDetails}
    </>
  );
};

const NameField: FunctionComponent<NameProps> = ({
  event,
  participant,
  showEventName,
}) => {
  const flags = useFlags();
  const isVaccinationEvent = useIsEventVaccination(event);

  if (isVaccinationEvent) {
    return (
      <VaccinationNameField
        event={event}
        participant={participant}
        showEventName={showEventName}
      />
    );
  }

  const patient =
    typeof participant.patient === "string"
      ? participant.patient
      : participant.patient.id;
  const patientUrl = `/${patient}`;

  let orgUrl = "";
  let orgName = "";
  let eventName = "";

  if (event) {
    eventName = event.name;
    const organization =
      typeof event?.organization === "string"
        ? event?.organization
        : event?.organization?.id;

    if (organization) {
      orgUrl = `/${organization}`;
      orgName = event.orgName!;
    }
  }

  const nameDetails = showEventName ? (
    <Typography variant="subtitle2">
      {orgName} - {eventName}
    </Typography>
  ) : null;

  // const reprintButton =
  //   flags.checkInPatient && participant.checkedInAt ? (
  //     <PrintLabelButton participant={participant} />
  //   ) : null;

  return (
    <>
      <Link
        display="block"
        variant="h4"
        component={RouterLink}
        to={`/history${orgUrl}${patientUrl}`}
      >
        {participant.patientName}
      </Link>
      {nameDetails}
      {/* {reprintButton} */}
    </>
  );
};

interface AppointmentProps {
  participant: EventParticipant;
}

const AppointmentField: FunctionComponent<AppointmentProps> = ({
  participant,
}) => {
  const appointmentTime = participant.appointmentAt
    ? renderDatetime(participant.appointmentAt)
    : undefined;

  const appointmentLocation = participant.appointmentLocationName ? (
    <Typography variant="subtitle2">
      {participant.appointmentLocationName}
    </Typography>
  ) : null;

  return (
    <>
      <Typography variant="h4">{appointmentTime}</Typography>
      {appointmentLocation}
    </>
  );
};

interface ShotProps {
  patientVaccination: PatientVaccination;
}

const ShotField: FunctionComponent<ShotProps> = ({ patientVaccination }) => (
  <>
    <Typography variant="h4">
      {renderDatetime(patientVaccination.createdAt)}
    </Typography>
    <Typography variant="subtitle2">
      Lot {patientVaccination.vaccineLot}
    </Typography>
  </>
);

interface ReviewNameProps {
  participant: EventParticipant;
}

const ReviewNameField: FunctionComponent<ReviewNameProps> = ({
  participant,
}) => {
  const { doReviewParticipant } = useActionMenuContext();

  return (
    <>
      <VaccinationNameField participant={participant} />
      <Button
        onClick={() => doReviewParticipant({ participant })}
        variant="outlined"
        size="small"
      >
        Review Participant
      </Button>
    </>
  );
};

export const fieldMap: Record<Fields, FieldConfig<any>> = {
  [Fields.name]: {
    id: Fields.name,
    label: "Name",
    rawValue: (participant: EventParticipant): string | undefined =>
      participant.patientName,
    value: (
      participant: EventParticipant,
      events: Event[],
      currentEvent?: Event,
    ): ReactNode => {
      const event = getParticipantEvent(participant, events);

      return (
        <NameField
          event={event}
          participant={participant}
          showEventName={currentEvent?.id === GLOBAL_EVENT}
        />
      );
    },
  },
  [Fields.gender]: {
    id: Fields.gender,
    label: "Gender",
    value: (participant: EventParticipant): string | undefined =>
      participant.patientGender,
  },
  [Fields.dob]: {
    id: Fields.dob,
    label: "Date of Birth",
    rawValue: (participant: EventParticipant): Date | undefined =>
      participant.patientDob,
    value: (participant: EventParticipant): ReactNode | undefined =>
      participant.patientDob ? (
        <Tooltip title={`${renderAge(participant.patientDob)} years old`}>
          <span>{renderDate(participant.patientDob)}</span>
        </Tooltip>
      ) : undefined,
  },
  [Fields.email]: {
    id: Fields.email,
    label: "Email Address",
    value: (participant: EventParticipant): string | undefined =>
      participant.patientEmail,
  },
  [Fields.event]: {
    id: Fields.event,
    label: "Event",
    value: (
      participant: EventParticipant,
      events: Event[],
    ): string | undefined => {
      const event = getParticipantEvent(participant, events); // TODO this does not make sense to me yet

      if (!event) {
        return undefined;
      }

      return event.name;
    },
  },
  [Fields.mobile]: {
    id: Fields.mobile,
    label: "Phone Number",
    rawValue: (participant: EventParticipant): string | undefined =>
      participant.patientPhone
        ? participant.patientPhone.replace(/^\+?1?/, "")
        : undefined,
    value: (participant: EventParticipant): string | undefined =>
      participant.patientPhone
        ? renderPhoneNumber(participant.patientPhone)
        : undefined,
  },
  [Fields.visitComplete]: {
    id: Fields.visitComplete,
    label: "Completed Video Visit",
    rawValue: (participant: EventParticipant): Date | undefined =>
      participant.visitedAt,
    timeValue: (participant: EventParticipant): ReactNode | undefined =>
      participant.visitedAt ? renderDatetime(participant.visitedAt) : undefined,
    value: (participant: EventParticipant): ReactNode | undefined =>
      participant.visitedAt ? (
        <Tooltip title={renderDatetime(participant.visitedAt)}>{done}</Tooltip>
      ) : undefined,
  },
  [Fields.checkedIn]: {
    id: Fields.checkedIn,
    label: "Checked In for Testing",
    rawValue: (participant: EventParticipant): Date | undefined =>
      participant.checkedInAt,
    timeValue: (participant: EventParticipant): ReactNode | undefined =>
      participant.checkedInAt
        ? renderDatetime(participant.checkedInAt)
        : undefined,
    value: (participant: EventParticipant): ReactNode | undefined =>
      participant.checkedInAt ? (
        <Tooltip title={renderDatetime(participant.checkedInAt)}>
          {done}
        </Tooltip>
      ) : undefined,
  },
  [Fields.appointmentDate]: {
    id: Fields.appointmentDate,
    label: "Appointment",
    rawValue: (participant: EventParticipant): Date | undefined =>
      participant.appointmentAt,
    value: (participant: EventParticipant): ReactNode | undefined => (
      <AppointmentField participant={participant} />
    ),
  },
  [Fields.checkedInDate]: {
    id: Fields.checkedInDate,
    label: "Date Swabbed",
    rawValue: (participant: EventParticipant): Date | undefined =>
      participant.checkedInAt,
    value: (participant: EventParticipant): ReactNode | undefined =>
      participant.checkedInAt
        ? renderDatetime(participant.checkedInAt)
        : undefined,
  },
  [Fields.givenConsent]: {
    id: Fields.givenConsent,
    label: "Given Consent",
    rawValue: (participant: EventParticipant): boolean =>
      participant.orgConsent ?? false,
    value: (participant: EventParticipant): ReactNode | undefined => {
      if (
        participant.orgConsent === undefined ||
        participant.checkedInAt === undefined
      ) {
        return undefined;
      }

      return (
        <Tooltip title={renderDatetime(participant.checkedInAt)}>
          {participant.orgConsent ? done : denied}
        </Tooltip>
      );
    },
  },
  [Fields.hasRapidResults]: {
    id: Fields.hasRapidResults,
    label: "Received Rapid Results",
    rawValue: (participant: EventParticipant): boolean => {
      const results = getRapidResults(participant);

      return results.length > 0;
    },
    timeValue: (participant: EventParticipant): ReactNode | undefined => {
      const results = getRapidResults(participant);

      return results.length > 0
        ? renderDatetime(results[0].createdAt)
        : undefined;
    },
    value: (participant: EventParticipant): ReactNode => {
      const results = getRapidResults(participant);

      return results.length > 0 ? (
        <Tooltip title={renderDatetime(results[0].createdAt)}>{done}</Tooltip>
      ) : undefined;
    },
  },
  [Fields.hasLabResults]: {
    id: Fields.hasLabResults,
    label: "Received Lab Results",
    rawValue: (participant: EventParticipant): Date | undefined => {
      const results = getLabResults(participant);

      return results.length > 0 ? results[0].createdAt : undefined;
    },
    timeValue: (participant: EventParticipant): ReactNode | undefined => {
      const results = getLabResults(participant);

      return results.length > 0
        ? renderDatetime(results[0].createdAt)
        : undefined;
    },
    value: (participant: EventParticipant): ReactNode => {
      const results = getLabResults(participant);

      return results.length > 0 ? (
        <Tooltip title={renderDatetime(results[0].createdAt)}>{done}</Tooltip>
      ) : undefined;
    },
  },
  [Fields.actionPlan]: {
    id: Fields.actionPlan,
    label: "Action Plan",
    value: (
      participant: EventParticipant,
      events: Event[],
    ): ReactNode | undefined => {
      const event = getParticipantEvent(participant, events);

      if (event?.showActionPlans) {
        return participant.actionPlan;
      }

      return "Upgrade to access Action Plans";
    },
  },
  [Fields.actionPlanReady]: {
    id: Fields.actionPlanReady,
    label: "Action Plan Ready",
    rawValue: (
      participant: EventParticipant,
      events?: Event[],
    ): boolean | undefined => {
      const event = getParticipantEvent(participant, events);

      if (event?.showActionPlans) {
        return Boolean(participant.actionPlan);
      }

      return undefined;
    },
    value: (
      participant: EventParticipant,
      events: Event[],
    ): ReactNode | undefined => {
      const event = getParticipantEvent(participant, events);

      if (event?.showActionPlans) {
        return participant.actionPlan ? done : undefined;
      }

      return "Upgrade to access Action Plans";
    },
  },
  [Fields.vaccinationCheckIn]: {
    id: Fields.vaccinationCheckIn,
    label: "Checked In",
    rawValue: (participant: EventParticipant) => {
      const firstShot = getPatientVaccination(participant, 1);

      return firstShot?.createdAt;
    },
    timeValue: (participant: EventParticipant) => {
      const firstShot = getPatientVaccination(participant, 1);
      return firstShot ? <ShotField patientVaccination={firstShot} /> : null;
    },
    value: (participant: EventParticipant) => {
      const firstShot = getPatientVaccination(participant, 1);
      return firstShot ? done : null;
    },
  },
  [Fields.reviewName]: {
    id: Fields.reviewName,
    label: "Name",
    rawValue: (participant: EventParticipant) => {},
    value: (participant: EventParticipant): ReactNode | undefined => {
      return <ReviewNameField participant={participant} />;
    },
  },
  [Fields.participantStatus]: {
    id: Fields.participantStatus,
    label: "Status",
    value: (participant: EventParticipant): string | undefined => {
      return participant.status;
    },
  },
  [Fields.appointmentType]: {
    id: Fields.participantStatus,
    label: "Appointment Type",
    value: (participant: EventParticipant): string | undefined => {
      return capitalize((participant.appointmentType || "").replace("_", " "));
    },
  },
};

export type TableVariants =
  | "roster"
  | "stages"
  | "stagetimes"
  | "results"
  | "pcr"
  | "plans"
  | "details"
  | "checklistreview";

export const rosterFields: Fields[] = [
  Fields.name,
  Fields.gender,
  Fields.dob,
  Fields.appointmentDate,
  Fields.email,
  Fields.mobile,
  Fields.appointmentType,
];
export const stagesFields: Fields[] = [
  Fields.name,
  Fields.visitComplete,
  Fields.checkedIn,
  Fields.givenConsent,
  Fields.hasRapidResults,
  Fields.hasLabResults,
  Fields.actionPlanReady,
];

export const vaccinationStagesFields: Fields[] = [
  Fields.name,
  Fields.dob,
  Fields.gender,
  Fields.mobile,
  Fields.appointmentDate,
  Fields.vaccinationCheckIn,
];
export const stageTimesFields: Fields[] = [
  Fields.name,
  Fields.visitComplete,
  Fields.checkedIn,
  Fields.givenConsent,
  Fields.hasRapidResults,
  Fields.hasLabResults,
  Fields.actionPlanReady,
];

export const vaccinationStageTimesFields: Fields[] = [
  Fields.name,
  Fields.dob,
  Fields.gender,
  Fields.mobile,
  Fields.appointmentDate,
  Fields.vaccinationCheckIn,
];
export const resultsFields: Fields[] = [
  Fields.name,
  Fields.dob,
  Fields.checkedInDate,
];
export const detailsFields: Fields[] = [Fields.event];
export const pcrFields: Fields[] = [
  Fields.name,
  Fields.gender,
  Fields.dob,
  Fields.checkedInDate,
  Fields.email,
  Fields.mobile,
];
export const plansFields: Fields[] = [
  Fields.name,
  Fields.gender,
  Fields.dob,
  Fields.checkedInDate,
  Fields.actionPlan,
];

export const checklistFields: Fields[] = [
  Fields.reviewName,
  Fields.gender,
  Fields.dob,
  Fields.appointmentDate,
  Fields.participantStatus,
];
