import {
  getEventParticipant,
  marshallEventParticipant,
  putEventParticipant,
  unmarshallEventParticipant,
  EventParticipant,
} from "@relieftelemed/platform";

import { CheckInContext, RequestDoneEvent } from "./types";

export const fetchForParticipantDetails = async (
  context: CheckInContext,
): Promise<EventParticipant> => {
  try {
    const response = await getEventParticipant(context.participantId!);
    return marshallEventParticipant(response);
  } catch (error) {
    return Promise.reject(new Error(Object.values(error).flat().join(", ")));
  }
};

export const persistVaccinationChanges = async (
  context: CheckInContext,
): Promise<EventParticipant> => {
  const vaccineInfoChanges = context.vaccineInfo || {};
  const testingInfoChanges = context.testingInfo || {};
  const unmarshallEventParticipantVariable = unmarshallEventParticipant({
    ...context.participant!,
    ...vaccineInfoChanges,
    ...testingInfoChanges,
    checkInLocation: context.checkInLocation,
    checkedInAt:
      context.participant?.checkedInAt == null
        ? new Date()
        : context.participant?.checkedInAt,
  });
  // console.log("EventParticipant Unmarshall: ", unmarshallEventParticipantVariable);
  try {
    const response = await putEventParticipant(context.participantId!)(
      unmarshallEventParticipantVariable,
    );
    return marshallEventParticipant(response);
  } catch (error) {
    return Promise.reject(new Error(Object.values(error).flat().join(", ")));
  }
};
