import { Fab, makeStyles, Tooltip } from "@material-ui/core";
import { Edit, Publish } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import {
  ApiState,
  filterChange,
  TestResultChange,
  useBulkAddResults,
} from "../../core";
import { usePageTitle } from "../../hooks";
import { useAppService } from "../../state";

import { ChangesContext, EditContext } from "./edit-context";
import { ParticipantsTable } from "./table";

const useResultsStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

export const ResultsPage: FunctionComponent = () => {
  const queryClient = useQueryClient();
  const classes = useResultsStyles();
  const setTitle = usePageTitle();
  const flags = useFlags();
  const [{ currentEvent }] = useAppService();
  const [isEditing, setEditing] = useState(false);
  const [changes, setChanges] = useState<TestResultChange[]>([]);
  const { mutate } = useBulkAddResults();

  const toggleEditing = () => {
    setEditing(!isEditing);
  };

  useEffect(() => {
    const title = currentEvent
      ? `${currentEvent.orgName ?? "Organization"} Results for ${
          currentEvent.name
        }`
      : undefined;
    setTitle("Results", title);
  }, [currentEvent, setTitle]);

  let fab = null;
  let contextValue: ChangesContext | undefined;

  if (flags.addTestResults) {
    const handleSave = () => {
      mutate(changes, {
        onSuccess: () => {
          queryClient.invalidateQueries("get-event-participants");
          setEditing(false);
        },
      });
    };

    const editButton = (
      <Tooltip title="Edit">
        <Fab
          className={classes.fab}
          onClick={toggleEditing}
          color={isEditing ? "secondary" : "primary"}
        >
          <Edit />
        </Fab>
      </Tooltip>
    );

    const saveButton = (
      <Tooltip title="Save">
        <Fab onClick={handleSave} className={classes.fab} color="primary">
          <Publish />
        </Fab>
      </Tooltip>
    );

    fab = isEditing && changes.length > 0 ? saveButton : editButton;

    if (isEditing) {
      contextValue = {
        changes,
        add: (newChange: TestResultChange) => {
          setChanges([...changes.filter(filterChange(newChange)), newChange]);
        },
        revert: (change: TestResultChange) => {
          setChanges(changes.filter(filterChange(change)));
        },
      };
    }
  }

  return (
    <EditContext.Provider value={contextValue}>
      {fab}
      <ParticipantsTable variant="results" />
    </EditContext.Provider>
  );
};
