import { Divider, Grid, List, makeStyles, Typography } from "@material-ui/core";
import { Contacts, Dashboard, Home, Assessment } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import { NavItem } from "../../components";
import {
  PATH_EVENT_HOME,
  PATH_HOME,
  PATH_ROSTER,
  PATH_METABASE,
} from "../../constants";
import { useIsVaccinationEvent } from "../../hooks";
import { useAppService } from "../../state";
import { inputPlaceholder } from "../../styles";

import { ActionButton } from "./action-button";
import { TestingNavigation } from "./testing-navigation";
import { UserMenu } from "./user-menu";
import { VaccineNavigation } from "./vaccine-navigation";

const useNavStyles = makeStyles({
  root: {
    height: "100%",
  },
  divider: {
    backgroundColor: inputPlaceholder,
  },
});

export const Navigation: FunctionComponent = () => {
  const classes = useNavStyles();
  const isVaccination = useIsVaccinationEvent();
  const [{ currentEvent }] = useAppService();
  return (
    <Grid
      classes={{ root: classes.root }}
      alignItems="stretch"
      container
      direction="column"
      justify="flex-start"
      spacing={2}
    >
      <Grid item>
        <UserMenu />
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid item>
        <Typography variant="srOnly" id="nav-header">
          Main Navigation
        </Typography>
        <List aria-labelledby="nav-header" component="nav">
          <NavItem icon={Home} link={PATH_EVENT_HOME} text="Home" />
          {currentEvent ? (
            <>
              <NavItem icon={Dashboard} link={PATH_HOME} text="Dashboard" />
              <NavItem
                icon={Contacts}
                link={PATH_ROSTER}
                text="Roster"
                id="roster_link"
              />
              {isVaccination ? <VaccineNavigation /> : <TestingNavigation />}
            </>
          ) : null}
        </List>
      </Grid>
      <Grid item lg />
      <Grid item>{currentEvent ? <ActionButton /> : null}</Grid>
    </Grid>
  );
};
