import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { useIsLsu } from "../../hooks";
import {
  EventDetails,
  JobSheet,
  ParticipantCount,
  TestingMetabaseDashboard,
} from "../components";

import { PositivesCount } from "./positivites-count";
import { PositivityRate } from "./positivity-rate";
import { ResultsRow } from "./results-row";
import { TestTrends } from "./test-trends";

export const TestingDashboard: FunctionComponent = () => {
  const isLsu = useIsLsu();

  const positives = isLsu ? <PositivesCount /> : <PositivityRate />;

  return (
    <div className="dashboard_container" id="testing_dashboard">
      <Grid container spacing={2} justify="center">
        <Grid item xs={6} lg={4}>
          <EventDetails />
        </Grid>
        <Grid item xs={6} lg={4}>
          <JobSheet />
        </Grid>
      </Grid>
      <TestingMetabaseDashboard />
    </div>
  );
};
