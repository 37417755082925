import { Avatar, Card, Divider, Grid, makeStyles } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { Visit } from "../core";
import { reliefTeal, white } from "../styles";

import { DefinedProperty } from "./defined-property";

export interface VisitCardProps {
  visit: Visit;
  isSelected?: boolean;
  onClick: () => void;
}

export const useQuickCardStyles = makeStyles((theme) => ({
  root: {
    height: 80,
    padding: `0 ${theme.spacing(2)}px`,
  },
  avatar: {
    background: reliefTeal,
    color: white,
    height: 54,
    margin: "0 auto",
    width: 54,
  },
  content: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  beforeDivider: {
    marginRight: -theme.spacing(2),
  },
  afterDivider: {
    marginLeft: theme.spacing(2) - 1,
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

export const VisitQuickCard: FunctionComponent<VisitCardProps> = ({
  children,
  isSelected = false,
  onClick,
  visit,
}) => {
  const classes = useQuickCardStyles();

  return (
    <Card
      className={isSelected ? `selected ${classes.selected}` : undefined}
      classes={{ root: classes.root }}
      elevation={6}
      onClick={onClick}
    >
      <Grid container alignItems="flex-start">
        <Grid item xs={1} className={classes.content}>
          <Avatar alt={visit.patientName} classes={{ root: classes.avatar }}>
            {visit.patientInitials}
          </Avatar>
        </Grid>
        <Grid
          item
          xs={3}
          className={`${classes.content} ${classes.beforeDivider}`}
        >
          <DefinedProperty title="Patient Name">
            {visit.patientName}
          </DefinedProperty>
        </Grid>
        <Divider orientation="vertical" flexItem />
        {children}
      </Grid>
    </Card>
  );
};
