import {
  LinearProgress,
  TextField,
  TextFieldProps as ITextField,
} from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import React, { FunctionComponent, ChangeEvent } from "react";

import { useGetUsers, User } from "../../core";

export interface SelectUserProps {
  onChange?: (selectedUser?: User) => void;
  TextFieldProps?: ITextField;
  value?: User;
}

export const SelectUser: FunctionComponent<
  SelectUserProps &
    Partial<Omit<AutocompleteProps<User, false, false, false>, "onChange">>
> = ({ onChange, value: passedValue, TextFieldProps, ...props }) => {
  const { data: users, isLoading } = useGetUsers();

  const handleChange = (_event: ChangeEvent<{}>, newValue: User | null) => {
    if (onChange) {
      onChange(newValue ?? undefined);
    }
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Autocomplete
      id="participant"
      blurOnSelect
      {...props}
      options={users!}
      getOptionLabel={(option: User) => option.email}
      getOptionSelected={(option, selectedValue) =>
        option.id === selectedValue.id
      }
      renderInput={(params: {}) => (
        <TextField
          label="User"
          helperText="Select the user by email"
          {...TextFieldProps}
          {...params}
        />
      )}
      onChange={handleChange}
      value={passedValue ?? null}
    />
  );
};
