import { FunctionComponent } from "react";

import { usePrintLabel } from "../hooks";
import { useAppService } from "../state";

import { PrintLabelProps } from "./types";

export const PrintDesktopLabel: FunctionComponent<PrintLabelProps> = ({
  participant,
  render,
}) => {
  const [printLabel] = usePrintLabel();
  const [{ user }] = useAppService();

  const handleReprint = () => {
    printLabel({
      patientName: participant?.patientName ?? "__________",
      patientDob: participant?.patientDob,
      collectedAt: participant?.checkedInAt ?? new Date(),
      collectedBy: `${user!.firstName} ${user!.lastName}`,
    });
  };

  return render(handleReprint, false);
};
