import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, useState } from "react";

import { EventParticipant } from "../../core";
import { useAppService } from "../../state";
import { SelectParticipantForm } from "../select";

import { AddActionPlanForm } from "./form";

interface AddActionPlanProps {
  onCancel?: () => void;
  open: boolean;
}

export const AddActionPlan: FunctionComponent<AddActionPlanProps> = ({
  onCancel,
  open,
}) => {
  const flags = useFlags();
  const [{ currentEvent }] = useAppService();
  const [participant, changeParticipant] = useState<
    EventParticipant | undefined
  >();

  if (!flags.addActionPlans || !currentEvent?.showActionPlans || !open) {
    return null;
  }

  const handleClose = () => {
    changeParticipant(undefined);

    if (onCancel) {
      onCancel();
    }
  };

  if (participant?.visit) {
    const visitId =
      typeof participant.visit === "string"
        ? participant.visit
        : participant.visit.id;
    return <AddActionPlanForm visit={visitId} onClose={handleClose} />;
  }
  return (
    <SelectParticipantForm
      filter="visited"
      onChange={changeParticipant}
      onClose={handleClose}
    />
  );
};
