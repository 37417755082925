import { Button, DialogContentText, Grid, makeStyles } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

import { FileInput, FormModal } from "../../components";
import {
  ApiState,
  StudentRosterEntry,
  RosterUploadError,
  RosterUploadErrorMap,
  useLocalFile,
  logger,
  usePostStudentRoster,
} from "../../core";
import { useSpreadsheet } from "../../hooks";
import { useAppService } from "../../state";

const isErrorDetailed = (
  value: RosterUploadError | RosterUploadErrorMap,
): value is RosterUploadError => {
  return (value as RosterUploadError).schoolCode !== undefined;
};

const normalizeError = (
  error: RosterUploadError | RosterUploadErrorMap,
): RosterUploadError => {
  if (isErrorDetailed(error)) {
    return error;
  }

  const studentNumber = Object.keys(error).pop()!;

  return {
    studentNumber,
    error: error[studentNumber],
  };
};

interface UploadParticipantsProps {
  onClose?: () => void;
  open?: boolean;
}

const useModalStyles = makeStyles({
  primaryAction: {
    textAlign: "right",
  },
});

const SHEET_FILES = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  // "fods",
  // "uos",
  // "sylk",
  // "dif",
  // "dbf",
  // "prn",
  // "qpw",
  // "123",
  // "wb*",
  // "wq*",
  // "html",
  // "htm",
];

export const UploadParticipantsForm: FunctionComponent<UploadParticipantsProps> = ({
  onClose,
  open = false,
}) => {
  const classes = useModalStyles();
  const [{ currentEvent }] = useAppService();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [uploadEntries, setUploadEntries] = useState<StudentRosterEntry[]>([]);
  const fileData = useLocalFile(file);
  const sheetData = useSpreadsheet<StudentRosterEntry>(fileData ?? undefined);
  const { mutate, isSuccess, isIdle, data: response } = usePostStudentRoster();

  const success: string | undefined = useMemo(() => {
    if (!isSuccess || isIdle) {
      return undefined;
    }

    return `${response?.participantsAdded} records added`;
  }, [isSuccess, isIdle, response]);

  const warnings: string[] = useMemo(() => {
    if (!isSuccess || isIdle) {
      return [];
    }

    return response!.errors!.map((baseError) => {
      const error = normalizeError(baseError);

      const record = sheetData?.find(
        (entry) =>
          (!error.schoolCode || entry.school_code === error.schoolCode) &&
          entry.student_number === error.studentNumber,
      );

      if (!record) {
        const errorIdentifier = `${error.schoolCode ?? "UNKNOWN"}-${
          error.studentNumber
        }`;
        return `${errorIdentifier}: ${error.error}`;
      }

      const studentIdentifier = `${record.school_code}-${record.student_number}`;
      const studentName =
        record.student_first_name && record.student_last_name
          ? ` (${record.student_first_name} ${record.student_last_name})`
          : "";
      return `${studentIdentifier}${studentName}: ${error.error}`;
    });
  }, [response, isSuccess, isIdle, sheetData]);

  const submitDisabled = !sheetData || sheetData.length === 0;

  const handleSubmit = () => {
    mutate(
      {
        event: currentEvent!.id,
        roster: sheetData!,
      },
      {
        onSuccess: () => {
          logger.info("Upload has been performed");
        },
      },
    );
  };

  const uploadLabel =
    sheetData && sheetData.length > 0
      ? `Upload ${sheetData?.length} Records`
      : "Upload";

  const successMessage = success ? (
    <DialogContentText variant="h2">{success}</DialogContentText>
  ) : null;

  const warningMessages =
    warnings.length > 0 ? (
      <>
        <DialogContentText color="error" variant="h3">
          The following errors occurred while processing
        </DialogContentText>
        {warnings.map((warning) => (
          <DialogContentText key={warning}>{warning}</DialogContentText>
        ))}
      </>
    ) : null;

  return (
    <FormModal
      icon={CloudUpload}
      fullWidth
      onClose={onClose}
      open={open}
      scroll="body"
      title="Upload Participants"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button disabled={submitDisabled} onClick={handleSubmit}>
              {uploadLabel}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText variant="h3">Select a file</DialogContentText>
      <FileInput
        accepts={SHEET_FILES}
        onChange={setFile}
        label={file?.name ?? "Find a file"}
      />
      {successMessage}
      {warningMessages}
    </FormModal>
  );
};
