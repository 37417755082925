import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AuthenticatedSwitch } from "./authenticated-switch";
import {
  ForgotEmailPage,
  ForgotPasswordPage,
  LogoutPage,
  LoginPage,
  ResetPasswordPage,
} from "./authentication";
import { CollectionPage } from "./collection";
import {
  FLAG_COLLECT_SPECIMEN,
  FLAG_VIEW_PLANS,
  PATH_ACTION_PLANS,
  PATH_COLLECTION,
  PATH_EVENT_HOME,
  PATH_FORGOT_EMAIL,
  PATH_FORGOT_PASSWORD,
  PATH_HISTORY,
  PATH_HOME,
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_POSITIVES,
  PATH_REGISTER,
  PATH_RESET_PASSWORD,
  PATH_RESULTS,
  PATH_REVIEW,
  PATH_ROSTER,
  PATH_STAGES,
  PATH_METABASE,
  PATH_PROFILE,
} from "./constants";
import { ProcessingProvider } from "./core";
import { DashboardPage, EventDashboardPage } from "./dashboard";
import { FlaggedRoute } from "./flagged-route";
import {
  ActionPlansPage,
  PatientDetailsPage,
  PcrOnlyPage,
  ProtocolStagesPage,
  ResultsPage,
  RosterPage,
  ChecklistReviewPage,
} from "./participants";
import { ProfilePage } from "./profile";
import { RegistrationPage } from "./registration";

export const Routes = () => (
  <Switch>
    <Route path={PATH_LOGOUT}>
      <LogoutPage />
    </Route>
    <Route path={PATH_LOGIN}>
      <LoginPage />
    </Route>
    <Route path={PATH_REGISTER}>
      <RegistrationPage />
    </Route>
    <Route path={PATH_FORGOT_EMAIL}>
      <ForgotEmailPage />
    </Route>
    <Route path={PATH_FORGOT_PASSWORD}>
      <ForgotPasswordPage />
    </Route>
    <Route path={PATH_RESET_PASSWORD}>
      <ResetPasswordPage />
    </Route>
    <ProcessingProvider>
      <AuthenticatedSwitch>
        <Route path={PATH_ROSTER}>
          <RosterPage />
        </Route>
        {/* <Route path={PATH_STAGES}>
          <ProtocolStagesPage />
        </Route> */}
        <Route path={PATH_RESULTS}>
          <ResultsPage />
        </Route>
        <Route path={PATH_HISTORY}>
          <PatientDetailsPage />
        </Route>
        <Route path={PATH_POSITIVES}>
          <PcrOnlyPage />
        </Route>
        <Route path={PATH_REVIEW}>
          <ChecklistReviewPage />
        </Route>
        <FlaggedRoute flag={FLAG_VIEW_PLANS} path={PATH_ACTION_PLANS}>
          <ActionPlansPage />
        </FlaggedRoute>
        <FlaggedRoute flag={FLAG_COLLECT_SPECIMEN} path={PATH_COLLECTION}>
          <CollectionPage />
        </FlaggedRoute>
        <Route exact path={PATH_HOME}>
          <DashboardPage />
        </Route>
        <Route exact path={PATH_EVENT_HOME}>
          <EventDashboardPage />
        </Route>
        <Route exact path={PATH_PROFILE}>
          <ProfilePage />
        </Route>
      </AuthenticatedSwitch>
    </ProcessingProvider>
    <Route>
      <Redirect to={PATH_HOME} />
    </Route>
  </Switch>
);
