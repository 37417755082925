import { EventParticipant } from "@relieftelemed/platform";
import React, { FunctionComponent } from "react";

import { Review } from "./form";

interface ReviewParticipantProps {
  onCancel?: () => void;
  open: boolean;
  participant: EventParticipant;
}

export const ReviewParticipant: FunctionComponent<ReviewParticipantProps> = ({
  onCancel,
  open,
  participant,
}) => {
  if (!open) {
    return null;
  }

  return <Review onClose={onCancel} open={open} participant={participant} />;
};
