import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent } from "react";

// import { useAppService } from "../../state";

import { UploadParticipantsForm } from "./form";

interface UploadParticipantsProps {
  onCancel?: () => void;
  open: boolean;
}

export const UploadParticipants: FunctionComponent<UploadParticipantsProps> = ({
  onCancel,
  open,
}) => {
  const flags = useFlags();
  // const [{ currentEvent }] = useAppService();

  if (!flags.addParticipants || !open) {
    return null;
  }

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return <UploadParticipantsForm onClose={handleClose} open={open} />;
};
