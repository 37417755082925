// States
export const NOT_LOGGED_IN = "notLoggedIn";
export const LOGGED_IN = "loggedIn";
export const INITIAL = "initial";
export const FETCHING_ORGANIZATIONS = "fetchingOrganizations";
export const FETCHING_ORGANIZATION_EVENTS = "fetchingOrganizationEvents";
export const FETCHING_EVENT_TYPES = "fetchingEventTypes";
export const MAYBE_ORGANIZATION = "maybeOrganization";
export const MAYBE_EVENT = "maybeEvent";
export const FETCHING_PARTICIPANTS = "fetchingParticipants";
export const READY = "ready";
export const LOGGING_OUT = "loggingOut";
export const FETCH_FOR_COMMON_DATA = "fetchForCommonData";
export const DONE_FETCHING_DATA = "doneFetchingData";
export const FETCH_FOR_EVENT_LOCATIONS = "fetchForEventLocations";

// Private Events
export const CHANGE_EVENT = "APP.CHANGE_EVENT";
export const CHANGE_LOCATION = "APP.CHANGE_LOCATION";
export const CHANGE_NAME_FILTER = "APP.CHANGE_NAME_FILTER";
export const CHANGE_ORG = "APP.CHANGE_ORG";
export const CHANGE_TIME = "APP.CHANGE_TIME";
export const END_SESSION = "APP.END_SESSION";
export const FINISH_LOGOUT = "APP.FINISH_LOGOUT";
export const FETCH_EVENTS = "APP.FETCH_EVENTS";
export const FETCH_PARTICIPANTS = "APP.FETCH_PARTICIPANTS";
export const TOGGLE_GLOBAL = "APP.TOGGLE_GLOBAL";
export const UPDATE_EVENTS = "APP.UPDATE_EVENTS";
export const UPDATE_LOCATIONS = "APP.UPDATE_LOCATIONS";
export const UPDATE_PARTICIPANTS = "APP.UPDATE_PARTICIPANTS";
export const CHANGE_DATE_FILTER = "APP.CHANGE_DATE_FILTER";

export const UPDATE_USER = "APP.UPDATE_USER";
