import React, { FunctionComponent } from "react";

import { TabTitle } from "../../../components";
import { CheckinTabs, CheckinTitleProps } from "../types";

export const Title: FunctionComponent<CheckinTitleProps> = ({
  prefix,
  ...props
}) => (
  <TabTitle
    label={`${prefix}Testing`}
    name={CheckinTabs.TESTING}
    {...props}
    id="tab-title-testing"
  />
);
