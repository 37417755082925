import { Button, Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import React, { FunctionComponent, MouseEvent, useState } from "react";

import { EventLocation } from "../../core";
import { useIsDashboard, useIsVaccinationEvent } from "../../hooks";
import { useAppService } from "../../state";

export const LocationMenu: FunctionComponent = () => {
  const [
    { currentLocation, locations },
    ,
    { changeLocation },
  ] = useAppService();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDashboard = useIsDashboard();
  const isVaccination = useIsVaccinationEvent();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (location?: EventLocation) => () => {
    changeLocation(location);
    handleClose();
  };

  if ((!isDashboard && !isVaccination) || locations.length < 2) {
    return null;
  }

  return (
    <>
      <Button
        aria-controls="location-menu"
        aria-haspopup="true"
        endIcon={<KeyboardArrowDown />}
        onClick={handleClick}
        variant="text"
      >
        {currentLocation?.name ?? "All Locations"}
      </Button>
      <Menu
        id="location-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelect(undefined)}>All Locations</MenuItem>
        {locations.map((location) => (
          <MenuItem key={location.id} onClick={handleSelect(location)}>
            {location.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
