import {
  Grid,
  TextField,
  Checkbox,
  Typography,
  makeStyles,
  FormControlLabel,
} from "@material-ui/core";
import {
  User,
  useCreateOTPDevice,
  useVerifyOTPDevice,
} from "@relieftelemed/platform";
import QRCode from "qrcode.react";
import React, {
  FunctionComponent,
  useState,
  FormEventHandler,
  FormEvent,
} from "react";

import { ActionButton, FormCard } from "../components";

export interface SecondFactorFormProps {
  user: User;
  onUserUpdated: (updatedUser: User) => void;
}

const useSecondFactorStyles = makeStyles(() => ({
  qrContainer: {
    alignSelf: "center",
  },
}));

export const SecondFactorForm: FunctionComponent<SecondFactorFormProps> = ({
  user,
  onUserUpdated,
}) => {
  const [activate2Fa, setActivate2FA] = useState(user.use2Fa);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [code, setCode] = useState("");
  const [twoFaHelperAttribs, setHelperAttribs] = useState({
    error: false,
    text: "",
  });
  const { mutate: createOTPDevice } = useCreateOTPDevice();
  const { mutate: verifyOTPDevice } = useVerifyOTPDevice();
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      createOTPDevice(null, {
        onSuccess: (result: { url: string }) => {
          setQrCodeUrl(result.url);
        },
      });
    }
    setActivate2FA(event.target.checked);
  };

  const classes = useSecondFactorStyles();

  return (
    <FormCard
      elevation={0}
      title="Two Factor Settings"
      onSubmit={(e: FormEvent<HTMLFormElement & HTMLDivElement>) => {
        e.preventDefault();
        verifyOTPDevice(code, {
          onSuccess: (__) => {
            setHelperAttribs({
              error: false,
              text: "Looks Good! 2FA Activated",
            });
            onUserUpdated({ ...user, use2Fa: true });
          },
          onError: (__) => {
            setHelperAttribs({
              error: true,
              text: "Wrong Code!",
            });
          },
        });
      }}
      actions={
        <ActionButton type="submit" disabled={code.length < 6}>
          Save
        </ActionButton>
      }
    >
      <Grid item xs={12} md={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={activate2Fa}
              onChange={handleCheckChange}
              disabled={user.use2Fa}
            />
          }
          label={
            user.use2Fa ? "Two Factor Auth Enabled" : "Enable Two Factor Auth"
          }
        />
      </Grid>
      {activate2Fa && !user.use2Fa && (
        <Grid item container direction="column" justify="center" spacing={2}>
          <Grid item>
            <Typography>
              Scan the QR with Google authenticator or Authy to add Device
            </Typography>
          </Grid>
          <Grid item alignItems="center" className={classes.qrContainer}>
            {qrCodeUrl != null ? <QRCode value={qrCodeUrl!} /> : null}
          </Grid>
          <Grid item>
            <TextField
              id="code"
              onChange={(event) => setCode(event.target.value)}
              value={code}
              label="Code"
              helperText={twoFaHelperAttribs.text}
              error={twoFaHelperAttribs.error}
              required
              autoFocus
            />
          </Grid>
        </Grid>
      )}
    </FormCard>
  );
};
