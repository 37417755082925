import { isAfter, isYesterday, startOfDay, subDays } from "date-fns";

const fortnightAgo = subDays(startOfDay(new Date()), 14);

export enum Timeframe {
  yesterday = "yesterday",
  fortnight = "fortnight",
  lifetime = "lifetime",
}

export const TimeframeLabels: Record<Timeframe, string> = {
  [Timeframe.yesterday]: "Yesterday",
  [Timeframe.fortnight]: "Last 2 weeks",
  [Timeframe.lifetime]: "Event Lifetime",
};

export const isInTimeframe = (value?: Date, timeframe?: Timeframe) => {
  let inTimeframe = false;

  if (!value) {
    return inTimeframe;
  }

  switch (timeframe) {
    case Timeframe.yesterday:
      inTimeframe = isYesterday(value);
      break;
    case Timeframe.fortnight:
      inTimeframe = isAfter(startOfDay(value), fortnightAgo);
      break;
    default:
      inTimeframe = Boolean(value);
      break;
  }

  return inTimeframe;
};
