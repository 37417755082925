import React, { FunctionComponent } from "react";

import { NewParticipantForm } from "./form";

interface NewParticipantProps {
  onCancel?: () => void;
  open: boolean;
}

export const NewParticipantMenuItem: FunctionComponent<NewParticipantProps> = ({
  onCancel,
  open,
}) => {
  // const flags = useFlags();

  if (!open) {
    return null;
  }

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return <NewParticipantForm onCancel={handleClose} open={open} />;
};
