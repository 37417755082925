import { Test } from "../core";

export const covidTest: Test = {
  id: "pcr",
  name: "COVID-19 polymerise chain reaction (PCR)",
  shortName: "PCR Covid",
  type: "swab",
  value: "boolean",
};

export const covidRapidTest: Test = {
  id: "rapid",
  name: "COVID-19 rapid antigen test",
  shortName: "RCT",
  type: "swab",
  value: "boolean",
};

export const covidAntibodyIgg: Test = {
  id: "iv-igg",
  name: "COVID-19 IgG antibody test",
  shortName: "IgG (IV)",
  type: "iv",
  value: "boolean",
};

export const covidAntibodyIgm: Test = {
  id: "iv-igm",
  name: "COVID-19 IgM antibody test",
  shortName: "IgM (IV)",
  type: "iv",
  value: "boolean",
};

export const covidRapidIgg: Test = {
  id: "rapid-igg",
  name: "COVID-19 IgG rapid test",
  shortName: "IgG (rapid)",
  type: "rapid",
  value: "boolean",
};

export const covidRapidIgm: Test = {
  id: "rapid-igm",
  name: "COVID-19 IgM rapid test",
  shortName: "IgM (rapid)",
  type: "rapid",
  value: "boolean",
};

export const lsuCovid = [covidTest, covidRapidTest];

export const allCovid = [
  covidRapidIgg,
  covidRapidIgm,
  covidAntibodyIgg,
  covidAntibodyIgm,
  covidTest,
];

export const covidDashboard = [covidRapidIgg, covidRapidIgm, covidTest];
