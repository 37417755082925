import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { credentials } from "../core";

export const CredentialSelector: FunctionComponent<TextFieldProps> = ({
  SelectProps,
  ...props
}) => (
  <TextField {...props} select>
    {credentials.map((credential) => (
      <MenuItem key={credential.id} value={credential.id}>
        {credential.name}
      </MenuItem>
    ))}
  </TextField>
);
