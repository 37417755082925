import {
  EventParticipant,
  HealthInsurance,
  logger,
  Patient,
  VideoVisit,
  Visit,
} from "@relieftelemed/platform";

import {
  CENTER,
  HORIZONTAL_MARGIN,
  MAXIMUM_PRINTER_WIDTH,
  MILLIMETER,
  DeviceFontAlignment,
  LabelMaker,
  LabelElementPosition,
} from "../core";

import { renderDate, renderTime } from "./date";
import signature from "./signature.png";

const LEFT_EDGE = 3 * MILLIMETER;

const RIGHT_EDGE = MAXIMUM_PRINTER_WIDTH - (2 * MILLIMETER + HORIZONTAL_MARGIN);

const isPatientInfo = (value?: string | Patient): value is Patient =>
  Boolean(value) && typeof value !== "string";

const isInsuranceInfo = (
  value?: string | HealthInsurance,
): value is HealthInsurance => Boolean(value) && typeof value !== "string";

const isVisitInfo = (value?: string | Visit): value is VideoVisit =>
  Boolean(value) && typeof value !== "string";

const printLabelHeader = (label: LabelMaker, top = 0) => {
  label.addText("RELIEF TELEMED", {
    position: [CENTER, top],
    width: 5 * MILLIMETER,
    height: 4 * MILLIMETER,
    align: DeviceFontAlignment.CENTER,
    bold: true,
  });

  label.addText("7117 Florida Blvd, Baton Rouge, LA 70806", {
    position: [CENTER, top + 6 * MILLIMETER],
    width: 3.5 * MILLIMETER,
    height: 3.5 * MILLIMETER,
    align: DeviceFontAlignment.CENTER,
  });
};

const printPatientInfo = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  const patientDob = isPatientInfo(participant.patient)
    ? participant.patient.dob
    : participant.patientDob;

  const patientGender = isPatientInfo(participant.patient)
    ? participant.patient.gender
    : participant.patientGender;

  const patientAddress = isPatientInfo(participant.patient)
    ? participant.patient.address?.summary
    : undefined;

  const patientInsurance =
    isPatientInfo(participant.patient) &&
    isInsuranceInfo(participant.patient.insurance)
      ? participant.patient.insurance.provider
      : undefined;

  const patientInsuranceId =
    isPatientInfo(participant.patient) &&
    isInsuranceInfo(participant.patient.insurance)
      ? participant.patient.insurance.memberId
      : undefined;

  const insurance =
    patientInsurance && patientInsurance.length > 0
      ? `${patientInsurance} / ${patientInsuranceId}`
      : "No Known Insurance";
  const dob = patientDob ? renderDate(patientDob) : "___/___/______";
  const gender = patientGender ? `, ${patientGender}` : "M | F";

  label.addText(`${participant.patientName} (${dob}${gender})`, {
    position: [left + 3 * MILLIMETER, top + 3 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText(patientAddress ?? "", {
    position: [left + 3 * MILLIMETER, top + 8 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText(insurance, {
    position: [left + 3 * MILLIMETER, top + 13 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 18 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

const printProviderInfo = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  const provider =
    typeof participant.visit === "string"
      ? participant.visit
      : participant.visit?.provider;
  const providerName =
    provider && typeof provider !== "string"
      ? `${provider.firstName} ${provider.lastName}`
      : "Ron Andrews";
  const npiNumber =
    provider && typeof provider !== "string"
      ? `(${provider.npId})`
      : "(1083699946)";

  label.addText(`Provider / NPI: ${providerName} ${npiNumber}`, {
    position: [left + 3 * MILLIMETER, top + 3 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  const rawSignature = providerName === "Ron Andrews" ? signature : "";
  const [, signatureImage] = rawSignature.split(",");

  logger.info("Signature", signatureImage);

  label.addImage(
    signatureImage,
    [left + 70 * MILLIMETER, top],
    24 * MILLIMETER,
    false,
  );

  label.addBox([left, top], [right, top + 8 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

const printTestInfo = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  label.addText("Test:", {
    position: [left + 3 * MILLIMETER, top + 2 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText("SARS-COV-2 Virus", {
    position: [left + 3 * MILLIMETER, top + 7 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 12 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

const printSwabInfo = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  label.addText("Swab:", {
    position: [left + 3 * MILLIMETER, top + 2 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText("Mid-turbinate nasal", {
    position: [left + 3 * MILLIMETER, top + 7 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 12 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

const printDiagnosisInfo = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  const rawDiagnoses = isVisitInfo(participant.visit)
    ? participant.visit!.diagnoses
    : undefined;

  const diagnoses = Array.isArray(rawDiagnoses)
    ? rawDiagnoses.filter(Boolean)
    : [];

  const dxCodes =
    diagnoses.length > 0
      ? diagnoses
          .filter(Boolean)
          .map((diagnosis) => diagnosis.code)
          .join(", ")
      : "Z03.818";

  label.addText("Dx:", {
    position: [left + 3 * MILLIMETER, top + 2 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText(dxCodes, {
    position: [left + 3 * MILLIMETER, top + 7 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 12 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

const printCollectionDate = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  label.addText("Collection Date:", {
    position: [left + 3 * MILLIMETER, top + 2 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText(renderDate(participant.checkedInAt ?? new Date()), {
    position: [left + 3 * MILLIMETER, top + 7 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 12 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

const printCollectionTime = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  label.addText("Collection Time:", {
    position: [left + 3 * MILLIMETER, top + 2 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText(renderTime(participant.checkedInAt ?? new Date()), {
    position: [left + 3 * MILLIMETER, top + 7 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 12 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

const printCollectorInfo = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  const collector =
    participant.checkedInBy && typeof participant.checkedInBy !== "string"
      ? `${participant.checkedInBy.firstName} ${participant.checkedInBy.lastName}`
      : undefined;

  label.addText("Collector:", {
    position: [left + 3 * MILLIMETER, top + 2 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText(collector ?? "_____", {
    position: [left + 3 * MILLIMETER, top + 7 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 12 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

const printLabInfo = (
  label: LabelMaker,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  label.addText("Orion Laboratories L.L.C.", {
    position: [left + 3 * MILLIMETER, top + 3 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText("CLIA 19D2168465", {
    position: [left + 3 * MILLIMETER, top + 8 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText("6949 Commerce Cir, Suite C, Baton Rouge, LA 70809", {
    position: [left + 3 * MILLIMETER, top + 13 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText("Gillian C.Redlich, M.D.", {
    position: [left + 3 * MILLIMETER, top + 18 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText("225-923-6070", {
    position: [left + 3 * MILLIMETER, top + 23 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 28 * MILLIMETER], {
    thickness: 1 * MILLIMETER,
  });
};

const printSigForm = (
  label: LabelMaker,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;

  label.addText("DL/SSN: _______________________________________________", {
    position: [left + 3 * MILLIMETER, top + 5 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addText(
    "Ethnicity: _____________________  Race: __________________________",
    {
      position: [left + 3 * MILLIMETER, top + 16 * MILLIMETER],
      width: 3 * MILLIMETER,
      height: 3 * MILLIMETER,
      align: DeviceFontAlignment.LEFT,
    },
  );

  label.addText("Signature: ____________________________________________", {
    position: [left + 3 * MILLIMETER, top + 27 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  label.addBox([left, top], [right, top + 35 * MILLIMETER], {
    thickness: 0.5 * MILLIMETER,
  });
};

export const printImage = (
  label: LabelMaker,
  position: LabelElementPosition,
  image: string,
) => {
  label.addImage(image, position, 96 * MILLIMETER);
};

const printTearOffLabel = (
  label: LabelMaker,
  participant: EventParticipant,
  topLeft: LabelElementPosition,
  width?: number,
) => {
  const [left, top] = topLeft;
  const right = width ? width + left : RIGHT_EDGE;
  const name = participant.patientName ?? "__________";
  const dob = participant.patientDob
    ? renderDate(participant.patientDob)
    : "___/___/___";
  const checkIn = renderDate(participant.checkedInAt ?? new Date());

  label.addText(name, {
    position: [left + 3 * MILLIMETER, top + 2 * MILLIMETER],
    width: 3 * MILLIMETER,
    height: 3 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
    bold: true,
  });

  label.addText(`DOB: ${dob}  Coll: ${checkIn}`, {
    position: [left + 3 * MILLIMETER, top + 7 * MILLIMETER],
    width: 2.5 * MILLIMETER,
    height: 2.5 * MILLIMETER,
    align: DeviceFontAlignment.LEFT,
  });

  // TODO: BARCODE
  // label.addBarcode(
  //   "123456789AB",
  //   [left + 3 * MILLIMETER, top + 11.5 * MILLIMETER],
  //   {
  //     height: 5.5 * MILLIMETER,
  //   },
  // );
};

export const createLargeSpecimentLabel = (
  label: LabelMaker,
  participant: EventParticipant,
) => {
  logger.debug("Participant to print", participant);
  printLabelHeader(label);
  printPatientInfo(label, participant, [LEFT_EDGE, 13 * MILLIMETER]);
  printProviderInfo(label, participant, [LEFT_EDGE, 31 * MILLIMETER]);
  printTestInfo(
    label,
    participant,
    [LEFT_EDGE, 39 * MILLIMETER],
    32 * MILLIMETER,
  );
  printSwabInfo(
    label,
    participant,
    [35 * MILLIMETER, 39 * MILLIMETER],
    42 * MILLIMETER,
  );
  printDiagnosisInfo(label, participant, [77 * MILLIMETER, 39 * MILLIMETER]);
  printCollectionDate(
    label,
    participant,
    [LEFT_EDGE, 51 * MILLIMETER],
    32 * MILLIMETER,
  );
  printCollectionTime(
    label,
    participant,
    [35 * MILLIMETER, 51 * MILLIMETER],
    32 * MILLIMETER,
  );
  printCollectorInfo(label, participant, [67 * MILLIMETER, 51 * MILLIMETER]);
  printLabInfo(label, [LEFT_EDGE, 65 * MILLIMETER]);
  printSigForm(label, [LEFT_EDGE, 93 * MILLIMETER]);
  // printImage(label, [LEFT_EDGE, 70 * MILLIMETER], idImage);
  // printImage(label, [LEFT_EDGE, 70 * MILLIMETER], insuranceImage);
  printTearOffLabel(
    label,
    participant,
    [LEFT_EDGE, 134 * MILLIMETER],
    48 * MILLIMETER,
  );
  printTearOffLabel(label, participant, [51 * MILLIMETER, 134 * MILLIMETER]);
};
