import {
  DialogContentText,
  Grid,
  FormControlLabel,
  MenuItem,
  CircularProgress,
  TextField,
  Checkbox,
} from "@material-ui/core";
import {
  useGetCovidTestTypes,
  useGetCovidTestOptions,
} from "@relieftelemed/platform";
import React, { FunctionComponent, useMemo, ChangeEvent } from "react";

import { useCheckinContext } from "../context";
import { valueFromTarget } from "../helpers";
import { TestingInfo } from "../state";

export const CovidTestingForm: FunctionComponent = () => {
  const {
    onUpdateTestingInfo,
    testingInfo = {
      testResult: "",
      testName: "",
      emailUser: false,
    },
  } = useCheckinContext();

  const {
    isLoading: loadingTestTypes,
    data: testTypes,
  } = useGetCovidTestTypes();

  const {
    isLoading: loadingTestOptions,
    data: testOptions,
  } = useGetCovidTestOptions();

  const updateTestingInfo = (props: Partial<TestingInfo>) =>
    onUpdateTestingInfo({ ...testingInfo, ...props });

  const changeTestType = (testType: string) => {
    updateTestingInfo({
      testName: testType,
    });
  };

  const changeTestResult = (testResult: string) => {
    updateTestingInfo({
      testResult,
    });
  };

  return (
    <>
      <DialogContentText variant="h3">Testing Form</DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Test Types"
            onChange={valueFromTarget(changeTestType)}
            value={testingInfo.testName}
            InputProps={{
              endAdornment: loadingTestTypes ? (
                <div style={{ marginRight: "1.5em" }}>
                  <CircularProgress color="inherit" size={20} />
                </div>
              ) : null,
            }}
            select
          >
            {testTypes?.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Test Results"
            onChange={valueFromTarget(changeTestResult)}
            value={testingInfo.testResult}
            InputProps={{
              endAdornment: loadingTestOptions ? (
                <div style={{ marginRight: "1.5em" }}>
                  <CircularProgress color="inherit" size={20} />
                </div>
              ) : null,
            }}
            select
          >
            {testOptions?.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="Email User"
            control={
              <Checkbox
                checked={testingInfo.emailUser}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": "antibody-disclosure-label" }}
                onChange={() =>
                  onUpdateTestingInfo({
                    ...testingInfo,
                    emailUser: !testingInfo.emailUser,
                  })
                }
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
