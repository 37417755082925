import {
  DialogContentText,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { LockOpen } from "@material-ui/icons";
import React, {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from "react";

import { Button, FormModal } from "../../components";
import { useAddRole, ApiState, User } from "../../core";
import { SelectUser } from "../select";

interface AddNurseFormProps {
  onClose?: () => void;
  open?: boolean;
}

const nurseRoles = [
  {
    label: "Relief Nurse",
    value: "relief_nurse",
  },
  {
    label: "Clinic's Nurse",
    value: "clinic_nurse",
  },
];

const roleRenderer = (value: unknown) =>
  nurseRoles.find((role) => role.value === (value as string))?.label ?? "";

const useVisitModalStyles = makeStyles({
  primaryAction: {
    textAlign: "right",
  },
});

export const AddNurseForm: FunctionComponent<AddNurseFormProps> = ({
  onClose,
  open = false,
}) => {
  const classes = useVisitModalStyles();
  const [user, changeUser] = useState<User>();
  const [userRole, changeRole] = useState("clinic_nurse");
  const { mutate } = useAddRole();

  const handleChangeRole: ChangeEventHandler<HTMLInputElement> = (event) => {
    changeRole(event.target.value);
  };

  const handleSubmit = () => {
    if (!user) {
      return;
    }
    mutate(
      { id: user!.id, role: userRole },
      {
        onSuccess: () => {
          if (onClose) onClose();
        },
      },
    );
  };

  return (
    <FormModal
      icon={LockOpen}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      scroll="body"
      title="Add Nurse"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button onClick={handleSubmit}>Submit</Button>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText variant="h3">Select a user</DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <SelectUser value={user} onChange={changeUser} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            label="Nurse Type"
            onChange={handleChangeRole}
            select
            SelectProps={{ renderValue: roleRenderer }}
            value={userRole}
          >
            {nurseRoles.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </FormModal>
  );
};
